import React, { Component } from "react";
import "./style.scss";

import Cross from "../../../Media/Common/cross.svg";

export interface PopupWrapperProps {
    active?: boolean;
    onClose?: Function;
}

export default class PopupWrapper extends Component<PopupWrapperProps, {}> {
    handleClose() {
        if (this.props.onClose) {
            document.body.style.overflow = "auto";
            this.props.onClose();
        }
    }

    render() {
        const active = this.props.active || false;

        if (active) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }

        return (
            <>
                {active && (
                    <div className="popup-wrapper">
                        <div className="popup-children">
                            <div
                                onClick={() => this.handleClose()}
                                className="popup-button"
                            >
                                <img src={Cross} alt="X"></img>
                            </div>
                            {this.props.children}
                        </div>
                    </div>
                )}
            </>
        );
    }
}
