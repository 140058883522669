import React from "react";
import "./style.scss";

import MainView from "../Common/MainView";
import Breadcrumbs from "../../Components/Layout/Breadcrumbs";
import { BreadcrumbsItem } from "../../Components/Layout/Breadcrumbs/types";

import MyRequestsSection from "./Sections/myRequests";
import RequestedSection from "./Sections/requested";

import paApiHelper from "../../Utils/personalAreaApi";

const breadcrumbsButtons: BreadcrumbsItem[] = [
    {
        id: 36,
        name: "Мои заявки",
        path: "/requests/?section=my-requests",
    },
    {
        id: 37,
        name: "Заявки мне",
        path: "/requests/?section=requested",
    },
];

interface RequestsPageState {
    structure: any[];
}

export default class RequestsPage extends React.Component<{}, RequestsPageState> {

    constructor(props: any) {
        super(props);

        this.state = {
            structure: [],
        }
    }

    async getPageStructure() {
        const pageStructure = await paApiHelper.getPageStructure("requests");

        if (pageStructure.success !== false) {
            this.setState({
                structure: pageStructure.elements,
            });
        }
    }

    async componentDidMount() {
        await this.getPageStructure();
    }

    render() {

        let availableBreadcrumbsButtons: any = [];
        const queryParams = new URLSearchParams(window.location.search);
        let section = "my-requests";

        const querySectionParam = queryParams.get("section");
        switch (querySectionParam) {
            case "my-requests":
                section = "my-requests";
                break;
            case "requested":
                section = "requested";
                break;
        }


        console.log(this.state);

        if(this.state.structure)
        {
            availableBreadcrumbsButtons = breadcrumbsButtons.filter(bb => this.state.structure.map((e: any) => e.id).includes(bb.id))
        }

        return (
            <MainView>
                <Breadcrumbs
                    breadcrumbsButtons={availableBreadcrumbsButtons}
                ></Breadcrumbs>
                {(() => {
                    switch (section) {
                        case "requested":
                            return <RequestedSection/>
                        default:
                            return <MyRequestsSection/>;
                    }
                })()}
            </MainView>
        );
    }
}

