import moment from 'moment';
import 'moment/locale/ru';
import { DropdownItem } from '../Components/Dropdowns/Select/types';

// const time = new Date().toString();

// console.log(`${typeof(time)}: ${time}`);

const createTimeForTableFeedback = (dataStr: string) => {
    const date = moment(dataStr).format('L');
    const time = moment(dataStr).format('LT');
    const dateTime = `${date}-${time}`;
    return dateTime;
};

function standartTimeFormat(date: moment.Moment)
{
    return date.format("DD.MM.YYYY-HH:mm")
}

interface CommondHandlerOptions {
    bindObj: any,
    body: Document, 
    ref: any,
    action: Function, 
    type: string, 
    condition: boolean
}
/**
 * Хендлер вызова переданной функции @action при клике @event вне элемента @ref 
 * @param {any} bindObj - точка вызова хендлера (по умолчанию передавать this)
 * @param {Document} body - ReactDOM, в котором происходит обработка события (по умолчанию передавать document)
 * @param {any} ref - элемент, вне которого проверяется клик
 * @param {Function} action - обработчик, который нужно вызвать при клике вне компонента @ref
 * @param {string} type - тип обрабатываемого события (по умолчанию передавать "click")
 * @param {boolean} condition - условие добавления события (при каком condition нужно добавить обработчик события на @body) 
 */
function outsideClickHandler(options: CommondHandlerOptions) {
    const {
        bindObj,
        body, 
        ref,
        action, 
        type, 
        condition
    } = options;
    if (condition) {
        body.addEventListener(type, (event: any) => handleOutsideEvent(event, ref, action.bind(bindObj)), { capture: true })
    }
    else {
        body.removeEventListener(type, (event: any) => handleOutsideEvent(event, ref, action.bind(bindObj)), { capture: true })
    }
}

function handleOutsideEvent(event: any, ref: any, action: Function) {
    if (!ref || !ref.current) {
        return;
    }

    if (!ref.current.contains(event.target)) {
        action();
    }
}

async function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function mapArrayToDropdownElements(array: any[], elementNameKey: string = "name") {
    const items: DropdownItem[] = array.map((element: any) => {
        const item: DropdownItem = {
            id: element.id,
            label: element[elementNameKey],
            data: element,
        };
        return item;
    });

    return items;
}

function mapTags(rawTags: any[]) {
    const tags: any[] = [];

    for (const rawTag of rawTags) {
        if (rawTag.dependsOn === null) {
            const details = rawTag.details;

            const subTags: any[] = [];

            for (const rawSubTag of rawTags) {
                if (rawSubTag.dependsOn && rawSubTag.dependsOn === rawTag.id) {
                    const subTag = {
                        id: rawSubTag.id,
                        title: rawSubTag.name,
                    };

                    subTags.push(subTag);
                }
            }

            const tag = {
                id: rawTag.id,
                title: rawTag.name,
                color: details.color,
                width: "fit-content",
                subTags: subTags,
                isActive: false,
                selectedTag: false,
            };
            tags.push(tag);
        }
    }

    return tags;
}

function getFullName(profile: any)
{
    let names = [profile.lastName, profile.firstName, profile.middleName];

    names = names.filter(n => n !== null);

    if(names.length === 0)
    {
        names = [profile.username];
    }

    const lfm = names.filter(a => a.length > 0).join(" ");

    return lfm;
}

export {
    createTimeForTableFeedback,
    outsideClickHandler,
    delay,
    mapArrayToDropdownElements,
    mapTags,
    standartTimeFormat,
    getFullName,
};

// console.log(createTimeForTableFeedback(time));