export const registerTableHeadData = { appNumber: "№ заявки", name: "Клиент ФИО", status: "Статус", data: "Дата", details: "Детали" };
export const registerTableBodyData = [
    {
        id: 0,
        appNumber: "000001",
        name: "Иванов Иван Иванович",
        status: "Новая",
        data: "26.20.2021–12:50",
        details: "Посмотреть"
    },
    {
        id: 1,
        appNumber: "000001",
        name: "Сергей Иванов Сергеевич",
        status: "Отменена",
        data: "26.20.2021–12:50",
        details: "Посмотреть"
    },
    {
        id: 2,
        appNumber: "000001",
        name: "Сергей Иванов Сергеевич",
        status: "Исполнена",
        data: "26.20.2021–12:50",
        details: "Посмотреть"
    },
    {
        id: 3,
        appNumber: "000001",
        name: "Сергей Иванов Сергеевич",
        status: "Новая",
        data: "26.20.2021–12:50",
        details: "Посмотреть"
    },
];
