import React from "react";

import "./my.scss";

import TableWrapper, {
    HeaderGreenProvider,
} from "../../../Components/Layout/TableWrapper";

import TitleTextBlock from "../../../Components/Layout/TextBlocks/TitleTextBlock";
import paApiHelper from "../../../Utils/personalAreaApi";

import SimpleLoader from "../../../Components/Layout/Loaders/Resizeable";

import moment from "moment";

const requestsHeader = {
    id: "ID операции",
    productOrderId: "ID поставки продукта",
    product: "Продукт",
    // productProvider: "Исполнитель",
    amount: "Сумма",
    currency: "Валюта",
    time: "Время создания",
    status: "Статус",
};

const processData = (data: any, extra: any) => {

    const statuses = extra.statuses;
    const currencies = extra.currencies;
    const productsTypes = extra.productsTypes;

    const newData = data.map((element: any) => {
        return {
            id: <div>{element.id}</div>,
            productOrderId: <div>{element.productOrderId}</div>,
            product: <div>{productsTypes.find((pt: any) => pt.id === element.productTypeId).name} | {element.product.name}</div>,
            // productProvider: <div>Исполниель</div>,
            amount: <div>{element.amount}</div>,
            currency: <div>{currencies.find((c: any) => c.id === element.currencyId).name}</div>,
            time: <div>{moment(element.createdAt * 1000).format("HH:mm:ss DD-MM-YYYY")}</div>,
            status: <div>{statuses.find((s: any) => s.id === element.statusId).name}</div>,
        };
    });

    return newData;
};

interface OperationsSectionProps {
    type?: string;
}

interface OperationsSectionState {
    operations: any[];
    operationsTotal: number;
    currencies: any[];
    statuses: any[];
    productsTypes: any[];
    loading: boolean;
}

const maxRows = 10;

export default class OperationsSection extends React.Component<
    OperationsSectionProps,
    OperationsSectionState
> {
    constructor(props: any) {
        super(props);

        this.state = {
            operations: [],
            operationsTotal: 0,
            statuses: [],
            currencies: [],
            productsTypes: [],
            loading: false,
        };
    }

    async getOperations(offset: number) {


        const requestsBody = {
            type: this.props.type,
        };

        const result = await paApiHelper.filterOperations(requestsBody, maxRows, offset);

        if (result.success !== false) {
            this.setState({
                operations: result.operations,
                operationsTotal: result.total,
            });
        }
    }

    async getOperationsExtra() {

        const result = await paApiHelper.getOperationsExtra();

        if (result.success !== false) {
            this.setState({
                currencies: result.currencies,
                statuses: result.paymentsOrdersStatuses,
                productsTypes: result.productsTypes,
            });
        }
    }

    async componentDidMount() {


        await this.getOperationsExtra();

        await this.getOperations(0);
    }

    render() {
        return (
            <div>
                <TitleTextBlock>
                    {
                        this.props.type === "partner" ? 
                        <span>
                            Здесь ты можешь просматривать все 
                            <br/>
                            поступления денежных средств.
                        </span>
                        :
                        <span>
                            Здесь ты можешь просматривать все операции
                            <br /> за полученные услуги.
                        </span>
                    }
                </TitleTextBlock>
                {this.state.loading ? (
                    <div style={{ width: "100%", height: "500px" }}>
                        <SimpleLoader></SimpleLoader>
                    </div>
                ) : (
                    <HeaderGreenProvider value={true}>
                        <TableWrapper
                            total={this.state.operationsTotal}
                            rowsCount={maxRows}
                            headerData={requestsHeader}
                            bodyData={processData(this.state.operations, {
                                statuses: this.state.statuses,
                                productsTypes: this.state.productsTypes,
                                currencies: this.state.currencies,
                            })}
                            onChangePage={(offset: number) =>
                                this.getOperations(offset)
                            }
                        />
                    </HeaderGreenProvider>
                )}
            </div>
        );
    }
}
