import React from 'react';
import './style.scss';
import Input from '../../../Inputs/Input'
import Textarea from '../../../Inputs/Textarea'
// import SingleDropdown from '../../../Dropdowns/Select/Single'
import Tags from '../../../Dropdowns/Tags'
import Button from '../../../Buttons/PrimaryButton'
import { mapTags } from "../../../../Utils";
import Notifier from '../../Notifier/store';

interface PopupAddServiceProps {
    tags?: any[];
    onClose?: Function;
    onSave?: Function;
}

interface PopupAddServiceState {
    service: any;
}

const requiredKeys = [
    "name", "description", "price", "typeId"
];

export default class PopupAddService extends React.Component<PopupAddServiceProps, PopupAddServiceState> {

    constructor(props: any) {
        super(props)
        this.state = {
            service: {
                "typeId": 1,
            }
        };
    }

    handleOnClose()
    {
        if(this.props.onClose)
        {
            this.props.onClose();
        }
    }

    createService()
    {
        for(const k of requiredKeys)
        {
            if(!Object.keys(this.state.service).includes(k))
            {
                Notifier.warn("Необходимо заполнить все обязательные поля!");
                return;
            }
        }
        this.handleOnClose();
        if(this.props.onSave)
        {
            this.props.onSave(this.state.service);
        }
    }

    setValue(key: string, value: any)
    {
        const service = Object.assign({}, this.state.service);

        service[key] = value;

        this.setState({
            service: service,
        })
    }

    setDiscount(value: any) {
        const service = Object.assign({}, this.state.service);

        service["discountSize"] = Number(value);
        service["discountUnitId"] = 1;

        this.setState({
            service: service,
        })
    }

    render() {
        const rawTags = this.props.tags || [];
        const tags: any[] = mapTags(rawTags);
        return (
            <div className='popup__add-service'>
                <div style={{position: "relative"}}>
                    <div className='popup__btn-close' onClick={() => this.handleOnClose()}></div>
                </div>
                <div className='popup__container'>
                    <div className='popup__item'>
                        <div className='popup__label'>Введите наименование услуги </div>
                        <div className='popup__input-name-service'> <Input onChangeValue={(v) => this.setValue("name", v)} placeholder=" " /></div>
                    </div>
                    <div className='popup__item'>
                        <div className='popup__label'>Введите краткое описание услуги</div>
                        <div className='popup__textarea-wrapper-height' ><Textarea onChangeValue={(v) => this.setValue("description", v)} maxLength={120} /></div>
                    </div>
                    <div className='popup__item'>
                        <div className='popup__label'>Введите стоимость услуги
                            <div className='popup__input-price'><Input type='number' min="0" max="10000000" onChangeValue={(v) => this.setValue("price", Number(v))} placeholder="1000" /></div>
                        </div>
                    </div>
                    <div className='popup__item'>
                        <div className='popup__label'>Укажите скидку (если имеется)
                            <div className='popup__input-wrapper-discount'><span className='popup__discount'>%</span>
                                <Input type='number' min="0" max="100" onChangeValue={(v) => this.setDiscount(v)} placeholder="10" />
                            </div>
                        </div>
                    </div>
                    <div className='popup__item'>
                        <div className='popup__label'>Тэги
                            <Tags onChange={(tags) => this.setValue("tags", tags)} disabled={false} values={tags} />
                        </div>
                    </div>
                    <div className='popup-add-service__button'> <Button onClick={() => this.createService()} text={"Сохранить изменения"} /></div>
                </div>
            </div>
        );
    };
}