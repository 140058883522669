import React, { Component } from "react";
import "./style.scss";
import IcoEye from "../PasswordForm/ico-eye.svg";
import IcoCheckGrey from "../PasswordForm/ico-check-grey.svg";
import Input from "../../Input";

interface PasswordFormProps {
    value?: any;
    onChange?: (
        password: string
    ) => void;
    placeholder?: string;
    showInfo?: boolean;
}
interface PasswordFormState {
    password: string;
    type: string;
    score: string;
    correctLength: boolean;
    correctUpper: boolean;
    correctNumber: boolean;
    correctService: boolean;
}

class PasswordForm extends Component<PasswordFormProps, PasswordFormState> {
    componentDidUpdate(
        prevProps: PasswordFormProps,
        prevState: PasswordFormState
    ) {
        if (
            this.state.correctLength
        ) {
            if(prevState.password !== this.state.password)
            {
                if (this.props.onChange) {
                    this.props.onChange(this.state.password);
                }
            }
        }
    }

    constructor(props: PasswordFormProps) {
        super(props);
        this.state = {
            password: this.props.value || "",
            type: "password",
            score: "null",
            correctLength: false,
            correctUpper: false,
            correctNumber: false,
            correctService: false,
        };
    }

    showHide() {
        this.setState({
            type: this.state.type === "input" ? "password" : "input",
        });
    }

    validateLength = (password: string) => {
        const LengthRegexp = new RegExp("^(.){8,25}$");
        return LengthRegexp.test(password);
    };

    validateUpperCase = (password: string) => {
        const UpperCaseRegexp = new RegExp("[A-Z]{1,}");
        return UpperCaseRegexp.test(password);
    };

    validateAtLeastOneNumber = (password: string) => {
        const AtLeastOneNumberRegexp = new RegExp("\\d{1,}");
        return AtLeastOneNumberRegexp.test(password);
    };

    validateAtLeastServiceSymbol = (password: string) => {
        const AtLeastOneServiceSymbol = new RegExp("[!@#\\$%^&*]{1,}");
        return AtLeastOneServiceSymbol.test(password);
    };

    handleChange = (password: any) => {
        const correctLength = this.validateLength(password);
        const correctUpper = this.validateUpperCase(password);
        const correctNumber = this.validateAtLeastOneNumber(password);
        const correctService = this.validateAtLeastServiceSymbol(password);

        this.setState({
            password: password,
            correctLength: correctLength,
            correctUpper: correctUpper,
            correctNumber: correctNumber,
            correctService: correctService,
        });
    };

    render() {
        const {
            password,
            correctLength,
            correctUpper,
            correctNumber,
            correctService,
        } = this.state;
        const placeholder = this.props.placeholder || "Password";
        const showInfo = this.props.showInfo === undefined ? true : this.props.showInfo;
        // const onChangeHandler = this.props.onChange || (() => { });
        return (
            <form className="password-wrapper">
                <div className="password">
                    <Input
                        defaultValue={this.props.value}
                        placeholder={placeholder}
                        badge={IcoEye}
                        type={this.state.type}
                        onBadgeClick={() => this.showHide()}
                        onChangeValue={(value) => this.handleChange(value)}
                    />
                </div>
                {showInfo && (
                    <div className="container-info">
                        <div
                            className={`info ico-grey ${
                                password.length > 0
                                    ? correctLength
                                        ? "green ico-green"
                                        : "red ico-red"
                                    : ""
                            }`}
                        >
                            <img
                                src={IcoCheckGrey}
                                className="icon-check"
                                alt="icon-check"
                            />
                            Пароль должен содержать от 8 до 25 символов
                        </div>
                        <div
                            className={`info ico-grey ${
                                password.length > 0
                                    ? correctUpper
                                        ? "green ico-green"
                                        // : "red ico-red"
                                        : ""
                                    : ""
                            }`}
                        >
                            <img
                                src={IcoCheckGrey}
                                className="icon-check"
                                alt="icon-check"
                            />
                            Пароль может иметь заглавные буквы
                        </div>
                        <div
                            className={`info ico-grey ${
                                password.length > 0
                                    ? correctNumber
                                        ? "green ico-green"
                                        // : "red ico-red"
                                        : ""
                                    : ""
                            }`}
                        >
                            <img
                                src={IcoCheckGrey}
                                className="icon-check"
                                alt="icon-check"
                            />
                            Пароль может содержать цифры
                        </div>
                        <div
                            className={`info ico-grey ${
                                password.length > 0
                                    ? correctService
                                        ? "green ico-green"
                                        // : "red ico-red"
                                        : ""
                                    : ""
                            }`}
                        >
                            <img
                                src={IcoCheckGrey}
                                className="icon-check"
                                alt="icon-check"
                            />
                            Пароль может содержать спецсимволы (!@#$%^&*)
                        </div>
                    </div>
                )}
            </form>
        );
    }
}

export default PasswordForm;
