import React, { Component } from 'react';
import './style.scss';
import Spinner from '../Spinner';
import api from '../../../Utils/personalAreaApi';
import Notifier from '../Notifier/store';

interface ImageUploadProps {
    onUploadEnd?: Function;
}

interface ImageUploadState {
    file: string,
    imagePreviewUrl: any,
    message: string,
    loading: boolean
}

export default class ImageUpload extends Component<ImageUploadProps, ImageUploadState> {
    constructor(props: any) {
        super(props);
        this.state = {
            file: '',
            imagePreviewUrl: '',
            message: '',
            loading: false
        };
    }
  
    async handleSubmit(e: any) {
        e.preventDefault();

        this.setState({
            loading: true
        });
        const uploadResult = await api.uploadAvatar(this.state.file);


        if(uploadResult.success)
        {
            if(this.props.onUploadEnd)
            {
                this.props.onUploadEnd();
            }
            Notifier.success(uploadResult.message);
        }
        else{
            Notifier.error(uploadResult.message);
        }

        this.setState({
            loading: false
        });

    }
  
    handleImageChange(e: any) {
        e.preventDefault();

        const reader = new FileReader();
        const file = e.target.files[0];
  
        reader.onloadend = () => {
            this.setState({
                file: file,
                imagePreviewUrl: reader.result,
            });
        }

        if (file.type !== "image/png" && file.type !== "image/jpeg") {
                this.setState({message: "формат не соответствует"});
        } else {
            this.setState({message: file.name});
            reader.readAsDataURL(file);
        }
    }
  
    render() {

        const {message, imagePreviewUrl, loading} = this.state;
        let imagePreview: any = null;

        if (imagePreviewUrl) {
            imagePreview = (<img src={imagePreviewUrl} alt='avatar-preview'/>);
        } else {
            imagePreview = (<div className="previewText">Select an Image for Preview</div>);
        }

        const addMessage = (message: string) => {
            if(message !== '') {
               return (<div className='wrapper__message'>
                            <div className='label__message'>Файл для загрузки:</div>
                            <div className="message">{this.state.message}</div>
                        </div>);
            }
        };

        const addPreview = (message: string) => {
            if(message !== '' && message !== 'формат не соответствует') {
               return (
                    <div className='wrapper__preview'>
                        <div className="image-preview">
                            {imagePreview}
                        </div>
                        <button className="submit-button" 
                            type="submit" 
                            onClick={(e)=>this.handleSubmit(e)}>
                            Загрузить
                        </button> 
                    </div>
               );
            }
        };

        const createSpinnerOrPreview = (loading: boolean) => {
            if (loading) {
               return <Spinner/>
            } else {
                return (
                    <React.Fragment>
                        {addMessage(message)}
                        <form onSubmit={(e)=>this.handleSubmit(e)}>
                            {addPreview(message)}
                            <input id='input__file' className="input__file" 
                                    type="file" 
                                    onChange={(e)=>this.handleImageChange(e)} />
                            <label className={`input__file-button ${message !== '' ? 'input__file-button-change' : ''} `} htmlFor="input__file">
                                <span className="input__file-button-text">Выбрать файл</span>
                            </label>
                        </form>
                    </React.Fragment>
                );
            }
        };

        return (
            <div className="wrapper__image-upload">
                 {createSpinnerOrPreview(loading)}
            </div>
        )
    }
  }