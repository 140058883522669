import React, { Component } from "react";
import "./style.scss";

import PopupWrapper, { PopupWrapperProps } from "../PopupWrapper";

import { getFullName, standartTimeFormat } from "../../../../Utils";
import moment from "moment";

import TableWrapper, {
    HeaderGreenProvider,
} from "../../../../Components/Layout/TableWrapper";

interface DetailsPopupProps extends PopupWrapperProps {
    serviceRequest: any;
    statuses: any[];
}

const requestsHeader = {
    loggedAt: "Дата изменения",
    serviceDate: "Запрашиваемое время",
    status: "Статус",
    comment: "Сообщение",
};

function processData(logs: any[], statuses: any[], clientUsername: string) {
    return logs.map((log: any) => {
        return {
            loggedAt: standartTimeFormat(moment(log.loggedAt * 1000)),
            serviceDate: standartTimeFormat(moment(log.serviceDate * 1000)),
            status: statuses.find((s: any) => s.id === log.statusId).name,
            comment: (
                <div className="sr-popup-history-comment">
                    {log.commentBy !== null && (
                        <>
                            {log.commentBy === 1 ? (
                                <b>От Консультанта:</b>
                            ) : (
                                <b>От Клиента:</b>
                            )}
                            <div>{log.comment}</div>
                        </>
                    )}
                </div>
            ),
        };
    });
}

export default class DetailsPopup extends Component<DetailsPopupProps, {}> {
    render() {
        const request = this.props.serviceRequest;
        let serviceName = request.service.name;
        let username = getFullName(request.profile);
        let datetime = standartTimeFormat(moment(request.serviceDate * 1000));

        const logs = request.servicesRequestsLogs.sort();

        return (
            <PopupWrapper
                active={this.props.active || false}
                onClose={() =>
                    this.props.onClose ? this.props.onClose() : () => {}
                }
            >
                <div className="popup-title-wrapper">
                    <div>Детали заявки</div>
                    {/* <img alt="+" src={ApproveIcon} /> */}
                </div>
                <div className="sr-popup-grid">
                    {/* Left */}
                    <div className="sr-popup-grid-column-left">
                        <div className="sr-popup-grid-item">Услуга:</div>
                        <div className="sr-popup-grid-item">Пользователь:</div>
                        <div className="sr-popup-grid-item">Дата и время:</div>
                    </div>
                    {/* Right */}
                    <div className="sr-popup-grid-column-right">
                        <div className="sr-popup-grid-item">{serviceName}</div>
                        <div className="sr-popup-grid-item">{username}</div>
                        <div className="sr-popup-grid-item">{datetime}</div>
                    </div>
                </div>
                <div className="sr-popup-change-history">
                    <HeaderGreenProvider value={true}>
                        <TableWrapper
                            total={1000}
                            rowsCount={1000}
                            headerData={requestsHeader}
                            bodyData={processData(logs, this.props.statuses, username)}
                        />
                    </HeaderGreenProvider>
                </div>
            </PopupWrapper>
        );
    }
}
