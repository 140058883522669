import React, { Component } from "react";
import "./style.scss";
import Panel from "../Common/Panel";
import Spinner from "../Spinner";
import api from "../../../Utils/personalAreaApi";
import Notifier from "../Notifier/store";
import AddRemoveButton from "../../Buttons/AddRemoveButton";
import PrimaryButton from "../../Buttons/PrimaryButton";

interface VideoUploadProps {
    onUploadEnd?: Function;
    video?: any;
}

interface VideoUploadState {
    file: any;
    video: any;
    loading: boolean;
    progress: number;
    processingProgress: number;
}

function clamp(num: number, min: number = 0, max: number = 100) {
    return Math.min(Math.max(num, min), max);
}

export default class VideoUpload extends Component<
    VideoUploadProps,
    VideoUploadState
> {
    processingIntervalId: any = null;
    fileInputRef: any = null;
    constructor(props: VideoUploadProps) {
        super(props);
        this.state = {
            file: null,
            video: props.video || null,
            loading: false,
            progress: 0,
            processingProgress: 0,
        };
        this.fileInputRef = React.createRef();
    }

    componentDidUpdate() {
        if(this.state.video && this.processingIntervalId === null)
        {
            const video = this.state.video;
            if(video.status === 1)
            {
                this.setState({
                    processingProgress: video.percentComplete
                })
                this.startProcessingProgress()
            }
        }
    }

    componentDidMount() {
        if(this.state.video && this.processingIntervalId === null)
        {
            const video = this.state.video;
            if(video.status === 1)
            {
                this.setState({
                    processingProgress: video.percentComplete
                })
                this.startProcessingProgress()
            }
        }
    }

    async updateProcessingStatus()
    {
        const video = await api.getVideo(this.state.video.id);

        if(video.status !== 1)
        {
            clearInterval(this.processingIntervalId);
            this.processingIntervalId = null;
            this.setState({
                video: video
            })
        }

        this.setState({
            processingProgress: video.percentComplete
        })
    }

    updateUploadProgress(progress: number) {
        this.setState({
            progress: progress,
        });
    }

    async onSelectFile() {
        if (this.fileInputRef) {
            const files = this.fileInputRef.current.files;

            if (files.length > 0) {
                const file = files[0];

                this.setState({
                    file: file,
                    loading: true,
                });
                const uploadresult = await api.uploadVideo(file, (progress: any) => this.updateUploadProgress(progress));

                this.setState({
                    loading: false,
                    progress: 100,
                    file: null,
                })

                if(uploadresult.success === true)
                {
                    Notifier.success("Загрузка завершена! Видео скоро начнет обрабатываться")
                }
                else
                {
                    Notifier.error(uploadresult.message);
                    return;
                }
                if(this.props.onUploadEnd)
                {
                    this.props.onUploadEnd();
                }
                const profile = await api.getProfile();

                this.setState({
                    video: profile.video
                })
            }
        }
    }

    startProcessingProgress() {
        this.processingIntervalId = setInterval(
            () => this.updateProcessingStatus(),
            5 * 1000
        );
    }

    async deleteVideo()
    {
        const deleteResult = await api.deleteVideo();

        if(deleteResult.success === false){
            Notifier.error(deleteResult.message);
            return;
        }

        this.setState({
            video: null,
        })
    }

    render() {
        const percentage = clamp(this.state.progress, 0, 100);
        const progressBarText = `Загрузка.. ${percentage}%`;

        const processingPercentage = clamp(this.state.processingProgress, 0, 99);
        const processingProgressBarText = processingPercentage === 0 ? `В очереди на обработку...` : `Обработка.. ${processingPercentage}%`;
        return (
            <Panel>
                <div className="video-upload-wrapper">
                    <>
                        {this.state.video === null ? (
                            <>
                                <div className="video-upload-zone">
                                    {this.state.file === null ? (
                                        <>
                                            <span>Выбрать файл</span>
                                            <input
                                                onChange={() =>
                                                    this.onSelectFile()
                                                }
                                                hidden={true}
                                                ref={this.fileInputRef}
                                                type={"file"}
                                            ></input>
                                            <AddRemoveButton
                                                onClick={() => {
                                                    this.fileInputRef.current.click();
                                                }}
                                                add={true}
                                            />
                                        </>
                                    ) : (
                                        <Spinner/>
                                    )}
                                </div>
                            </>
                        ) : (
                            <>
                                {this.state.video.status === 2 ? (
                                    <div>
                                        <video
                                            controls
                                            src={this.state.video.path || ""}
                                            width={"100%"}
                                        ></video>
                                        <PrimaryButton onClick={() => this.deleteVideo()} text="Удалить"></PrimaryButton>
                                    </div>
                                ) : (
                                    <>
                                        <div className="video-processing-zone">
                                            <Spinner/>
                                        </div>
                                        <div className="progress-bar-wrapper">
                                            <div className="progress-bar">
                                                <div
                                                    style={{
                                                        translate: `-${100 - processingPercentage}%`,
                                                    }}
                                                    className="progress-bar-panel"
                                                ></div>
                                            </div>
                                            <div className="progress-bar-text-wrapper">
                                                <span className="progress-bar-text">
                                                    {processingProgressBarText}
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </>
                    {this.state.file && (
                        <div className="progress-bar-wrapper">
                            <div className="progress-bar">
                                <div
                                    style={{
                                        translate: `-${100 - percentage}%`,
                                    }}
                                    className="progress-bar-panel"
                                ></div>
                            </div>
                            <div className="progress-bar-text-wrapper">
                                <span className="progress-bar-text">
                                    {progressBarText}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </Panel>
        );
    }
}
