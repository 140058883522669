import React from "react";
import "./style.scss";
import "./createPartners.scss";
import Input from "../../Components/Inputs/Input";
import RadioButton from "../../Components/Inputs/RadioButton";
import PasswordForm from "../../Components/Inputs/ValidationForm/PasswordForm";
import SingleDropdown from "../../Components/Dropdowns/Select/Single";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import api from "../../Utils/personalAreaApi";
import SimpleLoader from "../../Components/Layout/Loaders/Resizeable";
import Notifier from "../../Components/Layout/Notifier/store";
import { mapArrayToDropdownElements } from "../../Utils";

interface CreatePartnersProps {
    disabled?: boolean;
    checked?: boolean;
    label?: string;
    name: string;
    id: string;
    onChange?: (id: string, checked: boolean) => void;
}

interface CreatePartnersState {
    partnerInfo: any;
    partnerResponsibleInfo: any;
    ownerInfo: any;
    legalTypes: any[];
    locations: any[];
    legalStatuses: any[];
    loading: boolean;
}
export default class CreatePartnersView extends React.Component<
    CreatePartnersProps,
    CreatePartnersState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            partnerInfo: {},
            partnerResponsibleInfo: {},
            ownerInfo: {},
            legalTypes: [],
            locations: [],
            legalStatuses: [],
            loading: true,
        };
    }

    setOptionField(
        objKey: "partnerInfo" | "partnerResponsibleInfo" | "ownerInfo",
        key: string,
        value: any
    ) {
        const obj: any = Object.assign({}, this.state[objKey]);
        obj[key] = value;

        const newState: any = {};
        newState[objKey] = obj;
        this.setState(newState);
    }

    async componentDidMount() {
        this.setState({ loading: true });

        await this.getProfileExtra();

        this.setState({
            loading: false,
        });
    }

    async getProfileExtra() {
        const result = await api.getProfileExtra([
            "legalStatuses",
            "legalTypes",
            "locations",
        ]);

        if (result.success !== false) {
            this.setState({
                legalTypes: result.legalTypes,
                legalStatuses: result.legalStatuses,
                locations: result.locations,
            });
        }
    }

    haveRequiredFields(data: any) {
        let have = true;
        const requiredKeys: any = {
            partnerInfo: [
                "key",
                "name",
                "commission",
                "legalStatusId",
                "legalTypeId",
                "itn",
                "trrc",
                "partnerEmail",
            ],
            partnerResponsibleInfo: ["email", "name", "msisdn"],
            ownerInfo: ["region", "username", "password", "email"],
        };

        for (const part of [
            "partnerInfo",
            "partnerResponsibleInfo",
            "ownerInfo",
        ]) {
            const fields = Object.keys(data[part]);

            for (const field of requiredKeys[part]) {
                if (!fields.includes(field)) {
                    have = false;
                }
            }
        }

        return have;
    }

    async createPartner() {
        const partnerBody = {
            partner: this.state.partnerInfo,
            responsible: this.state.partnerResponsibleInfo,
            owner: this.state.ownerInfo,
        };

        if (this.haveRequiredFields(this.state)) {
            const result = await api.createPartner(partnerBody);
            if (result.success) {
                Notifier.success(result.message);
            } else {
                Notifier.error(result.message);
            }
        } else {
            Notifier.warn("Необходимо заполнить обязательные поля");
        }
    }

    render() {
        return (
            <>
                {this.state.loading ? (
                    <div style={{ width: "100%", height: "500px" }}>
                        <SimpleLoader></SimpleLoader>
                    </div>
                ) : (
                    <div className="createpartners__content-wrapper">
                        <div className="createpartners__list">
                            {this.state.legalTypes.map((lt) => {
                                return (
                                    <div className="createpartners__item">
                                        <RadioButton
                                            label={lt.name}
                                            name={"partners-type"}
                                            id={lt.id}
                                            onChange={(id, checked) => {
                                                this.setOptionField(
                                                    "partnerInfo",
                                                    "legalTypeId",
                                                    Number(id)
                                                );
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                        <div className="createpartners__name-partner">
                            <div className="createpartners__label">
                                Введите наименование партнёра
                                <span className="createpartners__star">*</span>
                            </div>
                            <div className="createpartners__input">
                                {" "}
                                <Input
                                    onChangeValue={(v) =>
                                        this.setOptionField(
                                            "partnerInfo",
                                            "name",
                                            v
                                        )
                                    }
                                    placeholder="Пример - ООО «Партнер»"
                                />
                            </div>
                            <div className="createpartners__label">
                                Введите ключ партнёра
                                <span className="createpartners__star">*</span>
                            </div>
                            <div className="createpartners__input">
                                {" "}
                                <Input
                                    onChangeValue={(v) =>
                                        this.setOptionField(
                                            "partnerInfo",
                                            "key",
                                            v
                                        )
                                    }
                                    placeholder="Пример - partner"
                                />
                            </div>
                            <div className="createpartners__label">
                                Выберите статус партнёра
                                <span className="createpartners__star">*</span>
                            </div>
                            <div className="createpartners__input">
                                {" "}
                                <SingleDropdown
                                    values={mapArrayToDropdownElements(
                                        this.state.legalStatuses
                                    )}
                                    onChange={(v) =>
                                        this.setOptionField(
                                            "partnerInfo",
                                            "legalStatusId",
                                            Number(v)
                                        )
                                    }
                                />
                            </div>
                            <div className="createpartners__label">
                                Введите размер комиссии партнёра
                                <span className="createpartners__star">*</span>
                            </div>
                            <div className="createpartners__input">
                                {" "}
                                <Input
                                    type="number"
                                    onChangeValue={(v) =>
                                        this.setOptionField(
                                            "partnerInfo",
                                            "commission",
                                            Number(v)
                                        )
                                    }
                                    placeholder="Пример - 30"
                                />
                            </div>
                        </div>
                        {/* <div className='createpartners__type-partner'>
                    <div className='createpartners__label'>Выберите вид парнера</div>
                    <div className='createpartners__input'> <SingleDropdown values={this.state.type} /></div>
                </div> */}
                        <div className="createpartners__wrapper">
                            <div className="createpartners__id-partners">
                                <div className="createpartners__label">
                                    <div className="createpartners__text">
                                        Ввести идентификационные данные партнёра
                                    </div>
                                </div>
                                <div className="createpartners__list-items">
                                    <div className="createpartners__item">
                                        <div className="createpartners__label">
                                            Введите ИНН{" "}
                                            <span className="createpartners__star">
                                                *
                                            </span>
                                        </div>
                                        <Input
                                            placeholder={"Пример - 10987654321"}
                                            onChangeValue={(v) =>
                                                this.setOptionField(
                                                    "partnerInfo",
                                                    "itn",
                                                    v
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="createpartners__item">
                                        <div className="createpartners__label">
                                            Введите КПП
                                            <span className="createpartners__star">
                                                *
                                            </span>
                                        </div>
                                        <Input
                                            placeholder={"Пример - 987654321"}
                                            onChangeValue={(v) =>
                                                this.setOptionField(
                                                    "partnerInfo",
                                                    "trrc",
                                                    v
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="createpartners__item">
                                        <div className="createpartners__label">
                                            Введите email
                                            <span className="createpartners__star">
                                                *
                                            </span>
                                        </div>
                                        <Input
                                            onChangeValue={(v) =>
                                                this.setOptionField(
                                                    "partnerInfo",
                                                    "partnerEmail",
                                                    v
                                                )
                                            }
                                            placeholder={"Введите email"}
                                        />
                                    </div>
                                    <div className="createpartners__item">
                                        <div className="createpartners__label">
                                            Введите номер телефона
                                            <span className="createpartners__star">
                                                *
                                            </span>
                                        </div>
                                        <Input
                                            onChangeValue={(v) =>
                                                this.setOptionField(
                                                    "partnerInfo",
                                                    "partnerMsisdn",
                                                    v
                                                )
                                            }
                                            placeholder={
                                                "+7 ( ___ )  -  ___ __ __"
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="createpartners__resp-partner">
                                <div className="createpartners__label">
                                    <div className="createpartners__text">
                                        Ввести данные ответственного лица
                                    </div>
                                </div>
                                <div className="createpartners__list-items">
                                    <div className="createpartners__item">
                                        <div className="createpartners__label">
                                            Фамилия Имя Отчество (произвольно){" "}
                                            <span className="createpartners__star">
                                                *
                                            </span>
                                        </div>
                                        <Input
                                            onChangeValue={(v) =>
                                                this.setOptionField(
                                                    "partnerResponsibleInfo",
                                                    "name",
                                                    v
                                                )
                                            }
                                            placeholder={"Введите ФИО"}
                                        />
                                    </div>

                                    <div className="createpartners__item">
                                        <div className="createpartners__label">
                                            Введите номер телефона
                                            <span className="createpartners__star">
                                                *
                                            </span>
                                        </div>
                                        <Input
                                            onChangeValue={(v) =>
                                                this.setOptionField(
                                                    "partnerResponsibleInfo",
                                                    "msisdn",
                                                    v
                                                )
                                            }
                                            placeholder={
                                                "Введите номер телефона"
                                            }
                                        />
                                    </div>
                                    <div className="createpartners__item">
                                        <div className="createpartners__label">
                                            Введите email
                                            <span className="createpartners__star">
                                                *
                                            </span>
                                        </div>
                                        <Input
                                            onChangeValue={(v) =>
                                                this.setOptionField(
                                                    "partnerResponsibleInfo",
                                                    "email",
                                                    v
                                                )
                                            }
                                            placeholder={"Введите email"}
                                        />
                                    </div>
                                </div>
                                <div className="createpartners__resp-partner">
                                    <div className="createpartners__label">
                                        <div className="createpartners__text">
                                            Ввести данные владельца
                                        </div>
                                    </div>
                                    <div className="createpartners__list-items">
                                        <div className="createpartners__item">
                                            <div className="createpartners__label">
                                                Регион{" "}
                                                <span className="createpartners__star">
                                                    *
                                                </span>
                                            </div>
                                            {/* TODO: Пока всех партнеров заводим в RU */}
                                            <SingleDropdown
                                                values={mapArrayToDropdownElements(
                                                    this.state.locations
                                                )}
                                                onChange={(v) =>
                                                    this.setOptionField(
                                                        "ownerInfo",
                                                        "region",
                                                        "RU"
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="createpartners__item">
                                            <div className="createpartners__label">
                                                Имя{" "}
                                                <span className="createpartners__star">
                                                    *
                                                </span>
                                            </div>
                                            <Input
                                                onChangeValue={(v) =>
                                                    this.setOptionField(
                                                        "ownerInfo",
                                                        "firstName",
                                                        v
                                                    )
                                                }
                                                placeholder={"Введите имя"}
                                            />
                                        </div>
                                        <div className="createpartners__item">
                                            <div className="createpartners__label">
                                                Фамилия{" "}
                                                <span className="createpartners__star">
                                                    *
                                                </span>
                                            </div>
                                            <Input
                                                onChangeValue={(v) =>
                                                    this.setOptionField(
                                                        "ownerInfo",
                                                        "lastName",
                                                        v
                                                    )
                                                }
                                                placeholder={"Введите фамилию"}
                                            />
                                        </div>
                                        <div className="createpartners__item">
                                            <div className="createpartners__label">
                                                Отчество (если имеется){" "}
                                                <span className="createpartners__star">
                                                    *
                                                </span>
                                            </div>
                                            <Input
                                                onChangeValue={(v) =>
                                                    this.setOptionField(
                                                        "ownerInfo",
                                                        "middleName",
                                                        v
                                                    )
                                                }
                                                placeholder={"Введите отчество"}
                                            />
                                        </div>
                                        <div className="createpartners__item">
                                            <div className="createpartners__label">
                                                Имя пользователя {" "}
                                                <span className="createpartners__star">
                                                    *
                                                </span>
                                            </div>
                                            <Input
                                                onChangeValue={(v) =>
                                                    this.setOptionField(
                                                        "ownerInfo",
                                                        "username",
                                                        v
                                                    )
                                                }
                                                placeholder={
                                                    "Введите имя пользователя"
                                                }
                                            />
                                        </div>
                                        <div className="createpartners__item">
                                            <div className="createpartners__label">
                                                Введите номер телефона
                                                <span className="createpartners__star">
                                                    *
                                                </span>
                                            </div>
                                            <Input
                                                placeholder={
                                                    "+7 ( ___ )  -  ___ __ __"
                                                }
                                            />
                                        </div>
                                        <div className="createpartners__item">
                                            <div className="createpartners__label">
                                                Введите email
                                                <span className="createpartners__star">
                                                    *
                                                </span>
                                            </div>
                                            <Input
                                                onChangeValue={(v) =>
                                                    this.setOptionField(
                                                        "ownerInfo",
                                                        "email",
                                                        v
                                                    )
                                                }
                                                placeholder={"Введите email"}
                                            />
                                        </div>
                                        <div className="createpartners__item"></div>
                                        <div className="createpartners__item">
                                            <div className="createpartners__label">
                                                Введите пароль{" "}
                                                <span className="createpartners__star">
                                                    *
                                                </span>
                                            </div>
                                            <div className="createpartners__password-form">
                                                <PasswordForm
                                                    onChange={(v) =>
                                                        this.setOptionField(
                                                            "ownerInfo",
                                                            "password",
                                                            v
                                                        )
                                                    }
                                                    placeholder={"jkf887shf"}
                                                />
                                            </div>
                                        </div>
                                        <div className="createpartners__item">
                                            <div className="createpartners__label">
                                                Введите пароль еще раз 
                                                <span className="createpartners__star">
                                                    *
                                                </span>
                                            </div>
                                            <div className="createpartners__password-form">
                                                <PasswordForm
                                                    placeholder={"jkf887shf"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="createpartners__save-button">
                            <PrimaryButton
                                onClick={() => this.createPartner()}
                                text="Сохранить изменения"
                            />
                        </div>
                    </div>
                )}
            </>
        );
    }
}
