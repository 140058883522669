import React from "react";
import "./style.scss";

interface TertiaryButtonProps {
    text?: string;
    badgeInitial?: any;
    badgeOnHover?: any;
    onClick?: () => void;
}

interface TertiaryButtonState {
    hovering: boolean;
}

class TertiaryButton extends React.Component<TertiaryButtonProps, TertiaryButtonState> {

    constructor(props: TertiaryButtonProps){
        super(props)

        this.state = {
            hovering: false,
        }
    }

    onHover(hovering: boolean)
    {
        this.setState(
            {
                hovering: hovering
            }
        )
    }

    render() {
        const text = this.props.text || "";
        const onClickHandler = this.props.onClick || (() => {});
        const badge = (this.props.badgeInitial && this.props.badgeOnHover) ? (this.state.hovering ? this.props.badgeOnHover : this.props.badgeInitial) : false;
        return (
            <button 
                className="tertiary__button" 
                onClick={onClickHandler}
                onMouseEnter={() => this.onHover(true)}
                onMouseLeave={() => this.onHover(false)}
            >
                <div className={`tertiary__button_content ${this.state.hovering ? "hover" : ""}`}>
                    {text}
                    {
                        badge && 
                        <img
                            src={badge}
                            className="tertiary__button__icon"
                            alt="[ ]"
                        />
                    }
                </div>
            </button>
        );
    }
}

export default TertiaryButton;
