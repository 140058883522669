import React from "react";
import "./style.scss";
import searchIcon from "../../../Media/Common/magnifier-white.svg";
import Avatar from "../Avatar";
import HeaderDropdown from "../HeaderDropdown";
import { outsideClickHandler } from "../../../Utils";
import HeaderImg from "../../../Media/Images/abstract.svg";
import paApiHelper from "../../../Utils/personalAreaApi";

import Chevron from "../../../Media/Common/chevron-white.svg";
import Bell from "../../../Media/Common/bell-notified.svg";

interface HeaderState {
    headerDropdownActive: boolean;
    profileShort: any;
    loading: boolean;
}

export default class Header extends React.Component<{}, HeaderState> {
    refControls: any = null;
    constructor(props: any) {
        super(props);
        this.state = {
            headerDropdownActive: false,
            profileShort: null,
            loading: false,
        };
        this.refControls = React.createRef();
    }

    async componentDidMount() {
        const s = this.state;

        outsideClickHandler({
            bindObj: this,
            body: document,
            ref: this.refControls,
            action: () => this.openBlock(false),
            type: "click",
            condition: !s.headerDropdownActive,
        });

        this.setState({ loading: true });

        const profileShort = await paApiHelper.getProfileShort();

        if (profileShort && profileShort.success !== false) {
            this.setState({
                profileShort: profileShort,
            });
        }

        this.setState({ loading: false });
    }

    openBlock(force?: boolean) {
        const s = Object.assign({}, this.state);
        const open = force === undefined ? !s.headerDropdownActive : force;
        this.setState({
            headerDropdownActive: open,
        });
    }

    render() {
        const isActiveDropdown = this.state.headerDropdownActive;

        const profile = this.state.profileShort;

        let avatarImage = null;

        if(profile && profile.avatar)
        {
            avatarImage = profile.avatar.small;
        }

        return (
            <header className="header layout-2-column">
                {profile && (
                    <>
                        {/* <div className="header__wrapper search">
                            <form
                                className="search-form"
                                action=""
                                method="get"
                            >
                                <button
                                    className="search-form__button"
                                    type="submit"
                                >
                                    <img
                                        className="icon-search"
                                        src={searchIcon}
                                        alt="icon-search"
                                    />
                                </button>
                                <input
                                    className="search-form__input"
                                    name="s"
                                    placeholder="Начните вводить текст"
                                    type="search"
                                />
                            </form>
                            <img
                                alt="&"
                                src={Bell} 
                                className="notification"
                            />
                        </div> */}
                        <div
                            ref={this.refControls}
                            className="header__wrapper greetings"
                        >
                            <span className="greetings-text">
                                Привет, {profile.firstName || profile.username}!
                            </span>
                            <div className="header__avatar">
                                <Avatar size={50} src={avatarImage}/>
                            </div>
                            <img
                                onClick={() => this.openBlock()}
                                className="dropdown-arrow"
                                src={Chevron}
                                alt="v"
                            ></img>
                            {isActiveDropdown && (
                                <div className="dropdown-wrapper">
                                    <HeaderDropdown />
                                </div>
                            )}
                        </div>
                        {/* <div className='header__backgr-img'></div> */}
                        <img
                            className="header__backgr-img"
                            src={HeaderImg}
                            alt=""
                        ></img>
                    </>
                )}
            </header>
        );
    }
}
