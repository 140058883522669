import React from 'react';


export default class UnauthPage extends React.Component<{}, {}> {

    constructor(props: any) {
        super(props);
        this.state = {
            popupActive: false
        }
    }

    render() {
        const pStyle = {
            width: "80%"
        }
        return(
            <div style={{display: "flex", flexDirection: "column", textAlign: "center", alignItems: "center"}}>
                <p style={pStyle}>
                    Эта страница доступна только при разработке и говорит о том, что не выполнен вход
                </p>

                <p style={pStyle}>
                    Можно выполнить вход, нажав сюда - <a href={process.env.REACT_APP_AUTH_PAGE + "/?redirect=" + process.env.REACT_APP_PA_HOST}>тык</a> (если переменные окружения настроены правильно, то Вы попадете на страницу аутентификации)
                </p>

                <p style={pStyle}>
                    Или выполнить вход самостоятельно, подставить правильный параметр куки в браузере и вернуться в <a href="/">профиль</a> (если кука неверная - Вас вернет опять на эту же страницу, так что смотрите в консоль, чтобы понять, что не так)
                </p>
            </div>
        );
    }
}