import React from "react";
import "./style.scss";

export default class BreadcrumbsIndependentBlock extends React.Component {
    render() {

        return (
            <div className="breadcrumbs-independent__block">
                <div className="breadcrumbs-independent__label">
                    {this.props.children}
                </div>
            </div>
        );
    }
}
