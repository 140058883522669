import React from 'react';
import './style.scss';
import Plus from '../../../Media/Common/plus.svg';
import Cross from '../../../Media/Common/cross.svg';

interface AddRemoveButtonProps {
    add?: boolean,
    onClick?: () => void;
}

class AddRemoveButton extends React.Component<AddRemoveButtonProps> {


    render() {
        const add = this.props.add || false;
        const onClickHandler = this.props.onClick || (() => { });
        return (
            <button className="add_remove__button" onClick={onClickHandler}>
                {
                    add ?
                        <img src={Plus} className="add_remove__icon" alt="Plus" />
                        :
                        <img src={Cross} className="add_remove__icon" alt="Cross" />
                }
            </button>
        );
    }
}

export default AddRemoveButton;
