import React from "react";
import "./style.scss";

import Sidebar from "../../../Components/Layout/Sidebar";
import Header from "../../../Components/Layout/Header";

import Notifier from "../../../Components/Layout/Notifier";

export default class MainView extends React.Component {
    constructor(props: any) {
        super(props);
        this.state = {

        };
    }

    render() {

        return (
            <div className="app__wrapper">
                <Sidebar />
                <div className="wrapper__header-main">
                    <Header />
                    {this.props.children}
                </div>
                <Notifier/>
            </div>
        );
    }
}
