import React from "react";
import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import ProfilePage from "./Views/Profile";
import AnalyticsPage from "./Views/Analytics";
import HistoryPage from "./Views/History";
import RequestsPage from "./Views/Requests";
import ShedulePage from "./Views/Shedule";
import MyServicesPage from "./Views/MyServices";
import ServicesSetupPage from "./Views/ServicesSetup";
import UnauthPage from "./Views/UtilsPages/unauth";
import Partners from "./Views/Partners";
import Employees from "./Views/Employees";
import Register from "./Views/Register";
import Users from "./Views/Users";

class App extends React.Component {
    render() {
        const environment = process.env.REACT_APP_NODE_ENV;
        return (
            <div className="App">
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<ProfilePage></ProfilePage>} />
                        <Route
                            path="/services"
                            element={<MyServicesPage></MyServicesPage>}
                        />
                        <Route
                            path="/services-setup"
                            element={<ServicesSetupPage></ServicesSetupPage>}
                        />
                        <Route
                            path="/analytics"
                            element={<AnalyticsPage></AnalyticsPage>}
                        />
                        <Route
                            path="/operations"
                            element={<HistoryPage></HistoryPage>}
                        />
                        <Route
                            path="/requests"
                            element={<RequestsPage></RequestsPage>}
                        />
                        <Route
                            path="/schedule"
                            element={<ShedulePage></ShedulePage>}
                        />
                        <Route
                            path="/partners"
                            element={<Partners></Partners>}
                        />

                        <Route
                            path="/employees"
                            element={<Employees></Employees>}
                        />

                        <Route
                            path="/register-requests"
                            element={<Register></Register>}
                        />

                        <Route
                            path="/users"
                            element={<Users></Users>}
                        />

                        {environment !== "production" && (
                            <Route
                                path="/unauth"
                                element={<UnauthPage></UnauthPage>}
                            />
                        )}
                    </Routes>
                </BrowserRouter>
            </div>
        );
    }
}

export default App;
