import "./style.scss";

const Spinner = () => {
    return (
        <div className="loadingio-spinner-bars-tbkz09lga3s">
            <div className="ldio-clz1gia9v8">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default Spinner;