import React, { Component } from "react";
import "./style.scss";

import PopupWrapper, { PopupWrapperProps } from "../PopupWrapper";

import CancelIcon from "../../../../Media/Common/cancel.svg";
import { getFullName, mapArrayToDropdownElements, standartTimeFormat } from "../../../../Utils";
import moment from "moment";
import PrimaryButton from "../../../Buttons/PrimaryButton";
import Textarea from "../../../Inputs/Textarea";
import Calendar from "../../../Inputs/Calendar";
import SingleSelect from "../../../Dropdowns/Select/Single";

interface RejectPopupProps extends PopupWrapperProps {
    serviceRequest: any;
    reasons: any[];
    onReject: Function;
}

interface RejectPopupState {
    comment: string | null;
    reasonId: number;
}

export default class RejectRequestPopup extends Component<RejectPopupProps, RejectPopupState> {

    constructor(props: RejectPopupProps)
    {
        super(props);

        this.state = {
            comment: null,
            reasonId: 1,
        }
    }

    setComment(value: string) {
        let comment: any = value;
        if(value.length === 0)
        {
            comment = null;
        }

        this.setState({
            comment: comment,
        })
    }

    setReason(value: number) {
        let reasonId: number = Number(value);

        this.setState({
            reasonId: reasonId,
        })
    }



    render() {

        const request = this.props.serviceRequest;
        let serviceName = request.service.name;
        let username = getFullName(request.profile);

        return (
            <PopupWrapper
                active={this.props.active || false}
                onClose={() => this.props.onClose ? this.props.onClose() : () => {}}
            >
                <div className="popup-title-wrapper">
                    <div>Отмена заявки</div>
                    <img alt="+" src={CancelIcon} />
                </div>
                <div className="sr-popup-grid">
                    {/* Left */}
                    <div className="sr-popup-grid-column-left">
                        <div className="sr-popup-grid-item">Услуга:</div>
                        <div className="sr-popup-grid-item">Пользователь:</div>
                        <div className="sr-popup-grid-item">Причина:</div>
                    </div>
                    {/* Right */}
                    <div className="sr-popup-grid-column-right">
                        <div className="sr-popup-grid-item">{serviceName}</div>
                        <div className="sr-popup-grid-item">{username}</div>
                        <div className="sr-popup-calendar"><SingleSelect onChange={(value) => this.setReason(value)} values={mapArrayToDropdownElements(this.props.reasons)} placeholder={"Не выбрано"}/></div>
                    </div>
                </div>
                <div className="sr-popup-text-area">
                    <Textarea onChangeValue={(value) => this.setComment(value)} placeholder="Добавить сообщение (к примеру, Вы можете указать, как с Вами связаться в случае подтверждения заявки)" />
                </div>
                <div className="sr-popup-buttons">
                    <div className="sr-popup-button">
                        <PrimaryButton onClick={() => this.props.onReject({
                            comment: this.state.comment,
                            cancelReasonId: this.state.reasonId,
                        })} text="Отменить заявку"></PrimaryButton>
                    </div>
                </div>
            </PopupWrapper>
        );
    }
}
