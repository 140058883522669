import React from 'react';
import { Component, ReactNode } from 'react';
import './style.scss';


const { Provider: HeaderGreenProvider, Consumer: HeaderGreenConsumer } = React.createContext(false);
export {HeaderGreenProvider, HeaderGreenConsumer};

class TableChildren extends Component {

    render() {
        return (
            <table className='table-wrapper__table'>
                {this.props.children}
            </table>
        )
    }
}


interface TableHeadProps {
    isHeaderGreen: boolean,
    data: any,
}

class TableHead extends Component<TableHeadProps> {
    render(): ReactNode {
        const header = this.props.isHeaderGreen || false;
        const data = this.props.data || {};

        return (
            <thead className='table-wrapper__head'>
                <tr className='table-wrapper__tr'>
                    {Object.keys(data).map((key) => {
                        if(header)
                        {
                            return (
                                <th  key={Object.keys(data).indexOf(key, 0) || Math.random()} className='table-wrapper__th table-wrapper__green-text'>{data[key]}</th>
                            )
                        } 
                        else
                        {
                            return (
                                <th key={Object.keys(data).indexOf(key, 0) || Math.random()} className='table-wrapper__th'>{data[key]}</th>
                            )
                        }
                    })}
                </tr>
            </thead> 
        ) 
    }
    
}



interface TableRowProps {
    isHeader: any,
    data: any,
    numbersGreenColumn: number[]
}

class TableRow extends Component<TableRowProps> {

    render() {
        const header = this.props.isHeader || false;
        const data = this.props.data || {};
        const numbersGreenColumn = this.props.numbersGreenColumn || [];

        const createClassNameTd = (className: string, numbersGreenColumn: number[], index: number) => {

                numbersGreenColumn.forEach((numberColumn) => {

                    switch(numberColumn - 1){
                        case index:
                            className += ' table-wrapper__green-text';
                            break;
                        default:
                            return className;
                    } 
                    
                    return className;

                });

            return className;
        };

        return (
            <tr className='table-wrapper__tr'>
                {Object.keys(data).map((key) => {

                    let classNameTd = 'table-wrapper__td';
                    let indexElem = Object.keys(data).indexOf(key);

                    if(header)
                    {
                        return (
                            <th key={indexElem} className='table-wrapper__th'>{data[key]}</th>
                        )
                    }
                    else
                    {
                        return (
                            <td key={Object.keys(data).indexOf(key)} 
                                className={createClassNameTd(classNameTd, numbersGreenColumn, indexElem)}>
                            {data[key]}</td>
                        )
                    }
                })}
            </tr> 
        )
    }
}

interface TableBodyProps {
    bodyData: any;
    numberGreenColumn: any;
    rowsCount: number;
    offset: number;
}

interface TableBodyState {
    offset: number
}

class TableBody extends Component<TableBodyProps, TableBodyState> {

    constructor(props: TableBodyProps)
    {
        super(props);

        this.state = {
            offset: 0
        }
    }

    render(): ReactNode {

        const actualBodyData = this.props.bodyData || [];
        const numberGreenColumn = this.props.numberGreenColumn || [];

        const bodyData = [...actualBodyData];

        return(
            <tbody className='table-wrapper__tbody'>
            {
                bodyData.map((element: any) => {

                    let hash = "";

                    for(const k of Object.values(element))
                    {
                        if(["string", "number", "boolean"].includes(typeof k))
                        {
                            hash += k;
                        }
                    }

                    //bodyData.indexOf(element, 0)
                    return (
                        <TableRow key={hash} isHeader={false} data={element} numbersGreenColumn={numberGreenColumn}></TableRow>
                    )
                })
            }
        </tbody>
        )
    }
}

interface TableWrapperProps {
    headerData?: any,
    bodyData?: any,
    numberGreenColumn?: number[];
    total?: number;
    rowsCount?: number | 10;
    onChangePage?: (offset: number) => void;
}

interface TableWrapperState {
    offset: number;
    rowsCount: number;
}

export default class TableWrapper extends Component<TableWrapperProps, TableWrapperState> {

    constructor(props: TableWrapperProps)
    {
        super(props);

        this.state = {
            offset: 0,
            rowsCount: props.rowsCount || 10,
        }
    }

    componentDidUpdate(prevProps: TableWrapperProps, prevState: TableWrapperState): void {
        if(this.state.offset !== prevState.offset)
        {
            if(this.props.onChangePage)
            {
                this.props.onChangePage(this.state.offset);
            }
        }
    }

    setOffset(offset: number)
    {
        if(offset < 0)
        {
            offset = 0;
        }

        if(this.props.total)
        {
            if(offset > this.props.total)
            {
                offset = Math.floor(this.props.total / this.state.rowsCount) * this.state.rowsCount;
            }
        }

        this.setState({offset: offset})
    }

    render() {

        const headerData = this.props.headerData || {};
        const bodyData = this.props.bodyData || [];
        const numberGreenColumn = this.props.numberGreenColumn || [];

        const total = this.props.total || 0;
        const rowsCount = this.state.rowsCount;

        const pages = Array.from(Array(Math.floor(total / rowsCount + (total % rowsCount === 0 ? 0 : 1))).keys());

        return (
            <div>
                <div className='table-wrapper-control'>
                    {
                        total > 0 && pages.length > 1 && <div className='table-wrapper-page-control'>
                            <div 
                                className='table-wrapper-page-control-button'
                                onClick={() => this.setOffset(this.state.offset - rowsCount)}
                            >{"<"}</div>
                            {
                                pages.map((num: any) => {
                                    return (
                                        <div 
                                            className={`table-wrapper-page ${this.state.offset / rowsCount === num ? "active" : ""}`}
                                            onClick={() => this.setOffset(num * rowsCount)}
                                        >
                                            <span>{num + 1}</span>
                                        </div>
                                    )
                                })
                            }
                            <div 
                                className='table-wrapper-page-control-button'
                                onClick={() => this.setOffset(this.state.offset + rowsCount)}
                            >{">"}</div>
                        </div>
                    }
                </div>
                <TableChildren>
                    <HeaderGreenConsumer>
                        {
                            (isHeaderGreen) => {
                                return (
                                    <TableHead isHeaderGreen={isHeaderGreen} data={headerData} />
                                );
                            }
                        }
                    </HeaderGreenConsumer>
                    <TableBody
                        rowsCount={rowsCount}
                        offset={this.state.offset}
                        bodyData={bodyData} 
                        numberGreenColumn = {numberGreenColumn}
                    />
                </TableChildren>
            </div>
            
        );
    }
}



// ПРИМЕРЫ ДАННЫХ:

// const headElem1 = { name: "Пользователь", count: "Запрашиваемая услуга", date: "Время и дата", details: "Управление" }

// const headElem2 = {sender: "Отправитель", getter: "Получатель", sum: "Сумма", action: "Действие"}

// const test_2_data_1 = {sender: "Иванов И.И.", getter: "Петров П.П.", sum: "42 000 р", action: <button onClick={() => console.log(42)}>Нажать</button>}
// const test_2_data_2= {sender: "Сидоров С.С.", getter: "Петров П.П.", sum: "142 000 р", action: <button onClick={() => console.log(142)}>Нажать</button>}

// ПРИМЕРЫ ПЕРЕДАЧИ ДАННЫХ:

// {/* <TableChildren>
//     <TableRow isHeader={true} data={headElem1}></TableRow>
//     <TableRow  isHeader={false} data={{ name: "Test", count: 42, date: String(createTimeForTableFeedback(users.dateTime)), details: "Какие-то детали" }}></TableRow>
// </TableChildren>

// <br></br>

// <TableChildren>
//     <TableRow isHeader={true} data={headElem2}></TableRow>
//     <TableRow isHeader={false} data={test_2_data_1}></TableRow>
//     <TableRow isHeader={false} data={test_2_data_2}></TableRow>
// </TableChildren> */}

