import React from "react";
// import "./style.scss";
import "./security.scss";
import ReadonlyText from "../../../Components/Inputs/ReadonlyText";
import Input from "../../../Components/Inputs/Input";
import PrimaryButton from "../../../Components/Buttons/PrimaryButton";
import EmailForm from "../../../Components/Inputs/ValidationForm/EmailForm";
import PasswordForm from "../../../Components/Inputs/ValidationForm/PasswordForm";

interface SecurityProps {
    profile?: any;
}

interface SecurityState {
    newPhone: string;
    newEmail: string;
    emailChange: boolean;
    oldPassword: string;
    newPassword: string;
    newPasswordRepeat: string;
    passwordChange: boolean;
}

export default class SecurityView extends React.Component<SecurityProps, SecurityState> {
    constructor(props: SecurityProps) {
        super(props);
        this.state = {
            newPhone: "",
            newEmail: "",
            emailChange: false,
            oldPassword: "",
            newPassword: "",
            newPasswordRepeat: "",
            passwordChange: false,
        };
    }

    setOldPassword(password: string) {
        this.setState({oldPassword: password})
    }

    setNewPassword(password: string) {
        this.setState({newPassword: password})
    }

    setNewPasswordRepeat(password: string) {
        this.setState({newPasswordRepeat: password})
    }

    showHideEmailChange()
    {
        const emailChange = this.state.emailChange;

        this.setState({
            emailChange: !emailChange,
        })
    }

    showHidePasswordChange()
    {
        const passwordChange = this.state.passwordChange;

        this.setState({
            passwordChange: !passwordChange,
        })
    }

    render() {
        const profile = this.props.profile;
        return <main className="main">
            <div>TODO: сделать, как будут макеты с новым UX</div>
        </main> 
        // return (
        //     <main className="main">
        //         {profile !== null && 
        //         <div className="content__wrapper">
        //             <div className="security__wrapper">
        //                 {/* <div className="security__item">
        //                     <div className="security-title">Номер телефона</div>
        //                     <div className="security-input">
        //                         <Input />
        //                     </div>
        //                 </div> */}
        //                 <div className="security__item">
        //                     <div className="security-title">Email</div>
        //                     <div className="security-input">
        //                         <ReadonlyText defaultValue={profile.email}/>
        //                     </div>
        //                     <div 
        //                         className="security-change"
        //                         onClick={() => this.showHideEmailChange()}
        //                     >
        //                         {this.state.emailChange ? "Отмена" : "Изменить"}
        //                     </div>
        //                     {
        //                         this.state.emailChange && <div>
        //                             <br/>
        //                             <div className="security-subtitle">Введите новый Email</div>
        //                             <div className="security-input">
        //                                 <EmailForm/>
        //                             </div>
        //                         </div>
        //                     }
        //                 </div>
        //                 <div className="security__item">
        //                     <div className="security-title">Пароль</div>
        //                     {/* <div className="security-input">
        //                         <Input />
        //                     </div> */}
        //                     <div 
        //                         className="security-change"
        //                         onClick={() => this.showHidePasswordChange()}
        //                     >
        //                         {this.state.passwordChange ? "Отмена" : "Изменить"}
        //                     </div>
        //                     {
        //                         this.state.passwordChange && <div>
        //                             <br/>
        //                             <div className="security-subtitle">Введите старый пароль</div>
        //                             <div className="security-input">
        //                                 <PasswordForm showInfo={false} onChange={(password) => this.setOldPassword(password)}/>
        //                             </div>
        //                             <br/>
        //                             <div className="security-subtitle">Введите новый пароль</div>
        //                             <div className="security-input password">
        //                                 <PasswordForm onChange={(password) => this.setNewPassword(password)}/>
        //                             </div>
        //                             <br/>
        //                             <div className="security-subtitle">Введите новый пароль еще раз</div>
        //                             <div className="security-input password">
        //                                 <PasswordForm showInfo={false} onChange={(password) => this.setNewPasswordRepeat(password)}/>
        //                                 <div className="container-info">
        //                                     <div
        //                                         className={`info ico-grey ${
        //                                             this.state.newPassword === this.state.newPasswordRepeat && this.state.newPassword !== ""
        //                                                 ? 
        //                                                 "green ico-green"
        //                                                 : "red ico-red"
        //                                                 // : ""
        //                                         }`}
        //                                     >
        //                                     <img
        //                                         // src={IcoCheckGrey}
        //                                         className="icon-check"
        //                                         alt="icon-check"
        //                                     />
        //                                         Пароли должны совпадать
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                             <div style={{width: "250px", height: "50px"}}>
        //                                 <PrimaryButton text="Изменить пароль"/>
        //                             </div>
        //                         </div>
        //                     }
        //                 </div>
        //             </div>
        //         </div>}
        //     </main>
        // );
    }
}
