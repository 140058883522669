import React from 'react';

// import '../style.scss';

import MainView from "../Common/MainView";
import Breadcrumbs from "../../Components/Layout/Breadcrumbs";
import { BreadcrumbsItem } from "../../Components/Layout/Breadcrumbs/types";

import api from "../../Utils/personalAreaApi";

import AvailablilitySection from './Sections/availability';

const breadcrumbsButtons: BreadcrumbsItem[] = [
    // {
    //     id: 1,
    //     name: "Расписание",
    //     path: "/schedule/?section=my-schedule",
    // },
        {
        id: 2,
        name: "Настройка доступности",
        path: "/schedule/?section=availablility",
    },
];

class ShedulePage extends React.Component {

    async componentDidMount() {
        
    }

    render() {
        const queryParams = new URLSearchParams(window.location.search);
        let section = "availability";

        const querySectionParam = queryParams.get("section");
        switch (querySectionParam) {
            case "my-schedule":
                section = "my-schedule";
                break;
            default:
                section = "availablility";
                break;
        }

        return (
            <MainView>
                <Breadcrumbs
                    breadcrumbsButtons={breadcrumbsButtons}
                ></Breadcrumbs>
                {(() => {
                    switch (section) {
                        case "availablility":
                            return <AvailablilitySection/>
                        default:
                            return <></>;
                    }
                })()}
            </MainView>
        )
    }
}

export default ShedulePage;
