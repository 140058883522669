import React, { Component } from "react";
import "./style.scss";

import PopupWrapper, { PopupWrapperProps } from "../PopupWrapper";

import ClockIcon from "../../../../Media/Icons/ico-clock.svg";
import { getFullName, standartTimeFormat } from "../../../../Utils";
import moment from "moment";
import PrimaryButton from "../../../Buttons/PrimaryButton";
import Textarea from "../../../Inputs/Textarea";
import Calendar from "../../../Inputs/Calendar";

interface PostponePopupProps extends PopupWrapperProps {
    serviceRequest: any;
    onPostpone: Function;
}

interface PostponePopupState {
    comment: string | null;
    serviceDate: moment.Moment;
}

export default class PostponePopup extends Component<PostponePopupProps, PostponePopupState> {

    constructor(props: PostponePopupProps)
    {
        super(props);

        this.state = {
            comment: null,
            serviceDate: moment(props.serviceRequest.serviceDate * 1000)
        }
    }

    setComment(value: string) {
        let comment: any = value;
        if(value.length === 0)
        {
            comment = null;
        }

        this.setState({
            comment: comment,
        })
    }

    setServiceDate(value: moment.Moment) {
        let serviceDate: moment.Moment = value;

        this.setState({
            serviceDate: serviceDate,
        })
    }



    render() {

        const request = this.props.serviceRequest;
        let serviceName = request.service.name;
        let username = getFullName(request.profile);

        return (
            <PopupWrapper
                active={this.props.active || false}
                onClose={() => this.props.onClose ? this.props.onClose() : () => {}}
            >
                <div className="popup-title-wrapper">
                    <div>Перенос времени</div>
                    <img alt="+" src={ClockIcon} />
                </div>
                <div className="sr-popup-grid">
                    {/* Left */}
                    <div className="sr-popup-grid-column-left">
                        <div className="sr-popup-grid-item">Услуга:</div>
                        <div className="sr-popup-grid-item">Пользователь:</div>
                        <div className="sr-popup-grid-item">Дата и время:</div>
                    </div>
                    {/* Right */}
                    <div className="sr-popup-grid-column-right">
                        <div className="sr-popup-grid-item">{serviceName}</div>
                        <div className="sr-popup-grid-item">{username}</div>
                        <div className="sr-popup-calendar"><Calendar onChange={(value) => this.setServiceDate(value)} showTime date={moment(request.serviceDate * 1000)}/></div>
                    </div>
                </div>
                <div className="sr-popup-text-area">
                    <Textarea onChangeValue={(value) => this.setComment(value)} placeholder="Добавить сообщение (к примеру, Вы можете указать, как с Вами связаться в случае подтверждения заявки)" />
                </div>
                <div className="sr-popup-buttons">
                    <div className="sr-popup-button">
                        <PrimaryButton onClick={() => this.props.onPostpone({
                            comment: this.state.comment,
                            serviceDate: this.state.serviceDate.unix(),
                        })} text="Перенести заявку"></PrimaryButton>
                    </div>
                </div>
            </PopupWrapper>
        );
    }
}
