import React from "react";
import "./style.scss";

import "../common.scss";

export default class TitleTextBlock extends React.Component {
    render() {
        return (
            <div className="text-block">
                <div className="title-block-text">{this.props.children}</div>
            </div>
        );
    }
}
