import React from "react";
import "./style.scss";
import logo from "../../../Media/Images/logo.svg";

import paApiHelper from "../../../Utils/personalAreaApi";

import icoApp from '../../../Media/Icons/ico-service.svg';
import icoAnl from '../../../Media/Icons/ico-analytics.svg';
import icoHstr from '../../../Media/Icons/ico-history.svg';
import icoSch from '../../../Media/Icons/ico-schedule.svg';
import icoSrvc from '../../../Media/Icons/ico-service.svg';
import icoSrvcStp from '../../../Media/Icons/ico-services-setup.svg';
import icoUsers from '../../../Media/Icons/ico-users.svg';
import icoEmployees from '../../../Media/Icons/ico-employees.svg';
import icoContent from '../../../Media/Icons/ico-pencil.svg';
import icoRegistration from '../../../Media/Icons/ico-registration.svg';

const icoIndexMap: any = {
    1: icoSch,
    2: icoApp,
    3: icoHstr,
    4: icoAnl,
    5: icoSrvc,
    6: icoSrvcStp,
    7: icoUsers,
    8: icoEmployees,
    9: icoApp,
    10: icoRegistration,
    11: icoSrvcStp,
    12: icoContent,
    13: icoApp,
    14: icoApp,
    15: icoApp,
}

interface SidebarState {
    sideBarItems: any[]
}

export default class Sidebar extends React.Component<{}, SidebarState> {

    constructor(props: any) {
        super(props)

        this.state = {
            sideBarItems: []
        }
    }

    async componentDidMount() {
        const result = await paApiHelper.getSidebar();

        if(result.success !== false)
        {
            this.setState({sideBarItems: result.functions});
        }
        else
        {
            // window.open("/unauth", "_self") //TODO: Заменить на Что-по пошло не так :(
        }

    }

    sanitize(s: string)
    {
        if(!s)
        {
            return "/";
        }
        const sanitized = s.replace(/\//g, "");
        return sanitized;
    }

    render() {
        const pathName = this.sanitize(window.location.pathname);

        return (
            <aside className="sidebar">
                <div className="logo">
                    <a href={process.env.REACT_APP_LANDING_HOST}>
                        <img src={logo} alt="logo-adabstar" />
                    </a>
                </div>
                <nav className="nav-menu">
                    <div className="nav-menu__items">
                        {this.state.sideBarItems.map((element: any) => (
                            <a
                                key={element.id}
                                href={element.funcKey}
                                className={`nav-menu__item active-bg-hover ${
                                    pathName === this.sanitize(element.funcKey) && element.funcKey !== ''
                                        ? "active-background"
                                        : ""
                                }`}
                            >
                                <div
                                    className={`nav-menu__icon`}
                                >
                                    <img className="nav-menu__icon__img" src={icoIndexMap[element.id]} alt="1"></img>
                                </div>
                                {element.name}
                                {pathName.includes(element.funcKey) && element.funcKey !== '' && (
                                    <div className="container-mark">
                                        <div className="active-mark"></div>
                                    </div>
                                )}
                            </a>
                        ))}
                    </div>
                </nav>
            </aside>
        );
    }
}
