import React from "react";
import "./availability.scss";

import api from "../../../Utils/personalAreaApi";
import RadioButton from "../../../Components/Inputs/RadioButton";
import Checkbox from "../../../Components/Inputs/Checkbox";
import moment, { Moment } from "moment";

import Notifier from "../../../Components/Layout/Notifier/store";
import SimpleLoader from "../../../Components/Layout/Loaders/Resizeable";
import ArrowIcon from "../../../Media/Common/arrow.svg";
import ChevronBlackIcon from "../../../Media/Common/chevron-black.svg";

import PrimaryButton from "../../../Components/Buttons/PrimaryButton";
import Secondary2Button from "../../../Components/Buttons/Secondary1Button";

// const weekdaysMap: any = {
//     "1": "MONDAY",
//     "2": "TUESDAY",
//     "3": "WEDNESDAY",
//     "4": "THURSDAY",
//     "5": "FRIDAY",
//     "6": "SATURDAY",
//     "0": "SUNDAY",
// }

const weekdaysMap: any = {
    "1": "Понедельник",
    "2": "Вторник",
    "3": "Среда",
    "4": "Четверг",
    "5": "Пятница",
    "6": "Суббота",
    "0": "Воскресенье",
}


interface AvailablilitySectionState {
    schedule: any;
    currentEditSchedule: any;
    loading: boolean;
    currentWeekStart: number;
    currentWeekEnd: number;
    currentWeekdaysStarts: number[];
}

interface AvailabilityDayProps {
    start: number;
    availableSlots: any[];
    stubName?: string;
    addSlotHandler?: Function;
}

class AvailabilityDay extends React.Component<AvailabilityDayProps, {}> {
    constructor(props: any)
    {
        super(props);
    }

    getHoursIntervals()
    {
        const intervals = [];
        const start = this.props.start;
        for(let i = start; i < start + 24 * 60 * 60; i+=3600)
        {
            intervals.push(i);
        }

        return intervals;
    }

    isChecked(statTime: number)
    {
        const slot = this.props.availableSlots.find(as => {
            if(as.from === statTime && as.available)
            {
                return true;
            }
            return false;
        })

        return slot ? true : false;
    }

    onAvailabilityChange(timeSlot: any)
    {
        if(this.props.addSlotHandler)
        {
            this.props.addSlotHandler(timeSlot);
        }
    }

    render()
    {
        return <div className="availability-day-wrapper">
                <div className="availability-day-name">
                    {
                        this.props.stubName ? this.props.stubName : `${weekdaysMap[String(moment(this.props.start * 1000).day())]} (${moment(this.props.start * 1000).format("DD/MM/YYYY")})`
                    }
                </div>
                <div className="availability-day-slots">
                    {
                        this.getHoursIntervals().map(h => {
                            return <div key={`slot_${h}`}>
                                {
                                    this.props.stubName 
                                    ?
                                    <div className="availability-day-slots-display">
                                        {moment(h * 1000).format("HH:mm")}
                                    </div>
                                    :
                                    (this.props.addSlotHandler && 
                                    <Checkbox
                                        checked={this.isChecked(h)}
                                        onChange={(checked: boolean) => this.onAvailabilityChange({
                                            from: h,
                                            to: h + 3600,
                                            available: checked
                                        })}
                                    />)
                                }
                                
                            </div>
                        })
                    }
                </div>
            </div>
    }
}


class AvailablilitySection extends React.Component<
    {},
    AvailablilitySectionState
> {
    constructor(props: any) {
        super(props);

        const currentWeek = this.getCurrentWeekRange(moment());

        this.state = {
            schedule: {
                alwaysAvailable: false,
                schedulesSlots: [],
            },
            currentEditSchedule: {
                alwaysAvailable: false,
                slots: [],
            },
            loading: false,
            currentWeekStart: currentWeek.start,
            currentWeekEnd: currentWeek.end,
            currentWeekdaysStarts: currentWeek.weekdays,
        };
    }

    async getSchedule(from: number, to: number)
    {
        const getSchedule = await api.getSchedule(from, to);

        if (getSchedule.success === false) {
            Notifier.error(getSchedule.message);
        } else {
            
            this.setState({
                schedule: getSchedule,
                loading: false,
            });
        }
    }

    async componentDidMount() {
        this.setState({ loading: true });

        const from = this.state.currentWeekStart;
        const to = this.state.currentWeekEnd;

        await this.getSchedule(from, to);
        
    }


    updateSchedule(alwaysAvailable: boolean) {
        const schedule = Object.assign({}, this.state.currentEditSchedule);

        schedule.alwaysAvailable = alwaysAvailable;

        this.setState({ currentEditSchedule: schedule });
    }

    async editSchedule() {
        
        const res = await api.editSchedule(this.state.currentEditSchedule);

        if(res.success)
        {
            Notifier.success(res.message)
        }
        else
        {
            Notifier.error(res.message)
        }
    }

    getCurrentWeekRange(currentDay: Moment)
    {
        const startOfWeek = moment(currentDay).startOf('week').toDate();
        const endOfWeek   = moment(currentDay).endOf('week').toDate();

        const start = Math.floor(startOfWeek.getTime() /  1000);
        const end = Math.floor(endOfWeek.getTime() /  1000);

        const weekdays = [];

        for(let i = start; i < end; i += 24 * 60 * 60)
        {
            weekdays.push(i)
        }

        return {
            start: start,
            end: end,
            weekdays: weekdays,
        }
    }

    async increaseWeek(size: number) {
        
        const newDate = moment(this.state.currentWeekStart * 1000).add(7 * size, "days");

        const newRange = this.getCurrentWeekRange(newDate);


        await this.getSchedule(newRange.start, newRange.end);


        this.setState({
            currentWeekStart: newRange.start,
            currentWeekEnd: newRange.end,
            currentWeekdaysStarts: newRange.weekdays,
        });
    }

    addSlot(timeSlot: any)
    {
        const currentEditSchedule = Object.assign({}, this.state.currentEditSchedule);

        const slot = currentEditSchedule.slots.find((s: any) => s.from === timeSlot.from);

        if(slot)
        {
            slot.available = timeSlot.available;
        }
        else
        {
            currentEditSchedule.slots.push(timeSlot);
        }

        this.setState({
            currentEditSchedule: currentEditSchedule,
        })
    }
    
    render() {
        return (
            <div className="availability-wrapper">
                {this.state.loading ? (
                    <SimpleLoader />
                ) : (
                    <>
                        <span className="availability-title">Доступность (ваше текущее время - {`${moment().format("HH:mm DD/MM/YYYY")}`})</span>
                        <div className="availability-calendar-wrapper">
                            <div
                                onClick={() => this.increaseWeek(-1)}
                                className="availability-calendar-button"
                            >
                                <img
                                    src={ArrowIcon}
                                    style={{ transform: "rotate(-180deg)" }}
                                    alt="->"
                                ></img>
                            </div>
                            <span className="availability-caledar-period">
                                {moment(this.state.currentWeekStart * 1000).format("DD/MM/YYYY")}{" "}
                                -
                                {" "}{moment(this.state.currentWeekEnd * 1000).format("DD/MM/YYYY")}
                            </span>
                            <div
                                onClick={() => this.increaseWeek(1)}
                                className="availability-calendar-button"
                            >
                                <img src={ArrowIcon} alt="->"></img>
                            </div>
                        </div>
                        <div className="availability-modes">
                            <div className="availability-mode">
                                <span>{"Доступен в любое время"}</span>
                                <RadioButton
                                    key={this.state.currentWeekStart}
                                    name="mode"
                                    id={"1"}
                                    checked={this.state.schedule.alwaysAvailable === true}
                                    onChange={(id, checked) =>
                                        this.updateSchedule(true)
                                    }
                                ></RadioButton>
                            </div>
                            <div className="availability-mode">
                                <span>{"Доступен по расписанию"}</span>
                                <RadioButton
                                    key={this.state.currentWeekEnd}
                                    name="mode"
                                    id={"2"}
                                    checked={this.state.schedule.alwaysAvailable === false}
                                    onChange={(id, checked) =>
                                        this.updateSchedule(false)
                                    }
                                ></RadioButton>
                            </div>
                        </div>
                        <div className="availability-days-title">
                            Нажми на «время» и выбери промежутки времени (1 час), в
                            которые ты будешь доступен для работы
                        </div>
                        <div className="availability-days-wrapper">
                            <AvailabilityDay availableSlots={[]} stubName="Дни недели" start={this.state.currentWeekdaysStarts[0]}/>
                            {
                                this.state.currentWeekdaysStarts.map(wd => {
                                    return <AvailabilityDay availableSlots={this.state.schedule.schedulesSlots} addSlotHandler={(timeSlot: any) => this.addSlot(timeSlot)} start={wd}/>
                                })
                            }
                        </div>
                        <div>
                            <div
                                style={{
                                    marginTop: "40px",
                                    width: "300px",
                                    height: "50px",
                                }}
                            >
                                <PrimaryButton
                                    text="Сохранить изменения"
                                    onClick={() => this.editSchedule()}
                                ></PrimaryButton>
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }
}

export default AvailablilitySection;
