import React from "react";
import "./style.scss";
import Button from "../../../Buttons/PrimaryButton";
import PopupWrapper, { PopupWrapperProps } from "../PopupWrapper";
import Input from "../../../Inputs/Input";

interface PartnersDetailsProps extends PopupWrapperProps {
    partner?: any;
    onEdit?: Function;
}

interface PartnersDetailsState {
    commission: number;
}

export default class PartnersDetails extends React.Component<
    PartnersDetailsProps,
    PartnersDetailsState
> {
    constructor(props: any) {
        super(props);

        this.state = {
            commission: props.partner? Number(props.partner.commission) : 0,
        }
    }

    handleClose() {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    handleEdit() {
        if(this.props.onEdit)
        {
            this.props.onEdit({
                commission: this.state.commission,
            });
        }
    }

    render() {
        const partner = this.props.partner || {};
        return (
            <PopupWrapper
                active={this.props.active || false}
                onClose={() => this.handleClose()}
            >
                {partner ? (
                    <>
                        <p className="details-popup__title">Партнер {partner.name}</p>
                        <div className="details-popup-wrapper">
                            <div className="details-popup__item">
                                <div className="details-popup__label popup-underline">
                                    Email:
                                </div>
                                <span className="details-popup__filled-info">
                                    {" "}
                                    {partner.partnerEmail}
                                </span>
                            </div>
                            <div className="details-popup__item">
                                <div className="details-popup__label">
                                    Телефон:
                                </div>
                                <span className="details-popup__filled-info">
                                    {" "}
                                    {partner.partnerMsisdn}
                                </span>
                            </div>
                            <div className="details-popup__item">
                                <div className="details-popup__label">ИНН:</div>
                                <span className="details-popup__filled-info">
                                    {" "}
                                    {partner.itn}
                                </span>
                            </div>
                            <div className="details-popup__item">
                                <div className="details-popup__label">КПП:</div>
                                <span className="details-popup__filled-info">
                                    {" "}
                                    {partner.trrc}
                                </span>
                            </div>
                            <div className="details-popup__item">
                                <div className="details-popup__label">Контактное лицо:</div>
                                <span className="details-popup__filled-info">
                                    {" "}
                                    {partner.responsibleName}
                                </span>
                            </div>
                            <div className="details-popup__item">
                                <div className="details-popup__label">Email контактного лица:</div>
                                <span className="details-popup__filled-info">
                                    {" "}
                                    {partner.responsibleEmail}
                                </span>
                            </div>
                            <div className="details-popup__item">
                                <div className="details-popup__label">Телефон контактного лица:</div>
                                <span className="details-popup__filled-info">
                                    {" "}
                                    {partner.responsibleMsisdn}
                                </span>
                            </div>
                            <div className="details-popup__item-without-line">
                                <div className="details-popup__label popup-underline">
                                    Комиссия:
                                </div>
                                <span className="details-popup__filled-info">
                                    {" "}
                                    <Input disabled type="number" onChangeValue={(e) => this.setState({commission: Number(e)})} defaultValue={String(this.state.commission)}/>%
                                </span>
                            </div>
                        </div>
                        <div className="details-popup__button-save">
                            <Button disabled onClick={() => this.handleEdit()} text="Сохранить изменения" />
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </PopupWrapper>
        );
    }
}
