import React from 'react';
import './style.scss';

interface ToggleProps {
    disabled?: boolean;
    checked?: boolean;
    onChange?: (checked: boolean) => void;
}

class Toggle extends React.Component<ToggleProps> {

    render() {
        const onChangeHandler = this.props.onChange || (() => { });
        return (
            <div className='toggle'>
                <label className="checkbox-ios">
                    <input type="checkbox" disabled={this.props.disabled} defaultChecked={this.props.checked} onChange={(e) => onChangeHandler(e.target.checked)} />
                    <div className="checkbox-ios-switch"></div>
                </label>
            </div>

        );
    }
}

export default Toggle;
