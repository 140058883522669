import React from "react";
import "./style.scss";
import PartnersView from "./partners";
import CreatePartnersView from "./createPartners";

import Breadcrumbs from "../../Components/Layout/Breadcrumbs";
import { BreadcrumbsItem } from "../../Components/Layout/Breadcrumbs/types";
import MainView from "../Common/MainView";

const breadcrumbsButtons: BreadcrumbsItem[] = [
    {
        id: 1,
        name: "Партнёры",
        path: "/partners/?section=partners",
    },
    {
        id: 2,
        name: "Создание партнёра",
        path: "/partners/?section=createPartners",
    },
];

interface ProfileState {
    popupActive: boolean;
}

export default class Partners extends React.Component<{}, ProfileState> {
    render() {
        const queryParams = new URLSearchParams(window.location.search);
        let section = "partners";

        const querySectionParam = queryParams.get("section");
        switch (querySectionParam) {
            case "partners":
                section = "partners";
                break;
            case "createPartners":
                section = "createPartners";
                break;
        }

        return (
            <MainView>
                <Breadcrumbs
                    breadcrumbsButtons={breadcrumbsButtons}
                ></Breadcrumbs>
                {(() => {
                    switch (section) {
                        case "createPartners":
                            return (
                                <CreatePartnersView
                                    label={""}
                                    name={""}
                                    id={""}
                                />
                            );
                        default:
                            return <PartnersView onOpenPopup />;
                    }
                })()}
            </MainView>
        );
    }
}
