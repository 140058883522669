import React from "react";
import "./style.scss";

export class H1 extends React.Component {
    render() {
        return (
            <h1>
                {this.props.children}
            </h1>
        );
    }
}

export class H2 extends React.Component {
    render() {
        return (
            <h2>
                {this.props.children}
            </h2>
        );
    }
}

interface FieldHeadingProps {
    required?: boolean;
}

export class FieldHeading extends React.Component<FieldHeadingProps,{}> {
    render() {
        return (
            <h1 className="field-heading">
                {this.props.children}{
                    this.props.required && <span className="field-heading__star">{` *`}</span>
                }
            </h1>
        );
    }
}
