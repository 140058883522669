import React from "react";
import moment from "moment";
// import "./style.scss";
import "./profile.scss";

import Input from "../../../Components/Inputs/Input";
import MultiSelect from "../../../Components/Dropdowns/Select/Multi";
import SingleSelect from "../../../Components/Dropdowns/Select/Single";
import Textarea from "../../../Components/Inputs/Textarea";
import Calendar from "../../../Components/Inputs/Calendar";
import Tags from "../../../Components/Dropdowns/Tags";
import PrimaryButton from "../../../Components/Buttons/PrimaryButton";

import paApiHelper from "../../../Utils/personalAreaApi";

import TitleTextBlock from "../../../Components/Layout/TextBlocks/TitleTextBlock";

import { mapArrayToDropdownElements } from "../../../Utils"

// import TableFeedback from "../../../Components/Layout/TableFeedback";
import Notifier from "../../../Components/Layout/Notifier/store";
import VideoUpload from "../../../Components/Layout/VideoUpload";

const sexes = [
    {
        id: 1,
        name: "Мужчина",
        key: "male",
    },
    {
        id: 2,
        name: "Женщина",
        key: "female",
    },
];

interface ProfileProps {
    profile: any;
}

interface UserProfile {
    username?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    sex?: string;
    birthdate?: string;
    locationId?: number;
    tzId?: number;
    statusId?: number;
    workExperienceId?: number;
    userActivitySpheres?: number[];
    userUsersTypes?: number[];
    userTags?: number[];
    activitySpheres?: number[];
    professions?: number[];
    usersTypes?: number[];
    tags?: number[];
    communications?: number[];
    languages?: number[];
    about?: string;
    aboutExperience?: string;
    aboutWorkflow?: string;
}

interface ProfileState {
    loading: boolean;
    structure: string[];

    profile: any;

    activitySpheres: any[];
    communications: any[];
    locations: any[];
    professions: any[];
    profilesStatuses: any[];
    tags: any[];
    usersTypes: any[];
    workExperiences: any[];
    timezones: any[];

    //profile
    userProfile: UserProfile;
}

function mapTags(rawTags: any[]) {
    const tags: any[] = [];

    for (const rawTag of rawTags) {
        if (rawTag.dependsOn === null) {
            let details: any = {};

            try {
                details = JSON.parse(rawTag.details);
            } catch (error) {
                
            }

            const subTags: any[] = [];

            for (const rawSubTag of rawTags) {
                if (rawSubTag.dependsOn && rawSubTag.dependsOn === rawTag.id) {
                    const subTag = {
                        id: rawSubTag.id,
                        title: rawSubTag.name,
                    };

                    subTags.push(subTag);
                }
            }

            const tag = {
                id: rawTag.id,
                title: rawTag.name,
                color: details.color,
                width: "fit-content",
                subTags: subTags,
                isActive: false,
                selectedTag: false,
            };
            tags.push(tag);
        }
    }

    return tags;
}

export default class ProfileView extends React.Component<
    ProfileProps,
    ProfileState
> {
    constructor(props: ProfileProps) {
        super(props);
        this.state = {
            loading: false,
            structure: [],

            profile: this.props.profile,

            activitySpheres: [],
            communications: [],
            locations: [],
            professions: [],
            profilesStatuses: [],
            tags: [],
            usersTypes: [],
            workExperiences: [],
            timezones: [],

            //updated profile
            userProfile: {},
        };
    }

    componentDidUpdate() {}

    setArrayField(key: string, array: any[]) {
        const profile: any = Object.assign({}, this.state.userProfile);
        profile[key] = array;
        this.setState({
            userProfile: profile,
        });
    }

    setOptionField(key: string, value: any) {
        const profile: any = Object.assign({}, this.state.userProfile);
        profile[key] = value;
        this.setState({
            userProfile: profile,
        });
    }

    async getProfileExtra() {
        const result = await paApiHelper.getProfileExtra();

        if (result.success !== false) {
            this.setState({
                activitySpheres: result.activitySpheres,
                communications: result.communications,
                locations: result.locations,
                professions: result.professions,
                profilesStatuses: result.profilesStatuses,
                tags: result.tags,
                usersTypes: result.usersTypes,
                workExperiences: result.workExperiences,
                timezones: result.timezones,
            });
        }
    }

    async getPageStructure() {
        const pageStructure = await paApiHelper.getPageStructure("profile");

        if (pageStructure.success !== false) {
            this.setState({
                structure: pageStructure.elements,
            });
        }
    }

    hasAccessToField(fieldName: string) {
        const structure = this.state.structure;

        return structure.find((e: any) => e.name === fieldName);
    }

    async componentDidMount() {
        this.setState({ loading: true });

        await this.getPageStructure();
        await this.getProfileExtra();

        this.setState({ loading: false });
    }

    async getProfile()
    {
        const updatedProfile = await paApiHelper.getProfile();

        if (updatedProfile.success !== false) {
            this.setState({
                profile: updatedProfile,
                userProfile: {}
            });
        }
        else
        {
            Notifier.error(updatedProfile.message);
        }
    }

    async editProfile() {
        this.setState({
            loading: true,
        });
        const result = await paApiHelper.editProfile(this.state.userProfile);

        if (result.success !== false) {
            const updatedProfile = await paApiHelper.getProfile();

            if (updatedProfile.success !== false) {
                this.setState({
                    profile: updatedProfile,
                    userProfile: {}
                });
                Notifier.success(result.message);
                window.scrollTo(0,0);
            }
            else
            {
                Notifier.error(result.message);
            }
        }
        this.setState({
            loading: false,
        });
    }

    render() {
        const profile = this.state.profile
            ? this.state.profile
            : this.props.profile || null;

        const canRender = this.state.loading !== true && profile !== null;
        return (
            <div>
                {canRender && (
                    <div className="content__wrapper">
                        <TitleTextBlock>
                            {profile.firstName || profile.username},
                            для наиболее эффективной работы и точной
                            <br /> статистики, заполните поля ниже. Заранее
                            спасибо!
                        </TitleTextBlock>
                        <div className="edit-profile">
                            <div className="edit-profile__body">
                                {/* {this.hasAccessToField("fieldUsername") && (
                                    <div className="edit-profile__item">
                                        <div className="edit-profile__label">
                                            Имя пользователя{" "}
                                            <span className="edit-profile__star">
                                                *
                                            </span>
                                        </div>
                                        <div className="edit-profile__item-container">
                                            <Input
                                                defaultValue={profile.username}
                                                onChangeValue={(
                                                    value: string
                                                ) =>
                                                    this.setOptionField(
                                                        "username",
                                                        value
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                )} */}
                                {this.hasAccessToField("fieldBirthdate") && (
                                    <div className="edit-profile__item">
                                        <div className="edit-profile__label">
                                            Дата рождения{" "}
                                            <span className="edit-profile__star">
                                                *
                                            </span>
                                        </div>
                                        <div className="edit-profile__item-container">
                                            <Calendar
                                                date={moment(
                                                    profile.birthdate ||
                                                        moment("2000-01-01")
                                                )}
                                                onChange={(value) =>
                                                    this.setOptionField(
                                                        "birthdate",
                                                        moment(value).format(
                                                            "YYYY-MM-DD"
                                                        )
                                                    )
                                                }
                                            ></Calendar>
                                        </div>
                                    </div>
                                )}
                                {this.hasAccessToField("fieldLastName") && (
                                    <div className="edit-profile__item">
                                        <div className="edit-profile__label">
                                            Фамилия{" "}
                                            <span className="edit-profile__star">
                                                *
                                            </span>
                                        </div>
                                        <div className="edit-profile__item-container">
                                            <Input
                                                defaultValue={profile.lastName}
                                                onChangeValue={(
                                                    value: string
                                                ) =>
                                                    this.setOptionField(
                                                        "lastName",
                                                        value
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                )}
                                {this.hasAccessToField("fieldTimeZone") && (
                                    <div className="edit-profile__item">
                                        <div className="edit-profile__label">
                                            Часовой пояс{" "}
                                            <span className="edit-profile__star">
                                                *
                                            </span>
                                        </div>
                                        <div className="edit-profile__item-container">
                                            <SingleSelect
                                                chosenValue={profile.tzId}
                                                values={mapArrayToDropdownElements(
                                                    this.state.timezones
                                                )}
                                                onChange={(
                                                    chosenValue: number
                                                ) => {
                                                    this.setOptionField(
                                                        "tzId",
                                                        chosenValue
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                                {this.hasAccessToField("fieldFirstName") && (
                                    <div className="edit-profile__item">
                                        <div className="edit-profile__label">
                                            Имя{" "}
                                            <span className="edit-profile__star">
                                                *
                                            </span>
                                        </div>
                                        <div className="edit-profile__item-container">
                                            <Input
                                                defaultValue={profile.firstName}
                                                onChangeValue={(
                                                    value: string
                                                ) =>
                                                    this.setOptionField(
                                                        "firstName",
                                                        value
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                )}
                                {/* {this.hasAccessToField("fieldLocation") && (
                                    <div className="edit-profile__item">
                                        <div className="edit-profile__label">
                                            Страна{" "}
                                            <span className="edit-profile__star">
                                                *
                                            </span>
                                        </div>
                                        <div className="edit-profile__item-container">
                                            <SingleSelect
                                                chosenValue={profile.locationId}
                                                values={mapArrayToDropdownElements(
                                                    this.state.locations
                                                )}
                                                onChange={(
                                                    chosenValue: number
                                                ) => {
                                                    this.setOptionField(
                                                        "locationId",
                                                        chosenValue
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                )} */}
                                {this.hasAccessToField("fieldMiddleName") && ( //TODO: Добавить fieldSex, как появится
                                    <div className="edit-profile__item">
                                        <div className="edit-profile__label">
                                            Пол{" "}
                                            <span className="edit-profile__star">
                                                *
                                            </span>
                                        </div>
                                        <div className="edit-profile__item-container">
                                            <SingleSelect
                                                chosenValue={
                                                    sexes.find(
                                                        (e) =>
                                                            e.key ===
                                                            profile.sex
                                                    )?.id
                                                }
                                                values={mapArrayToDropdownElements(
                                                    sexes
                                                )}
                                                onChange={(
                                                    chosenValue: number
                                                ) => {
                                                    this.setOptionField(
                                                        "sex",
                                                        sexes.find(
                                                            (e) =>
                                                                e.id ===
                                                                chosenValue
                                                        )?.key
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                                {this.hasAccessToField("fieldMiddleName") && (
                                    <div className="edit-profile__item">
                                        <div className="edit-profile__label">
                                            Отчество (если имеется){" "}
                                            <span className="edit-profile__star">
                                                *
                                            </span>
                                        </div>
                                        <div className="edit-profile__item-container">
                                            <Input
                                                defaultValue={
                                                    profile.middleName
                                                }
                                                onChangeValue={(
                                                    value: string
                                                ) =>
                                                    this.setOptionField(
                                                        "middleName",
                                                        value
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                )}
                                {this.hasAccessToField(
                                    "fieldUserActivitySphere"
                                ) && (
                                    <div className="edit-profile__item">
                                        <div className="edit-profile__label">
                                            Направление{" "}
                                            <span className="edit-profile__star">
                                                *
                                            </span>
                                        </div>
                                        <div className="edit-profile__item-container">
                                            <MultiSelect
                                                placeholder="Не выбрано"
                                                values={mapArrayToDropdownElements(
                                                    this.state.activitySpheres
                                                )}
                                                chosenValues={profile.userActivitySpheres}
                                                onChange={(
                                                    chosenValues: number[]
                                                ) => {
                                                    this.setArrayField(
                                                        "userActivitySpheres",
                                                        chosenValues
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                                {this.hasAccessToField(
                                    "fieldUserUsersTypes"
                                ) && (
                                    <div className="edit-profile__item">
                                        <div className="edit-profile__label">
                                            Моя категория{" "}
                                            <span className="edit-profile__star">
                                                *
                                            </span>
                                        </div>
                                        <div className="edit-profile__item-container">
                                            <MultiSelect
                                                placeholder="Не выбрано"
                                                values={mapArrayToDropdownElements(
                                                    this.state.usersTypes
                                                )}
                                                chosenValues={profile.userUsersTypes}
                                                onChange={(
                                                    chosenValues: number[]
                                                ) => {
                                                    this.setArrayField(
                                                        "userUsersTypes",
                                                        chosenValues
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}

                                {this.hasAccessToField("fieldUserTags") && (
                                    <div className="edit-profile__item edit-profile__item-tags">
                                        <div className="edit-profile__label">
                                            Мой запрос{" "}
                                            <span className="edit-profile__star">
                                                *
                                            </span>
                                        </div>
                                        <Tags
                                            chosenValues={profile.userTags}
                                            values={mapTags(this.state.tags)}
                                            onChange={(
                                                chosenValues: number[]
                                            ) => {
                                                this.setArrayField(
                                                    "userTags",
                                                    chosenValues
                                                );
                                            }}
                                        />
                                    </div>
                                )}
                                {this.hasAccessToField("fieldProfile") && (
                                    <div className="edit-profile__item">
                                        <div className="edit-profile__label">
                                            Профиль (выберите не более 3){" "}
                                            <span className="edit-profile__star">
                                                *
                                            </span>
                                        </div>
                                        <div className="edit-profile__item-container">
                                            <MultiSelect
                                                placeholder="Не выбрано"
                                                values={mapArrayToDropdownElements(
                                                    this.state.professions
                                                )}
                                                chosenValues={profile.professions}
                                                onChange={(
                                                    chosenValues: number[]
                                                ) => {
                                                    this.setArrayField(
                                                        "professions",
                                                        chosenValues
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                                {this.hasAccessToField("fieldUsersTypes") && (
                                    <div className="edit-profile__item">
                                        <div className="edit-profile__label">
                                            С какими типами клиентов
                                            вы работаете? (не более 5){" "}
                                            <span className="edit-profile__star">
                                                *
                                            </span>
                                        </div>
                                        <div className="edit-profile__item-container">
                                            <MultiSelect
                                                placeholder="Не выбрано"
                                                values={mapArrayToDropdownElements(
                                                    this.state.usersTypes
                                                )}
                                                chosenValues={profile.usersTypes}
                                                onChange={(
                                                    chosenValues: number[]
                                                ) => {
                                                    this.setArrayField(
                                                        "usersTypes",
                                                        chosenValues
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                                {this.hasAccessToField(
                                    "fieldActivitySpheres"
                                ) && (
                                    <div className="edit-profile__item">
                                        <div className="edit-profile__label">
                                            Специальность (выберите не более 7){" "}
                                            <span className="edit-profile__star">
                                                *
                                            </span>
                                        </div>
                                        <div className="edit-profile__item-container">
                                            <MultiSelect
                                                placeholder="Не выбрано"
                                                values={mapArrayToDropdownElements(
                                                    this.state.activitySpheres
                                                )}
                                                chosenValues={profile.activitySpheres}
                                                onChange={(
                                                    chosenValues: number[]
                                                ) => {
                                                    this.setArrayField(
                                                        "activitySpheres",
                                                        chosenValues
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                                {/* {this.hasAccessToField("fieldLanguages") && (
                                    <div className="edit-profile__item">
                                        <div className="edit-profile__label">
                                            Языки, на которых веду консультации{" "}
                                            <span className="edit-profile__star">
                                                *
                                            </span>
                                        </div>
                                        <div className="edit-profile__item-container">
                                            <SingleSelect
                                                chosenValue={profile.locationId}
                                                values={mapArrayToDropdownElements(
                                                    this.state.locations
                                                )}
                                                onChange={(
                                                    chosenValue: number
                                                ) =>
                                                    console.log(
                                                        `dropdown values selected ${JSON.stringify(
                                                            chosenValue //TODO: После создания локализатора - добавить смену языков
                                                        )}`
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                )} */}
                                {this.hasAccessToField(
                                    "fieldCommunications"
                                ) && (
                                    <div className="edit-profile__item">
                                        <div className="edit-profile__label">
                                            Предпочитаемое средство связи{" "}
                                            <span className="edit-profile__star">
                                                *
                                            </span>
                                        </div>
                                        <div className="edit-profile__item-container">
                                            <>
                                                <MultiSelect
                                                    placeholder="Не выбрано"
                                                    values={mapArrayToDropdownElements(
                                                        this.state
                                                            .communications
                                                    )}
                                                    chosenValues={profile.communications}
                                                    onChange={(
                                                        chosenValues: number[]
                                                    ) => {
                                                        this.setArrayField(
                                                            "communications",
                                                            chosenValues
                                                        );
                                                    }}
                                                />
                                            </>
                                        </div>
                                    </div>
                                )}
                                {this.hasAccessToField(
                                    "fieldWorkExperience"
                                ) && (
                                    <div className="edit-profile__item">
                                        <div className="edit-profile__label">
                                            Опыт работы{" "}
                                            <span className="edit-profile__star">
                                                *
                                            </span>
                                        </div>
                                        <div className="edit-profile__item-container">
                                            <SingleSelect
                                                chosenValue={
                                                    profile.workExperienceId
                                                }
                                                values={mapArrayToDropdownElements(
                                                    this.state.workExperiences
                                                )}
                                                onChange={(
                                                    chosenValue: number
                                                ) => {
                                                    this.setOptionField(
                                                        "workExperienceId",
                                                        chosenValue
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                                {this.hasAccessToField(
                                    "fieldProfileStatus"
                                ) && (
                                    <div className="edit-profile__item">
                                        <div className="edit-profile__label">
                                            Статус работы (показывать Вас в каталоге?)
                                        </div>
                                        <div className="edit-profile__item-container">
                                            <SingleSelect
                                                chosenValue={
                                                    profile.statusId
                                                }
                                                values={mapArrayToDropdownElements(
                                                    this.state.profilesStatuses
                                                )}
                                                onChange={(
                                                    chosenValue: number
                                                ) => {
                                                    this.setOptionField(
                                                        "statusId",
                                                        chosenValue
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                                {this.hasAccessToField("fieldAbout") && (
                                    <div className="edit-profile__item edit-profile__item-textarea">
                                        <div className="edit-profile__label">
                                            Расскажите кратко о себе, своём
                                            профессиональном опыте и кейсах.
                                            Данная информация будет опубликована
                                            на странице каталога платформы.{" "}
                                            <span className="edit-profile__star">
                                                *
                                            </span>
                                        </div>
                                        <Textarea
                                            defaultValue={profile.about}
                                            onChangeValue={(value: string) =>
                                                this.setOptionField(
                                                    "about",
                                                    value
                                                )
                                            }
                                        />
                                    </div>
                                )}
                                {/* {this.hasAccessToField(
                                    "fieldAboutExperience"
                                ) && (
                                    <div className="edit-profile__item edit-profile__item-textarea">
                                        <div className="edit-profile__label">
                                            Напишите подробную информацию о себе
                                            и своём опыте. Данная иформация
                                            будет опубликована в публичном
                                            профиле.
                                        </div>
                                        <Textarea
                                            defaultValue={
                                                profile.aboutExperience
                                            }
                                            onChangeValue={(value: string) =>
                                                this.setOptionField(
                                                    "aboutExperience",
                                                    value
                                                )
                                            }
                                        />
                                    </div>
                                )} */}
                                {this.hasAccessToField("fieldTags") && (
                                    <div className="edit-profile__item edit-profile__item-tags">
                                        <div className="edit-profile__label">
                                            Тэги{" "}
                                            <span className="edit-profile__star">
                                                *
                                            </span>
                                        </div>
                                        <Tags
                                            chosenValues={profile.tags}
                                            values={mapTags(this.state.tags)}
                                            onChange={(
                                                chosenValues: number[]
                                            ) => {
                                                this.setArrayField(
                                                    "tags",
                                                    chosenValues
                                                );
                                            }}
                                        />
                                    </div>
                                )}
                                {this.hasAccessToField("fieldTags") && (
                                    <div className="edit-profile__item">
                                        <div className="edit-profile__label">Видео-презентация</div>
                                        <VideoUpload onUploadEnd={() => this.getProfile()} video={profile.video}/>
                                    </div>
                                )}
                                <div className="edit-profile__item edit-profile__item-18"></div>
                            </div>
                        </div>
                        <div
                            style={{
                                width: "250px",
                                height: "50px",
                            }}
                        >
                            <PrimaryButton
                                disabled={
                                    !(
                                        Object.keys(this.state.userProfile)
                                            .length > 0
                                    )
                                }
                                loading={this.state.loading}
                                text={"Сохранить изменения"}
                                onClick={() => this.editProfile()}
                            />
                        </div>
                        {/* <TableFeedback /> */}
                    </div>
                )}
            </div>
        );
    }
}
