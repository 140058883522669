import React from "react";
// import './style.scss';
import "./notifications.scss";
import api from "../../../Utils/personalAreaApi";
import SimpleLoader from "../../../Components/Layout/Loaders/Resizeable";
import TableWrapper, {
    HeaderGreenProvider,
} from "../../../Components/Layout/TableWrapper";
import Checkbox from "../../../Components/Inputs/Checkbox";
import Notifier from "../../../Components/Layout/Notifier/store";
import Panel from "../../../Components/Layout/Common/Panel";
import Input from "../../../Components/Inputs/Input";
import PrimaryButton from "../../../Components/Buttons/PrimaryButton";

interface NotificationsState {
    loading: boolean;
    newTelegramBinding: any;
    telegramBinding: any;
    unit: any;
    types: any;
}

const headerData = {
    name: "Тип уведомлений",
    email: "По почте",
    telegram: "В Telegram",
    // sms: "Уведомления по смс",
    // web: "Уведомления в браузере",
};

const processData = (data: any, extra: any) => {
    const types = extra.types;

    const controls = extra.controls;

    const newData = data.map((element: any) => {
        return {
            name: (
                <div>
                    {
                        types.find((t: any) => t.id === element.typeId)
                            .description
                    }
                </div>
            ),
            email: (
                <div>
                    <Checkbox
                        onChange={(checked) =>
                            controls.handler(element.typeId, "onEmail", checked)
                        }
                        checked={element.onEmail}
                    />
                </div>
            ),
            telegram: (
                <div>
                    <Checkbox
                        onChange={(checked) =>
                            controls.handler(
                                element.typeId,
                                "onTelegram",
                                checked
                            )
                        }
                        checked={element.onTelegram}
                    />
                </div>
            ),
            // sms: <div><Checkbox checked={element.onMobile}/></div>,
            // web: <div><Checkbox checked={element.onWeb}/></div>,
        };
    });

    return newData;
};

export default class NotificationsView extends React.Component<
    {},
    NotificationsState
> {
    constructor(props: any) {
        super(props);

        this.state = {
            loading: false,
            newTelegramBinding: null,
            telegramBinding: null,
            unit: [],
            types: [],
        };
    }

    async getExtra() {
        const getExtraResult = await api.getNotificationsExtra();

        if (getExtraResult.success !== false) {
            this.setState({
                types: getExtraResult.notificationsTypes,
            });
        }
    }

    async getUnit() {
        const getUnitResult = await api.getNotificationsUnit();

        if (getUnitResult.success !== false) {
            this.setState({
                telegramBinding: getUnitResult.telegramBinding,
                unit: getUnitResult.unit,
            });
        }
    }

    async componentDidMount() {
        this.setState({ loading: true });

        await this.getExtra();
        await this.getUnit();

        this.setState({ loading: false });
    }

    async editNotificationsUnit(typeId: number, where: string, state: boolean) {
        const body = {
            typeId: typeId,
            [where]: state,
        };

        const editUnitResult = await api.editNotificationsUnit(body);

        if (editUnitResult.success !== false) {
            Notifier.success(`Уведомления ${state ? "включены" : "выключены"}`);
        } else {
            Notifier.error(editUnitResult.message);
        }
    }

    async assignTelegram() {
        const body = {
            telegramBinding: this.state.newTelegramBinding === "" ? null : this.state.newTelegramBinding,
        }

        const editUnitResult = await api.editNotificationsUnit(body);

        if (editUnitResult.success !== false) {
            Notifier.success(`Телеграм успешно ${this.state.newTelegramBinding ? "привязан" : "отвязан"}`);
        } else {
            Notifier.error(editUnitResult.message);
        }
    }

    render() {
        return (
            <div>
                {this.state.loading ? (
                    <div style={{ width: "100%", height: "500px" }}>
                        <SimpleLoader></SimpleLoader>
                    </div>
                ) : (
                    <div style={{ marginTop: "30px" }}>
                        <Panel>
                            <div className="telegram-assing-panel">
                                <span className="telegram-assing-header">Телеграм-нотификации</span>
                                {
                                    !this.state.telegramBinding && 
                                    <div className="telegram-assing-text">Попроси у <a target="_blank" className="telegram-assing-link" href={process.env.REACT_APP_TELEGRAM_NOTIFICATIONS_BOT_URL}>бота</a> идентификатор привязки, вставь его сюда и нажми "привязать"</div>
                                }
                                <div>
                                    <Input
                                        placeholder="ID привязки"
                                        defaultValue={this.state.telegramBinding || undefined}
                                        onChangeValue={(value) => this.setState({newTelegramBinding: value})}
                                    />
                                </div>
                                <div
                                    style={{
                                        width: "100%",
                                        height: "25px",
                                        marginTop: "4px",
                                    }}
                                >
                                    <PrimaryButton
                                        disabled={!this.state.telegramBinding ? ((!this.state.newTelegramBinding || (this.state.newTelegramBinding === ""))) : false}
                                        text={this.state.telegramBinding ? "Отвязать" : "Привязать"}
                                        onClick={() => this.assignTelegram()}
                                    />
                                </div>
                            </div>
                        </Panel>
                        <HeaderGreenProvider value={true}>
                            <TableWrapper
                                headerData={headerData}
                                bodyData={processData(this.state.unit, {
                                    types: this.state.types,
                                    controls: {
                                        handler: (
                                            typeId: number,
                                            where: string,
                                            state: boolean
                                        ) =>
                                            this.editNotificationsUnit(
                                                typeId,
                                                where,
                                                state
                                            ),
                                    },
                                })}
                            />
                        </HeaderGreenProvider>
                    </div>
                )}
            </div>
        );
    }
}
