import React from 'react';
import './style.scss';

interface RadioButtonProps {
    disabled?: boolean,
    checked?: boolean,
    label?: string,
    name: string;
    id: string;
    onChange?: (id: string, checked: boolean) => void;
}

class RadioButton extends React.Component<RadioButtonProps> {


    render() {
        const checked = this.props.checked === undefined ? false : this.props.checked;
        const onChangeHandler = this.props.onChange || ((id: string, checked: boolean) => { })
        return (
            <div className='radio'>
                <div className='radio-wrap'>
                    <input
                        type="radio"
                        disabled={this.props.disabled || false}
                        defaultChecked={checked}
                        name={this.props.name}
                        id={this.props.id}
                        onChange={(e) => onChangeHandler(this.props.id, e.target.checked)}
                    />
                </div>
                <span className='radio-label'>{this.props.label || ""}</span>
            </div>
        );
    }
}

export default RadioButton;





