import React from "react";

import "./style.scss";

import TableWrapper, {
    HeaderGreenProvider,
} from "../../Components/Layout/TableWrapper";

import TitleTextBlock from "../../Components/Layout/TextBlocks/TitleTextBlock";

import SingleSelect from "../../Components/Dropdowns/Select/Single";
import paApiHelper from "../../Utils/personalAreaApi";

import Notifier from "../../Components/Layout/Notifier/store";

import SimpleLoader from "../../Components/Layout/Loaders/Resizeable";

import { mapArrayToDropdownElements } from "../../Utils";
import MainView from "../Common/MainView";

const usersHeader = {
    id: "ID",
    username: "Имя",
    fml: "ФИО",
    email: "Email",
    status: "Статус",
    // data: "Данные", // TODO: ДОБАВИТЬ, КОГДА ПОЯВИТСЯ
    // actions: "Действия",
};

const processData = (
    data: any,
    accessStatuses: any[],
    onStatusChange: (userId: number, statusBody: any) => void
) => {
    const newData = data.map((element: any) => {
        return {
            id: element.id,
            username: element.username,
            fml: (
                <div>
                    {element.lastName} {element.firstName} <br />{" "}
                    {element.middleName}
                </div>
            ),
            email: element.email,
            status: (
                <div
                    style={{
                        height: "35px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <SingleSelect
                        onChange={(statusId) =>
                            onStatusChange(element.id, { status: statusId })
                        }
                        chosenValue={element.status}
                        values={mapArrayToDropdownElements(accessStatuses)}
                        placeholder={"Статус"}
                    />
                </div>
            ),
            // data: <div style={{width: "auto", height: "35px"}}><PrimaryButton text="Профиль"/></div>, // TODO: ДОБАВИТЬ, КОГДА ПОЯВИТСЯ
            // actions: <div style={{width: "auto", height: "35px"}}><PrimaryButton text="Смотреть"/></div>,
        };
    });

    return newData;
};

interface UsersViewState {
    users: any[];
    usersLoading: boolean;
    totalUsers: number;
    accessStatuses: any[];
}

const maxRows = 10;

export default class Users extends React.Component<{}, UsersViewState> {
    constructor(props: any) {
        super(props);

        this.state = {
            totalUsers: 0,
            usersLoading: true,
            users: [],
            accessStatuses: [],
        };
    }

    async getExtra() {
        const profileExtraResult = await paApiHelper.getProfileExtra([
            "accessStatuses",
        ]);
        const rolesResult = await paApiHelper.getPartnerRoles();

        const res = [profileExtraResult.success, rolesResult.success];

        if (res.some((r) => r === false)) {
            Notifier.error("Ошибка отображения страницы");
        } else {
            this.setState({
                accessStatuses: profileExtraResult.accessStatuses,
            });
        }
    }

    async getUsers(offset: number) {
        const result = await paApiHelper.getUsers(maxRows, offset);
        if (result.success !== false) {
            this.setState({
                users: result.users,
                totalUsers: result.total,
            });
        }
    }

    async componentDidMount() {
        await this.getExtra();

        this.setState({
            usersLoading: true,
        });

        await this.getUsers(0);

        this.setState({
            usersLoading: false,
        });
    }

    async updateStatus(userId: number, statusBody: any) {
        const result = await paApiHelper.editEmployeeStatus(userId, statusBody);

        if (result.success) {
            Notifier.success(result.message);
        } else {
            Notifier.error(result.message);
        }
    }

    render() {
        return (
            <MainView>
                <TitleTextBlock>
                    Здесь находятся все ваши сотрудники на платформе.
                    <br />
                    Вы можете найти нужного сотрудника, посмотреть
                    <br />
                    его профиль и действия на платформе.
                </TitleTextBlock>
                {this.state.usersLoading ? (
                    <div style={{ width: "100%", height: "500px" }}>
                        <SimpleLoader></SimpleLoader>
                    </div>
                ) : (
                    <HeaderGreenProvider value={true}>
                        <TableWrapper
                            total={this.state.totalUsers}
                            rowsCount={10}
                            headerData={usersHeader}
                            bodyData={processData(
                                this.state.users,
                                this.state.accessStatuses,
                                this.updateStatus
                            )}
                            onChangePage={(offset: number) =>
                                this.getUsers(offset)
                            }
                        />
                    </HeaderGreenProvider>
                )}
            </MainView>
        );
    }
}
