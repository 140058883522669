import React from "react";

import "./style.scss";
// import '../style.scss';

import MainView from "../Common/MainView";
import Breadcrumbs from "../../Components/Layout/Breadcrumbs";
import BreadcrumbsIndependentBlock from "../../Components/Layout/Breadcrumbs/IndependentBlock/BCIndependentBlock.tsx";
// import AddRemoveButton from "../../Components/Buttons/AddRemoveButton";

import TableWrapper from "../../Components/Layout/TableWrapper";
import SimpleLoader from "../../Components/Layout/Loaders/Resizeable";

import api from "../../Utils/personalAreaApi";

// import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import Notifier from "../../Components/Layout/Notifier/store";

import Toggle from "../../Components/Inputs/Toggle";

const servicesTableHeadData = { 
    id: "ID",
    service: "Наименование услуги",  
    description: "Описание",
    price: "Стоимость",
    discount: "Скидка",
    addAction: "Добавить", 
};



interface MyServicesPageState {
    services: any[];
    myServices: any[];
    loading: boolean;
    tags: any[];
    popupOpened: boolean;
}

export default class MyServicesPage extends React.Component<{}, MyServicesPageState> {
    
    constructor(props: any)
    {
        super(props);

        this.state = {
            services: [],
            myServices: [],
            loading: true,
            tags: [],
            popupOpened: false,
        }
    }

    async assignService(serviceId: number, assign: boolean)
    {
        const assignResult = await api.assignService(serviceId, assign);

        const message = assignResult.message;
        if(assignResult.success)
        {
            if(assign)
            {
                Notifier.success("Услуга добавлена в список!");
            }
            else
            {
                Notifier.success("Услуга удалена из списка!");
            }
        }
        else
        {
            Notifier.error(message);
        }
        await this.getServices();
    }

    formatMyServicesTableData (data: any) {

        const newData = data.map((element:any) => {
    
            return {
                id: element.id,
                service: element.name,
                description: element.description,
                price: `${element.price} Coins`,
                discount: `${element.discountSize} ${element.discountUnitId === 1 ? "%" : "Coins"}`
            };
        });
    
        return newData;
    
    };

    serviceAssigned(serviceId: number)
    {
        return this.state.myServices.map(s => s.id).includes(serviceId);
    }

    formatServicesTableData (data: any) {

        const newData = data.map((element:any) => {
    
            return {
                id: element.id,
                service: element.name,
                description: element.description,
                price: `${element.price} Coins`,
                discount: `${element.discountSize} ${element.discountUnitId === 1 ? "%" : "Coins"}`,
                addAction: <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <Toggle onChange={(checked) => this.assignService(element.id, checked)} checked={this.serviceAssigned(element.id)}/>
                </div>,
            };
        });
    
        return newData;
    
    };

    async getServices()
    {
        const getServicesResult = await api.getServices();
        const getMyServicesResult = await api.getProfileServices();

        const extra = await api.getProfileExtra(["tags"]);

        let services: any[] = [];
        let myServices: any[] = [];

        if(getServicesResult.success !== false)
        {
            services = getServicesResult.services;
        }

        if(getMyServicesResult.success !== false)
        {
            myServices = getMyServicesResult.services;
        }

        const tags: any[] = extra.tags;

        this.setState({
            loading: false,
            services: services,
            myServices: myServices,
            tags: tags,
        })
    }

    async componentDidMount() {
        await this.getServices();
    }

    async createService(service: any) {
        const createResult = await api.createService(service);

        const message = createResult.message;
        if(createResult.success)
        {
            Notifier.success(message);
        }
        else
        {
            Notifier.error(message);
        }
        await this.getServices();
    }

    openPopup(open: boolean = true)
    {
        this.setState(
            {
                popupOpened: open,
            }
        )
    }
    
    render() {
        return (
            <MainView>
                <Breadcrumbs breadcrumbsButtons={[]}>
                    <BreadcrumbsIndependentBlock>
                        <div>
                            Выбери одну или несколько услуг из списка, которые
                            <br /> хотите предоставлять пользователям. 
                            {/* Вы можете
                            настроить
                            <br /> их длительность и максимальное количество
                            <br /> участников для групповой услуги. */}
                        </div>
                    </BreadcrumbsIndependentBlock>
                </Breadcrumbs>
                {
                    this.state.loading ?
                    <div style={{width: "100%", height: "500px"}}>
                        <SimpleLoader></SimpleLoader>
                    </div>
                    :
                    <>
                        {
                            this.state.myServices.length > 0 ? 
                            <>
                                <h2 className="my-service__title selected-services">
                                    Выбранные услуги: {this.state.myServices.length}
                                </h2>
                                {
                                    this.state.myServices.map(s => {
                                        return <TableWrapper bodyData={this.formatMyServicesTableData([s])}/>
                                    })
                                }
                            </>
                            :
                            <div className="my-service__label">
                                Выберите услуги из списка ниже
                            </div>
                        }
                        <h2 className="my-service__title all-services">Все услуги</h2>
                        <TableWrapper headerData={servicesTableHeadData} bodyData={this.formatServicesTableData(this.state.services)}/>
                    </>
                }
            </MainView>
        );
    }
}