import React /*, { Component }*/ from "react";
import "./style.scss";

import MainView from "../Common/MainView";

import Breadcrumbs from "../../Components/Layout/Breadcrumbs";
import BreadcrumbsIndependentBlock from "../../Components/Layout/Breadcrumbs/IndependentBlock/BCIndependentBlock.tsx";
import { BreadcrumbsItem } from "../../Components/Layout/Breadcrumbs/types";


import paApiHelper from "../../Utils/personalAreaApi";

import MyOperationsSection from "./Sections/my";

const breadcrumbsButtons: BreadcrumbsItem[] = [
    {
        id: 38,
        name: "Мои операции",
        path: "/operations/?section=my",
    },
    {
        id: 39,
        name: "Партнерские операции",
        path: "/operations/?section=partner",
    },
];

interface HistoryPageState {
    structure: any;
}

class HistoryPage extends React.Component<{}, HistoryPageState> {

    constructor(props: any) {
        super(props);

        this.state = {
            structure: [],
        };
    }

    async getPageStructure() {
        const pageStructure = await paApiHelper.getPageStructure("operations");

        if (pageStructure.success !== false) {
            this.setState({
                structure: pageStructure.elements,
            });
        }
    }


    async componentDidMount() {
        await this.getPageStructure();
    }

    render() {

        let availableBreadcrumbsButtons: any = [];

        const queryParams = new URLSearchParams(window.location.search);
        let section = "my-requests";

        const querySectionParam = queryParams.get("section");
        switch (querySectionParam) {
            case "my":
                section = "my";
                break;
            case "partner":
                section = "partner";
                break;
        }

        if(this.state.structure)
        {
            availableBreadcrumbsButtons = breadcrumbsButtons.filter(bb => this.state.structure.map((e: any) => e.id).includes(bb.id))
        }

        return (
        <MainView>
            <Breadcrumbs
                breadcrumbsButtons={availableBreadcrumbsButtons}
            >
            </Breadcrumbs>
            <MyOperationsSection type={section}/>
        </MainView>
        );
    }
}

export default HistoryPage;
