import React from 'react';
import './style.scss';

interface Secondary1ButtonProps {
    text?: string;
    onClick?: () => void;
}

class Secondary2Button extends React.Component<Secondary1ButtonProps> {

    render() {
        const onClickHandler = this.props.onClick || (() => { });
        const text = this.props.text || "";
        return (
            <button className="secondary_1__button" onClick={onClickHandler}>{text}</button>
        );
    }
}

export default Secondary2Button;

