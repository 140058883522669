import React, { Component } from "react";
import "./style.scss";

import PopupWrapper, { PopupWrapperProps } from "../PopupWrapper";

import ApproveIcon from "../../../../Media/Icons/ico-approve.svg";
import { getFullName, standartTimeFormat } from "../../../../Utils";
import moment from "moment";
import PrimaryButton from "../../../Buttons/PrimaryButton";
import Textarea from "../../../Inputs/Textarea";

interface ApprovePopupProps extends PopupWrapperProps {
    serviceRequest: any;
    onApprove: Function;
}

interface ApprovePopupState {
    comment: string | null;
}

export default class ApprovePopup extends Component<ApprovePopupProps, ApprovePopupState> {

    constructor(props: ApprovePopupProps)
    {
        super(props);

        this.state = {
            comment: null,
        }
    }

    setComment(value: string) {
        let comment: any = value;
        if(value.length === 0)
        {
            comment = null;
        }

        this.setState({
            comment: comment,
        })
    }

    render() {

        const request = this.props.serviceRequest;
        let serviceName = request.service.name;
        let username = getFullName(request.profile);
        let datetime = standartTimeFormat(moment(request.serviceDate * 1000));

        return (
            <PopupWrapper
                active={this.props.active || false}
                onClose={() => this.props.onClose ? this.props.onClose() : () => {}}
            >
                <div className="popup-title-wrapper">
                    <div>Вы подтверждаете заявку</div>
                    <img alt="+" src={ApproveIcon} />
                </div>
                <div className="sr-popup-grid">
                    {/* Left */}
                    <div className="sr-popup-grid-column-left">
                        <div className="sr-popup-grid-item">Услуга:</div>
                        <div className="sr-popup-grid-item">Пользователь:</div>
                        <div className="sr-popup-grid-item">Дата и время:</div>
                    </div>
                    {/* Right */}
                    <div className="sr-popup-grid-column-right">
                        <div className="sr-popup-grid-item">{serviceName}</div>
                        <div className="sr-popup-grid-item">{username}</div>
                        <div className="sr-popup-grid-item">{datetime}</div>
                    </div>
                </div>
                <div className="sr-popup-text-area">
                    <Textarea onChangeValue={(value) => this.setComment(value)} placeholder="Добавить сообщение (к примеру, Вы можете указать, как с Вами связаться в случае подтверждения заявки)" />
                </div>
                <div className="sr-popup-buttons">
                    <div className="sr-popup-button">
                        <PrimaryButton onClick={() => this.props.onApprove({comment: this.state.comment})} text="Подтвердить заявку"></PrimaryButton>
                    </div>
                </div>
            </PopupWrapper>
        );
    }
}
