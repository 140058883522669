import React from "react";

import "./style.scss";
import MainView from "../Common/MainView";

import Breadcrumbs from "../../Components/Layout/Breadcrumbs";
import BreadcrumbsIndependentBlock from "../../Components/Layout/Breadcrumbs/IndependentBlock/BCIndependentBlock.tsx";

function AnalyticsPage() {
    return (
        <MainView>
            <Breadcrumbs>
                <BreadcrumbsIndependentBlock>
                    <span>
                        Выбери нужное направление аналитики для просмотра
                        <br /> виджетов, с соответствующими показателями
                        за период.
                    </span>
                </BreadcrumbsIndependentBlock>
            </Breadcrumbs>
            <div className="analitics__wrapper">
                <div className="analitics__item analitics__item-services">
                    <span>Аналитика услуг</span>
                </div>
                <div className="analitics__item analitics__item-clients">
                    Аналитика клиентов
                </div>
                <div className="analitics__item analitics__item-feedbacks">
                    Аналитика отзывов
                </div>
            </div>
        </MainView>
    );
}

export default AnalyticsPage;
