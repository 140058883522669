import React from 'react';
import './style.scss';
import "../common.scss";
import Badge from '../../../../Media/Common/triangle-green.svg';
import Cross from '../../../../Media/Common/cross.svg';
import { outsideClickHandler } from "../../../../Utils";

import { DropdownItem } from "../types";

interface MultiSelectProps {
    chosenValues?: number[]; 
    disabled?: boolean
    values: DropdownItem[],
    placeholder: string,
    onChange?: (chosenValues: number[]) => void;
}

interface MultiSelectState {
    chosenValues: number[],
    dropdownOpened: boolean;
}

class MultiSelect extends React.Component<MultiSelectProps, MultiSelectState> {

    componentDidUpdate(prevProps: MultiSelectProps, prevState: MultiSelectState) {
        if(JSON.stringify(prevState.chosenValues.sort()) !== JSON.stringify(this.state.chosenValues.sort()))
        {
            if (this.props.onChange) {
                this.props.onChange(this.state.chosenValues)
            }
        }
    }

    refControls: any = null;

    mockElement: DropdownItem = {
        id: 0,
        label: "Не выбрано",
        data: {}
    }
    node: any;

    constructor(props: any) {
        super(props);
        const chosenValues = this.props.chosenValues ? this.props.chosenValues : [];
        this.state = {
            chosenValues: this.props.values ? this.props.values.filter(e => chosenValues.includes(e.id)).map(e => e.id) : [],
            dropdownOpened: false,
        };
        this.refControls = React.createRef();
        this.mockElement.label = this.props.placeholder;
    }

    componentDidMount() {
        const s = this.state;

        outsideClickHandler({
            bindObj: this,
            body: document,
            ref: this.refControls,
            action: this.hideAllDds,
            type: "click",
            condition: !(s.dropdownOpened)
        })

        const chosenValues = this.props.chosenValues ? this.props.chosenValues : [];
        this.setState({
            chosenValues: this.props.values ? this.props.values.filter(e => chosenValues.includes(e.id)).map(e => e.id) : [],
        })
    }

    hideAllDds() {
        this.setState(
            {
                dropdownOpened: false,
            }
        )
    }

    openCloseDropdown(openClose?: boolean) {
        const dropdownOpened = this.state.dropdownOpened;
        if (!this.props.disabled) {
            this.setState({
                dropdownOpened: openClose !== undefined ? openClose : !dropdownOpened,
            })
        }
    }

    elementChosen(element: any) {
        const chosenValues = this.state.chosenValues;

        if (chosenValues.includes(element)) {
            return true;
        }
        return false;
    }

    getChosenElements() {
        const chosen = [...this.state.chosenValues];
        let elements = chosen;
        let count = 0;

        if (chosen.length > 2) {
            elements = [chosen[0], chosen[1]];
            count = chosen.length - 2;
        }

        return {
            elements,
            count
        };
    }

    clearElement(element: any) {
        const chosen = [...this.state.chosenValues];

        this.setState({
            chosenValues: chosen.filter(v => v !== element)
        })
    }

    clearAllElements() {
        this.setState({ chosenValues: [] })
    }

    chooseElement(element: DropdownItem) {
        if (element === this.mockElement) {
            this.clearAllElements();
            return;
        }
        const chosen = [...this.state.chosenValues];

        if (!chosen.includes(element.id)) {
            chosen.push(element.id);
            this.setState({ chosenValues: chosen });
        }
        else
        {
            this.setState({
                chosenValues: chosen.filter(v => v !== element.id)
            })
        }
    }

    getElementName(element: DropdownItem) {
        // const limit = 13;
        let name = element.label;

        // if (name.length > limit) {
        //     name = name.slice(0, limit) + "...";
        // }
        return name;
    }

    render() {
        const s = this.state;
        const values = this.props.values;
        const disabled = this.props.disabled || false;
        const dropdownOpened = this.state.dropdownOpened;
        const { elements, count } = this.getChosenElements();
        return (
            <div ref={this.refControls} className="dropdown-multi">
                <div className={`dropdown-header ${s.dropdownOpened ? "opened" : ""}`}>
                    <div className='dropdown-multi-header-chosen-elements-area'>
                        {
                            elements.length > 0 ?
                                elements.map((element: any, i: number) =>
                                    <div className={`dropdown-multi-header-element`} key={i}>
                                        <div className='dropdown-multi-header-element-content'>
                                            <span className='dropdown-multi-option'>{this.getElementName(values.find(e => e.id === element) || this.mockElement)}</span>
                                            <img alt="" className='dropdown-multi-header-element-button' src={Cross} onClick={() => this.clearElement(element)}></img>
                                        </div>
                                    </div>
                                )
                                :
                                <div className={`dropdown-multi-header-element placeholder`} key={0}>
                                    <div className='dropdown-multi-header-element-content'>
                                        <span className='dropdown-multi-default-option'>{this.mockElement.label}</span>
                                    </div>
                                </div>
                        }
                    </div>
                    <div className='dropdown-multi-header-count-area'>
                        {
                            count > 0 &&
                            <div className='dropdown-multi-badge-counter'>+{count}</div>
                        }
                    </div>
                    <div className='dropdown-multi-header-clear-area' onClick={() => this.clearAllElements()}>
                        {
                            elements.length > 0 &&
                            <img alt="" className='dropdown-multi-badge clear' src={Cross}></img>
                        }
                    </div>
                    <div className='dropdown-multi-header-separator'/>
                    <div className='dropdown-multi-header-badge-area' onClick={() => this.openCloseDropdown()} >
                        <img alt="" className={`dropdown-multi-badge ${dropdownOpened ? "opened" : ""}`} src={Badge}></img>
                    </div>
                </div>

                {
                    !disabled && dropdownOpened &&
                    <div className='dropdown-list-wrapper'>
                        <div className='dropdown-list'>
                            {values.map((element: any, i: number) =>
                                <div className={`dropdown-list-element ${this.elementChosen(element.id) ? "selected" : ""}`} key={i}>
                                    <div onClick={() => this.chooseElement(element)} key={i} >
                                        <span className='option-select'>{element.label}</span>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                }

            </div>
        )
    }
}

export default MultiSelect;
