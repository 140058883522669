import React from 'react';
import './style.scss';

interface CheckboxProps {
    disabled?: boolean;
    checked?: boolean;
    onChange?: (checked: boolean) => void;
}

class Checkbox extends React.Component<CheckboxProps> {

    render() {
        const onClickHandler = this.props.onChange || (() => {});
        return (
            <div className='checkbox'>
                <label className='checkbox-container'>
                    <input type="checkbox" disabled={this.props.disabled} defaultChecked={this.props.checked} onChange={(e) => {onClickHandler(e.target.checked)}}/>
                    <span className="checkbox-checkmark"></span>
                </label>
            </div>
        );
    }
}

export default Checkbox;
