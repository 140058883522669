import React from "react";
import "./style.scss";
import Secondary2Button from "../../Buttons/Secondary2Button";
import { BreadcrumbsItem } from "./types";

interface BreadcrumbsProps {
    onTogglePopup?: any;
    breadcrumbsButtons?: BreadcrumbsItem[];
}

export default class Breadcrumbs extends React.Component<BreadcrumbsProps> {
    render() {

        const breadcrumbsButtons = this.props.breadcrumbsButtons || [];

        const gradient = this.props.children && (breadcrumbsButtons && breadcrumbsButtons.length > 0);

        return (
            <div className={`breadcrumbs ${gradient ? "gradient" : ""}`}>
                {
                    this.props.children
                }
                <div className={`breadcrumbs__items ${gradient ? "gradient" : ""}`}>
                    {breadcrumbsButtons.map((element) => (
                        <a
                            className="breadcrumbs__item"
                            key={element.id}
                            href={element.path}
                        >
                            <div
                                style={{ width: "fit-content", height: "50px" }}
                            >
                                <Secondary2Button
                                    text={element.name}
                                    badge={element.icon}
                                />
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        );
    }
}
