import React from "react";

import "./employees.scss";

import TableWrapper, {
    HeaderGreenProvider,
} from "../../../Components/Layout/TableWrapper";

import TitleTextBlock from "../../../Components/Layout/TextBlocks/TitleTextBlock";
import PrimaryButton from "../../../Components/Buttons/PrimaryButton";
import Secondary1Button from "../../../Components/Buttons/Secondary1Button";
import { FieldHeading } from "../../../Components/Layout/TextBlocks/Headings";
import Input from "../../../Components/Inputs/Input";

import SingleSelect from "../../../Components/Dropdowns/Select/Single";
import MultiSelect from "../../../Components/Dropdowns/Select/Multi";
import paApiHelper from "../../../Utils/personalAreaApi";

import Notifier from "../../../Components/Layout/Notifier/store";

import SimpleLoader from "../../../Components/Layout/Loaders/Resizeable";

import { mapArrayToDropdownElements } from "../../../Utils";

const employeesHeader = {
    id: "ID",
    username: "Имя",
    fml: "ФИО",
    email: "Email",
    role: "Роли",
    status: "Статус",
    // data: "Данные", // TODO: ДОБАВИТЬ, КОГДА ПОЯВИТСЯ 
    // actions: "Действия",
};


const processData = (data: any, roles: any[], accessStatuses: any[], onRolesChange: (userId: number, rolesIds: number[]) => void, onStatusChange: (userId: number, statusBody: any) => void) => {
    const newData = data.map((element: any) => {
        return {
            id: element.id,
            username: element.username,
            fml: <div>{element.lastName} {element.firstName} <br/> {element.middleName}</div>,
            email: element.email,
            roles: <div style={{height: "35px", display: "flex", alignItems: "center", justifyContent: "center"}}><MultiSelect onChange={(rolesIds) => onRolesChange(element.id, rolesIds)} chosenValues={element.roles.map((r: any) => r.id)} values={mapArrayToDropdownElements(roles)} placeholder={"Нет ролей"}/></div>,
            status: <div style={{height: "35px", display: "flex", alignItems: "center", justifyContent: "center"}}><SingleSelect onChange={(statusId) => onStatusChange(element.id, {status: statusId})} chosenValue={element.status} values={mapArrayToDropdownElements(accessStatuses)} placeholder={"Статус"}/></div>,
            // data: <div style={{width: "auto", height: "35px"}}><PrimaryButton text="Профиль"/></div>, // TODO: ДОБАВИТЬ, КОГДА ПОЯВИТСЯ 
            // actions: <div style={{width: "auto", height: "35px"}}><PrimaryButton text="Смотреть"/></div>,
        };
    });

    return newData;
};

interface EmployeesSectionState {
    addScreenOpened: boolean;
    employees: any[];
    employeesLoading: boolean;
    totalEmployees: number;
    employee: any,
    locations: any[];
    accessStatuses: any[];
    roles: any[];
}

const maxRows = 10;

const employeeRequiredFields = [
    "username", "region", "email", "password", "repeatPassword"
];

export default class EmployeesSection extends React.Component<{}, EmployeesSectionState> {

    constructor(props: any)
    {
        super(props);

        this.state = {
            addScreenOpened: false,
            totalEmployees: 0,
            employeesLoading: true,
            employees: [],
            employee: {},
            locations: [],
            accessStatuses: [],
            roles: [],
        }
    }

    async getExtra() {
        const profileExtraResult = await paApiHelper.getProfileExtra(["locations","accessStatuses"]);
        const rolesResult = await paApiHelper.getPartnerRoles();

        const res = [
            profileExtraResult.success,
            rolesResult.success,
        ]

        if (res.some(r => r === false)) {
            Notifier.error("Ошибка отображения страницы");
        }
        else
        {
            this.setState({
                locations: profileExtraResult.locations,
                accessStatuses: profileExtraResult.accessStatuses,
                roles: rolesResult.roles
            });
        }
    }

    openCloseAddScreen(force?: boolean)
    {
        const opened = force === undefined ? !this.state.addScreenOpened : force;

        this.setState({addScreenOpened: opened});
    }

    async getEmployees(offset: number)
    {
        const result = await paApiHelper.getEmployees(maxRows, offset);
        if(result.success !== false)
        {
            this.setState({
                employees: result.users,
                totalEmployees: result.total,
            })
        }
    }

    haveRequiredFields(employee: any)
    {
        let have = true;

        const fields = Object.keys(employee);

        for(const field of employeeRequiredFields)
        {
            if(!fields.includes(field))
            {
                have = false;
            }
        }

        return have;
    }

    async componentDidMount() {

        await this.getExtra()

        this.setState({
            employeesLoading: true,
        })

        await this.getEmployees(0);

        this.setState({
            employeesLoading: false,
        })
    }

    async createEmployee() {
        if(this.haveRequiredFields(this.state.employee))
        {
            const result = await paApiHelper.createEmployee(this.state.employee);
            if(result.success)
            {
                Notifier.success(result.message)
            }
            else
            {
                Notifier.error(result.message)
            }
        }
        else
        {
            Notifier.warn("Необходимо заполнить обязательные поля")
        }
    }

    setField(name: string, value: any)
    {
        const employee = Object.assign({}, this.state.employee);

        employee[name] = value;

        this.setState({employee: employee});
    }

    async updateRoles(userId: number, rolesIds: number[]) {

        if(rolesIds.length < 1)
        {
            Notifier.warn("Нельзя удалить последнюю роль пользователя!");
            return;
        }

        const result = await paApiHelper.grantRoles(userId, rolesIds);

        if(result.success)
        {
            Notifier.success(result.message)
        }
        else
        {
            Notifier.error(result.message)
        }
    }

    async updateStatus(userId: number, statusBody: any) {

        const result = await paApiHelper.editEmployeeStatus(userId, statusBody);

        if(result.success)
        {
            Notifier.success(result.message)
        }
        else
        {
            Notifier.error(result.message)
        }
    }


    render() {
        return (
            <div>
                {!this.state.addScreenOpened && (
                    <>
                        <TitleTextBlock>
                            Здесь находятся все ваши сотрудники на платформе.
                            <br />
                            Вы можете найти нужного сотрудника, посмотреть
                            <br />
                            его профиль и действия на платформе.
                        </TitleTextBlock>
                        {/* <div
                            style={{
                                border: "1px dotted black",
                                margin: "40px 0px",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            TODO: "Разместить тут строку поиска в дальнейшем"
                            <div style={{ width: "150px", height: "50px" }}>
                                <Secondary1Button onClick={() => Notifier.info("Информация", false)} text="Сбросить"></Secondary1Button>
                            </div>
                        </div> */}
                        {
                            this.state.employeesLoading ? 
                            <div style={{width: "100%", height: "500px"}}>
                                <SimpleLoader></SimpleLoader>
                            </div>
                            :
                            <HeaderGreenProvider value={true}>
                                <TableWrapper
                                    total={this.state.totalEmployees}
                                    rowsCount={10}
                                    headerData={employeesHeader}
                                    bodyData={processData(this.state.employees, this.state.roles, this.state.accessStatuses, this.updateRoles, this.updateStatus)}
                                    onChangePage={(offset: number) => this.getEmployees(offset)}
                                />
                            </HeaderGreenProvider>
                        }
                        <div style={{ marginTop: "40px", width: "250px", height: "50px" }}>
                            <PrimaryButton text="Добавить нового сотрудника"
                                onClick={() => this.openCloseAddScreen()}
                            />
                        </div>
                    </>
                )}
                {this.state.addScreenOpened && this.state.employeesLoading === false && (
                    <>
                        <h3>Введите данные нового сотрудника</h3>
                        <div className="add-employee_grid">
                            <div>
                                <FieldHeading>Имя</FieldHeading>
                                <div style={{width: "450px", height: "50px"}}>
                                    <Input placeholder="Имя" onChangeValue={(value: string) => this.setField("firstName", value)}/>
                                </div>
                            </div>
                            <div>
                                <FieldHeading required={true}>Имя пользователя</FieldHeading>
                                <div style={{width: "450px", height: "50px"}}>
                                    <Input placeholder="That.Cool_Guy.42" onChangeValue={(value: string) => this.setField("username", value)} />
                                </div>
                            </div>
                            <div>
                                <FieldHeading>Фамилия</FieldHeading>
                                <div style={{width: "450px", height: "50px"}}>
                                    <Input placeholder="Фамилия" onChangeValue={(value: string) => this.setField("lastName", value)}/>
                                </div>
                            </div>
                            <div>
                                <FieldHeading required={true}>E-mail</FieldHeading>
                                <div style={{width: "450px", height: "50px"}}>
                                    <Input placeholder="Адрес электронной почты" onChangeValue={(value: string) => this.setField("email", value)}/>
                                </div>
                            </div>
                            <div>
                                <FieldHeading>Отчество (если имеется)</FieldHeading>
                                <div style={{width: "450px", height: "50px"}}>
                                    <Input placeholder="Отчество" onChangeValue={(value: string) => this.setField("middleName", value)}/>
                                </div>
                            </div>
                            <div>
                                <FieldHeading required={true}>Регион</FieldHeading>
                                <div style={{width: "450px", height: "50px"}}>
                                    <SingleSelect values={mapArrayToDropdownElements(this.state.locations)} onChange={(value: number) => this.setField("region", "RU")}/>
                                </div>
                            </div>
                            <div>
                                <FieldHeading required={true}>Пароль</FieldHeading>
                                <div style={{width: "450px", height: "50px"}}>
                                    <Input type="password" onChangeValue={(value: string) => this.setField("password", value)}/>
                                </div>
                            </div>
                            <div>
                                <FieldHeading required={true}>Пароль еще раз</FieldHeading>
                                <div style={{width: "450px", height: "50px"}}>
                                    <Input type="password" onChangeValue={(value: string) => this.setField("repeatPassword", value)}/>
                                </div>
                            </div>
                        </div>
                        <div className="add-employee_control">
                            <div style={{width: "250px", height: "50px" }}>
                                <Secondary1Button text="Отменить"
                                    onClick={() => this.openCloseAddScreen(false)}
                                />
                            </div>
                            <div style={{width: "250px", height: "50px" }}>
                                <PrimaryButton text="Добавить"
                                    onClick={() => this.createEmployee()}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }
}
