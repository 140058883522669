import React from 'react';
import './style.scss';

interface TextareaProps {
    disabled?: boolean;
    defaultValue?: string;
    badge?: any;
    onBadgeClick?: Function;
    onChangeValue?: (value: string) => void;
    pattern?: RegExp;
    maxLength?: number;
    placeholder?: string;
}
interface TextareaState {
    value: string;
}
class Textarea extends React.Component<TextareaProps, TextareaState> {

    refContols: any = null;
    constructor(props: TextareaProps) {
        super(props);
        this.state = {
            value: this.props.defaultValue || "",
        };
        this.refContols = React.createRef();
    }

    componentDidUpdate(prevProps: TextareaProps, prevState: TextareaState) {
        if (prevState.value !== this.state.value) {
            if (this.props.onChangeValue) {
                this.props.onChangeValue(this.state.value);
            }
        }
    }

    handleChangeValue(event: any) {
        const r = this.props.pattern;
        if (r) {
            if (r.test(event.target.value)) {
                this.setState(
                    {
                        value: event.target.value
                    }
                )
            }
            event.target.value = this.state.value;
        }
        else {
            this.setState(
                {
                    value: event.target.value
                }
            )
        }
    }

    handleBadgeClick(event: any) {
        if (this.props.onBadgeClick) {
            this.props.onBadgeClick();
        }
    }


    render() {
        const defaultValue = this.props.defaultValue || "";
        const placeholder = this.props.placeholder || "";
        const limit = this.props.maxLength;
        return (
            <div className='textarea-container'>
                <textarea
                    ref={this.refContols}
                    className="textarea"
                    onBlur={(event) => this.handleChangeValue(event)}
                    disabled={this.props.disabled}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    maxLength={limit}
                />
                {this.props.children}
            </div>
        );
    }
}

export default Textarea;
