import React from 'react';
import './style.scss';



interface InputProps {
    disabled?: boolean;
    defaultValue?: string;
    type?: string;
    badge?: any;
    onBadgeClick?: Function;
    onChangeValue?: (value: string) => void;
    pattern?: RegExp;
    placeholder?: string;
    min?: string | number;
    max?: string | number;
}

interface InputState {
    value: string;
}

class Input extends React.Component<InputProps, InputState> {

    refContols: any = null;
    constructor(props: InputProps) {
        super(props);
        this.state = {
            value: this.props.defaultValue || ""
        };
        this.refContols = React.createRef();
    }

    componentDidMount(): void {
        // if(this.state.value !== "")
        // {
        //     if (this.props.onChangeValue) {
        //         this.props.onChangeValue(this.state.value);
        //     }
        // }
    }

    componentDidUpdate(prevProps: InputProps, prevState: InputState) {
        if(prevState.value !== this.state.value)
        {
            if (this.props.onChangeValue) {
                this.props.onChangeValue(this.state.value);
            }
        }
    }

    handleChangeValue(event: any) {
        const r = this.props.pattern;
        if (r) {
            if (r.test(event.target.value)) {
                this.setState(
                    {
                        value: event.target.value
                    }
                )
            }
            event.target.value = this.state.value;
        }
        else {

            if(this.props.min)
            {
                if(Number(event.target.value) < Number(this.props.min))
                {
                    event.target.value = this.props.min;
                }
            }

            if(this.props.max)
            {
                if(Number(event.target.value) > Number(this.props.max))
                {
                    event.target.value = this.props.max;
                }
            }

            console.log(this.props);
            console.log(event.target.value);

            this.setState(
                {
                    value: event.target.value
                }
            )
        }
    }

    handleBadgeClick(event: any) {
        if (this.props.onBadgeClick) {
            this.props.onBadgeClick();
        }
    }

    render() {

        const type = this.props.type || "text";
        const placeholder = this.props.placeholder || "";
        const badge = this.props.badge;
        const defaultValue = this.props.defaultValue || "";

        return (
            <div className='input-container'>
                <input
                    ref={this.refContols}
                    type={type}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    className="input input__text"
                    disabled={this.props.disabled}
                    onChange={(event) => this.handleChangeValue(event)}
                    autoComplete="new-password"
                    min={this.props.min || undefined}
                    max={this.props.max || undefined}
                />
                {
                    badge &&
                    <img
                        className='input-badge'
                        src={badge}
                        alt="ci"
                        onClick={(event) => this.handleBadgeClick(event)}
                    />
                }
                {this.props.children}
            </div>
        )
    }
}

export default Input;