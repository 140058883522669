import React from "react";

import "./myRequests.scss";

import TableWrapper, {
    HeaderGreenProvider,
} from "../../../Components/Layout/TableWrapper";

import TitleTextBlock from "../../../Components/Layout/TextBlocks/TitleTextBlock";
import PrimaryButton from "../../../Components/Buttons/PrimaryButton";

import SingleSelect from "../../../Components/Dropdowns/Select/Single";
import MultiSelect from "../../../Components/Dropdowns/Select/Multi";
import paApiHelper from "../../../Utils/personalAreaApi";

import Notifier from "../../../Components/Layout/Notifier/store";

import SimpleLoader from "../../../Components/Layout/Loaders/Resizeable";

import { mapArrayToDropdownElements } from "../../../Utils";

import moment from "moment";

import IcoApprove from "../../../Media/Icons/ico-approve.svg";
import IcoClock from "../../../Media/Icons/ico-clock.svg";
import IcoCancel from "../../../Media/Common/cancel.svg";
import PopupWrapper from "../../../Components/Layout/Popup/PopupWrapper";

import ApprovePopup from "../../../Components/Layout/Popup/ServicesRequests/ApprovePopup";
import PostponePopup from "../../../Components/Layout/Popup/ServicesRequests/PostponePopup";
import RejectRequestPopup from "../../../Components/Layout/Popup/ServicesRequests/RejectPopup";
import MessagePopup from "../../../Components/Layout/Popup/ServicesRequests/MessagePopup";
import DetailsPopup from "../../../Components/Layout/Popup/ServicesRequests/DetailsPopup";
import Secondary2Button from "../../../Components/Buttons/Secondary1Button";

const requestsHeader = {
    id: "ID",
    user: "Пользователь",
    service: "Запрашиваемая услуга",
    datetime: "Время и дата",
    status: "Статус",
    control: "Управление",
};

const processData = (data: any, extra: any) => {
    const statuses = extra.statuses;

    const controls = extra.controls;

    const newData = data.map((element: any) => {
        return {
            id: element.id,
            user: (
                <div>
                    {element.profile.lastName} {element.profile.firstName}{" "}
                    <br /> {element.profile.middleName}
                </div>
            ),
            service: <div>{element.service.name}</div>,
            datetime: (
                <div className="requests-control-time">
                    {moment(element.serviceDate * 1000).format(
                        "DD-MM-YYYY - HH:mm"
                    )}
                    {/* (?) */}
                    {/* TODO: Добавить HINT, что время в текущей временной зоне */}
                </div>
            ),
            status: (
                <div>
                    {statuses.find((s: any) => s.id === element.statusId).name}
                </div>
            ),
            control: (
                <div className="requests-control-buttons">
                    {![4, 5, 6, 11, 12, 13, 14, 15, 16, 17].includes(element.statusId) && (
                        <div
                            onClick={() => controls.handler(element, "message")}
                            className="requests-control-button"
                        >
                            <div>Отправить сообщение</div>
                            <img alt="v" src={IcoApprove} />
                        </div>
                    )}
                    {[9].includes(element.statusId) && (
                        <div
                            onClick={() => controls.handler(element, "approvePostpone")}
                            className="requests-control-button"
                        >
                            <div>Одобрить заявку</div>
                            <img alt="v" src={IcoApprove} />
                        </div>
                    )}
                    {[1, 7, 8, 9, 10].includes(element.statusId) &&
                        element.serviceDate > moment().unix() && (
                            <div
                                onClick={() =>
                                    controls.handler(element, "postpone")
                                }
                                className="requests-control-button"
                            >
                                <div>Перенести заявку</div>
                                <img alt="v" src={IcoClock} />
                            </div>
                        )}
                    {[1, 2, 7, 8, 9, 10].includes(element.statusId) &&
                        element.serviceDate > moment().unix() && (
                            <div
                                onClick={() =>
                                    controls.handler(element, "reject")
                                }
                                className="requests-control-button"
                            >
                                <div>Отменить заявку</div>
                                <img alt="v" src={IcoCancel} />
                            </div>
                        )}
                    {/* {[2, 8, 10].includes(element.statusId) &&
                        moment().unix() - element.serviceDate > 15 * 60 && (
                            <div
                                onClick={() =>
                                    controls.handler(element, "close")
                                }
                                className="requests-control-button"
                            >
                                <div>Консультация завершена</div>
                                <img alt="v" src={IcoApprove} />
                            </div>
                        )} */}
                    {/* 4, 5, 6, 14, 15, 16, 17 */}
                    {![0].includes(element.statusId) && (
                        <div
                            onClick={() => controls.handler(element, "details")}
                            className="requests-control-button"
                        >
                            <div>Посмотреть детали</div>
                            <img alt="v" src={IcoApprove} />
                        </div>
                    )}
                </div>
            ),
        };
    });

    return newData;
};

interface MyRequestsSectionState {
    currentRequests: any[];
    currentRequestsLoading: boolean;
    currentRequestsTotal: number;

    popupType: string | null;

    popupOpened: boolean;

    selectedRequest: any;

    subsection: string;
    requestsStatuses: any[];
    requestsCancelReasons: any[];
}

const maxRows = 10;

export default class MyRequestsSection extends React.Component<
    {},
    MyRequestsSectionState
> {
    constructor(props: any) {
        super(props);

        this.state = {
            currentRequests: [],
            currentRequestsLoading: true,
            currentRequestsTotal: 0,

            subsection: "new",

            popupType: "",

            popupOpened: false,

            selectedRequest: null,

            requestsStatuses: [],
            requestsCancelReasons: [],
        };
    }

    async getExtra() {
        const srExtraResult = await paApiHelper.getServicesRequestsExtra();

        const res = [srExtraResult.success];

        if (res.some((r) => r === false)) {
            Notifier.error("Ошибка отображения страницы");
        } else {
            this.setState({
                requestsStatuses: srExtraResult.servicesRequestsStatuses,
                requestsCancelReasons:
                    srExtraResult.servicesRequestsCancelReasons,
            });
        }
    }

    openClosePopup(type: string, force?: boolean) {
        const opened = force === undefined ? !this.state.popupOpened : force;

        this.setState({ popupOpened: opened, popupType: type });
    }

    handleServiceRequest(serviceRequest: any, type: string) {
        this.openClosePopup(type, true);

        this.setState({
            selectedRequest: serviceRequest,
        });
    }

    async getRequests(offset: number) {
        const requestsBody = {};

        const result = await paApiHelper.getMyRequests(
            requestsBody,
            maxRows,
            offset,
            this.state.subsection
        );
        if (result.success !== false) {
            this.setState({
                currentRequests: result.servicesRequests,
                currentRequestsTotal: result.total,
            });
        }
    }

    async selectSubsection(name: string) {
        this.setState({
            subsection: name,
        });

        await this.getExtra();

        await this.getCurrentRequests();
    }

    async getCurrentRequests() {
        this.setState({
            currentRequestsLoading: true,
        });

        await this.getRequests(0);

        this.setState({
            currentRequestsLoading: false,
        });
    }

    async componentDidMount() {
        await this.getExtra();

        await this.getCurrentRequests();
    }

    async editRequest(id: number, editBody: any) {
        const reqBody: any = {};

        for(const k in editBody)
        {
            if(![null, undefined].includes(editBody[k]))
            {
                reqBody[k] = editBody[k];
            }
        }

        const result = await paApiHelper.editRequest(id, reqBody);


        if(result.success !== true)
        {
            Notifier.error(result.message);
            return;
        }

        const status = editBody.status;

        const successMap: any = {
            approve: "Заявка успешно одобрена!",
            postpone: "Заявка успешно перенесена!",
            reject: "Заявка успешно отменена!",
            message: "Сообщение успешно отправлено!",
        };

        const notification = successMap[status] || (Object.keys(editBody).length === 1 ? successMap["message"] : "Успешно!")
        Notifier.success(notification);
        await this.getCurrentRequests();
    }

    render() {

        const subtitleMap: any = {
            new: "Новые заявки",
            postponed: "Заявки на перенос",
            approved: "Потдтвержденные",
            closed: "Завершенные",
        };

        const subtitle = subtitleMap[this.state.subsection];

        return (
            <div>
                <TitleTextBlock>
                    Здесь отображаются заявки, оставленные тобой.
                    <br />
                    Можно одобрить, перенести или отклонить заявку.
                </TitleTextBlock>
                <div className="requests-subsections">
                    {Object.keys(subtitleMap).map((s) => {
                        return (
                            <div className="requests-subsection">
                                {s === this.state.subsection ? (
                                    <PrimaryButton
                                        onClick={() =>
                                            this.selectSubsection(s)
                                        }
                                        text={subtitleMap[s]}
                                    />
                                ) : (
                                    <Secondary2Button
                                        onClick={() =>
                                            this.selectSubsection(s)
                                        }
                                        text={subtitleMap[s]}
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>
                <br />
                <div className="requests-subsection">{subtitle}</div>
                {this.state.currentRequestsLoading ? (
                    <div style={{ width: "100%", height: "500px" }}>
                        <SimpleLoader></SimpleLoader>
                    </div>
                ) : (
                    <HeaderGreenProvider value={true}>
                        <TableWrapper
                            total={this.state.currentRequestsTotal}
                            rowsCount={maxRows}
                            headerData={requestsHeader}
                            bodyData={processData(this.state.currentRequests, {
                                statuses: this.state.requestsStatuses,
                                controls: {
                                    handler: (r: any, type: string) =>
                                        this.handleServiceRequest(r, type),
                                },
                            })}
                            onChangePage={(offset: number) =>
                                this.getRequests(offset)
                            }
                        />
                    </HeaderGreenProvider>
                )}
                {(() => {
                    switch (this.state.popupType) {
                        case "approve":
                            return (
                                <ApprovePopup
                                    serviceRequest={this.state.selectedRequest}
                                    onClose={() =>
                                        this.openClosePopup("approve", false)
                                    }
                                    active={this.state.popupOpened}
                                    onApprove={(edit: any) =>
                                        this.editRequest(
                                            this.state.selectedRequest.id,
                                            Object.assign(
                                                { status: "approve" },
                                                edit
                                            )
                                        )
                                    }
                                ></ApprovePopup>
                            );
                        case "approvePostpone":
                            return (
                                <ApprovePopup
                                    serviceRequest={this.state.selectedRequest}
                                    onClose={() =>
                                        this.openClosePopup("approvePostpone", false)
                                    }
                                    active={this.state.popupOpened}
                                    onApprove={(edit: any) =>
                                        this.editRequest(
                                            this.state.selectedRequest.id,
                                            Object.assign(
                                                { status: "approvePostpone" },
                                                edit
                                            )
                                        )
                                    }
                                ></ApprovePopup>
                            );
                        case "postpone":
                            return (
                                <PostponePopup
                                    serviceRequest={this.state.selectedRequest}
                                    onClose={() =>
                                        this.openClosePopup("postpone", false)
                                    }
                                    active={this.state.popupOpened}
                                    onPostpone={(edit: any) =>
                                        this.editRequest(
                                            this.state.selectedRequest.id,
                                            Object.assign(
                                                { status: "postpone" },
                                                edit
                                            )
                                        )
                                    }
                                ></PostponePopup>
                            );
                        case "reject":
                            return (
                                <RejectRequestPopup
                                    serviceRequest={this.state.selectedRequest}
                                    reasons={this.state.requestsCancelReasons}
                                    onClose={() =>
                                        this.openClosePopup("reject", false)
                                    }
                                    active={this.state.popupOpened}
                                    onReject={(edit: any) =>
                                        this.editRequest(
                                            this.state.selectedRequest.id,
                                            Object.assign(
                                                { status: "reject" },
                                                edit
                                            )
                                        )
                                    }
                                ></RejectRequestPopup>
                            );
                        case "details":
                        case "history":
                            return (
                                <DetailsPopup
                                    serviceRequest={this.state.selectedRequest}
                                    onClose={() =>
                                        this.openClosePopup("details", false)
                                    }
                                    statuses={this.state.requestsStatuses}
                                    active={this.state.popupOpened}
                                ></DetailsPopup>
                            );
                        case "message":
                            return (
                                <MessagePopup
                                    serviceRequest={this.state.selectedRequest}
                                    onClose={() =>
                                        this.openClosePopup("message", false)
                                    }
                                    active={this.state.popupOpened}
                                    onSend={(edit: any) =>
                                        this.editRequest(
                                            this.state.selectedRequest.id,
                                            edit
                                        )
                                    }
                                ></MessagePopup>
                            );

                        default:
                            return <></>;
                    }
                })()}
            </div>
        );
    }
}
