import React from 'react';
import './style.scss';
interface PrimaryButtonProps {
    text?: string;
    disabled?: boolean;
    onClick?: () => void;
    loading?: boolean;
}
class PrimaryButton extends React.Component<PrimaryButtonProps> {

    onClickHandler()
    {
        if(this.props.onClick && !this.props.loading)
        {
            this.props.onClick();
        }
    }

    render() {
        const loading = this.props.loading;
        const text = this.props.text || "";
        return (
            <button 
                className="primary__button" 
                onClick={() => { this.onClickHandler();}} 
                disabled={this.props.disabled}
            >
                {loading && <svg className="preloader" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.99992 1.66536C8.35174 1.66536 6.74058 2.1541 5.37017 3.06978C3.99976 3.98546 2.93165 5.28695 2.30092 6.80967C1.67019 8.33239 1.50516 10.0079 1.82671 11.6244C2.14825 13.241 2.94192 14.7258 4.10736 15.8913C5.2728 17.0567 6.75766 17.8504 8.37416 18.1719C9.99067 18.4935 11.6662 18.3284 13.1889 17.6977C14.7117 17.067 16.0132 15.9989 16.9288 14.6285C17.8445 13.258 18.3333 11.6469 18.3333 9.9987" stroke="#FCFCFC" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        dur="1s"
                        from="0"
                        to="360"
                        repeatCount="indefinite" />
                </svg>
                }
                {!loading && <span>{text}</span>}
            </button>
        );
    }
}

export default PrimaryButton;
