import React from 'react';
import './style.scss';

interface Secondary2ButtonProps {
    text?: string;
    badge?: any;
    onClick?: () => void;
}

class Secondary2Button extends React.Component<Secondary2ButtonProps> {

    render() {
        const onClickHandler = this.props.onClick || (() => { });
        const text = this.props.text || "";
        const badge = this.props.badge || false;
        return (
            <button className="secondary_2__button" onClick={onClickHandler}>
                {
                    badge && 
                    <img src={badge} className="secondary_2__icon" alt="[ ]" />
                }
                {text}
                {this.props.children}
            </button>
        );
    }
}

export default Secondary2Button;

