import React from "react";

import "./style.scss";

import NotificationsStore, { Notification } from "./store";

import crossImg from "../../../Media/Common/cross.svg"

interface NotificationContainerProps {
    id: any;
    type: string;
    autoDelete?: boolean;
    onDelete: () => void;
}

interface NotificationContainerState {
    closing: boolean;
    timer: NodeJS.Timeout | null;
}

export class NotificationContainer extends React.Component<
    NotificationContainerProps,
    NotificationContainerState
> {
    constructor(props: NotificationContainerProps) {
        super(props);

        this.state = {
            closing: false,
            timer: null,
        };
    }

    componentDidMount(): void {
        setTimeout(() => {
            if(this.state.timer === null && this.props.autoDelete)
            {
                this.handleClosing()
            }
        }, 5 * 1000)
    }

    handleClosing()
    {
        const timer = setTimeout(() => {
            this.props.onDelete();
        }, 1000)
        this.setState({
            closing: true,
            timer: timer,
        });
    }

    render() {
        const key = this.props.id;
        return (
            <div
                key={key}
                id={String(key)}
                className={`notification-container ${this.state.closing ? "delete" : "appear"} ${this.props.type}`}
                // className={`notification-container ${this.state.closing ? "" : ""} ${this.props.type}`}
            >
                <div
                    className="notification-close"
                    onClick={() => this.handleClosing()}
                >
                    <img src={crossImg} alt="x"></img>
                </div>
                <div className={`notification-content`}>
                    {this.props.children}
                </div>
                <div className="notification-time">
                    <div className={`notification-bar ${this.props.autoDelete ? "" : "constant"}`}></div>
                </div>
            </div>
        );
    }
}

interface NotifierState {
    notifications: Notification[];
}

export default class Notifier extends React.Component<{}, NotifierState> {
    constructor(props: any) {
        super(props);

        this.state = {
            notifications: [],
        };
    }

    componentDidMount(): void {
        NotificationsStore.addAddingListener((notification) => this.addNotification(notification));
        NotificationsStore.addRemovingListener((id) => this.removeNotification(id));
    }

    addNotification(notification: Notification)
    {
        this.setState({
            notifications: [...this.state.notifications, notification]
        })
    }

    removeNotification(id: number)
    {
        this.setState({
            notifications: [...this.state.notifications].filter((n: Notification) => n.id !== id),
        })
    }


    render() {
        return (
            <div className="notifier-wrapper">
                <div className="notifier">
                    {this.state.notifications.length > 0 &&
                        this.state.notifications.map((notification, id) => {
                            return (
                                <NotificationContainer
                                    key={notification.id}
                                    id={notification.id}
                                    type={notification.type}
                                    autoDelete={notification.autoDelete}
                                    onDelete={() => NotificationsStore.remove(notification.id)}
                                >
                                    {notification.content}
                                </NotificationContainer>
                            );
                        })}
                </div>
            </div>
        );
    }
}
