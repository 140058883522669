import React from "react";

// import "../style.scss";
import "./style.scss";

import MainView from "../Common/MainView";

import Breadcrumbs from "../../Components/Layout/Breadcrumbs";
import { BreadcrumbsItem } from "../../Components/Layout/Breadcrumbs/types";
import Popup from "../../Components/Layout/Popup";
import PopupWrapper from "../../Components/Layout/Popup/PopupWrapper";
import Avatar from "../../Components/Layout/Avatar";

import ProfileView from "./Sections/profile";
import NotificationsView from "./Sections/notifications";
import SecurityView from "./Sections/security";
import paApiHelper from "../../Utils/personalAreaApi";

import IcoPencil from "../../Media/Icons/ico-pencil.svg";
import IcoBell from "../../Media/Icons/ico-bell.svg";
import IcoLock from "../../Media/Icons/ico-lock.svg";
import Secondary2Button from "../../Components/Buttons/Secondary2Button";

const breadcrumbsButtons: BreadcrumbsItem[] = [
    {
        id: 1,
        name: "Редактировать профиль",
        path: "/?section=profile",
        icon: IcoPencil,
    },
    {
        id: 2,
        name: "Уведомления",
        path: "/?section=notifications",
        icon: IcoBell,
    },
    {
        id: 3,
        name: "Безопасность",
        path: "/?section=security",
        icon: IcoLock,
    },
];

interface ProfileState {
    popupActive: boolean;
    profile: any;
}

export default class ProfilePage extends React.Component<{}, ProfileState> {
    constructor(props: any) {
        super(props);
        this.state = {
            popupActive: false,
            profile: null,
        };
    }

    async loadProfile() {
        const result = await paApiHelper.getProfile();

        if (result.success !== false) {
            this.setState({ profile: result });
        } else {
            // window.open("/unauth", "_self") //TODO: Заменить на Что-по пошло не так :(
        }
    }

    componentDidMount() {
        this.loadProfile();
    }

    changePopupActive = () => {
        const active = this.state.popupActive;
        this.setState({
            popupActive: !active
        });
        this.loadProfile();
    };

    render() {
        const { popupActive } = this.state;

        const queryParams = new URLSearchParams(window.location.search);
        let section = "profile";

        const querySectionParam = queryParams.get("section");
        switch (querySectionParam) {
            case "profile":
                section = "profile";
                break;
            case "notifications":
                section = "notifications";
                break;
            case "security":
                section = "security";
                break;
        }

        let avatarImage: any = null;

        if(this.state.profile && this.state.profile.avatar)
        {
            avatarImage = this.state.profile.avatar.medium;
        }

        return (
            <MainView>
                <Breadcrumbs breadcrumbsButtons={breadcrumbsButtons}>
                    <div className="change-avatar">
                        <Avatar size={75} src={avatarImage}/>
                        <div className="change-avatar-button">
                            <Secondary2Button
                                onClick={() => this.changePopupActive()}
                            >
                                <span style={{ textAlign: "left" }}>
                                    Изменить аватар <br /> Mаксимальный размер:
                                    5MB
                                </span>
                            </Secondary2Button>
                        </div>
                    </div>
                </Breadcrumbs>
                <PopupWrapper active={popupActive}>
                    <Popup onTogglePopup={this.changePopupActive} />
                </PopupWrapper>
                {(() => {
                    switch (section) {
                        case "notifications":
                            return <NotificationsView />;
                        case "security":
                            return (
                                <SecurityView profile={this.state.profile} />
                            );
                        default:
                            return <ProfileView profile={this.state.profile} />;
                    }
                })()}
            </MainView>
        );
    }
}
