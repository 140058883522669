import React from "react";
import "./style.scss";

import "../common.scss";

import Spinner from "../../../../Media/Common/loading.svg";

interface SimpleLoaderProps {
    spinnerSize?: number;
}

export default class SimpleLoader extends React.Component<SimpleLoaderProps, {}> {


    render() {
        const size = this.props.spinnerSize || 100;
        return (
            <div className="simple-loader-container">
                <img style={{width: `${size}px`}} className="simple-loader-spinner common-loader-spinner" src={Spinner} alt="@"/>
            </div>
        )
    }
}