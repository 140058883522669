import moment from "moment";
import React from "react";

import "./style.scss";

import { outsideClickHandler } from "../../../Utils";

import Input from "../Input";

import CalendarIcon from "../../../Media/Icons/ico-calendar.svg";

import CalendarPanel from "./Panel";

import PrimaryButton from "../../Buttons/PrimaryButton";
import Secondary2Button from "../../Buttons/Secondary2Button";

interface CalendarProps {
    date?: moment.Moment;
    showTime?: boolean;
    onChange?: (date: moment.Moment) => void;
}

interface CalendarState {
    date: moment.Moment;
    panelOpened: boolean;
}

class Calendar extends React.Component<CalendarProps, CalendarState> {
    refControls: any = null;

    constructor(props: any) {
        super(props);
        moment.locale("ru");

        const initDate = this.props.date || moment();

        this.state = {
            date: initDate,
            panelOpened: false,
        };

        this.refControls = React.createRef();
    }

    componentDidMount() {
        const s = this.state;

        outsideClickHandler({
            bindObj: this,
            body: document,
            ref: this.refControls,
            action: this.openCloseCalendarPanel.bind(this, false),
            type: "click",
            condition: !s.panelOpened,
        });
    }

    openCloseCalendarPanel(open?: boolean) {
        const opened = this.state.panelOpened;

        this.setState({
            panelOpened: open !== undefined ? open : !opened,
        });
    }

    componentDidUpdate(prevProps: CalendarProps, prevState: CalendarState) {
        if (prevState.date !== this.state.date) {
            if (this.props.onChange) {
                this.props.onChange(this.state.date);
            }
        }
    }

    selectDate(dateStr: string, format?: string) {
        this.setState({
            date: moment(dateStr, format),
        });
    }

    render() {
        const s = this.state;

        let format = "DD.MM.YYYY";
        let inputType = "text";
        let pattern = new RegExp(
            /^(0?0[1-9]|[12][0-9]|3[01])\.(0?0[1-9]|1[0-2])\.\d\d\d\d$/gm
        );

        const showTime = this.props.showTime || false;
        if (showTime) {
            format = `HH:mm DD.MM.YYYY`;
            inputType = "text";
            pattern = new RegExp(
                /^(00|0[0-9]|1[0-9]|2[0-3]):(0[0-9]|[0-5][0-9]) (0?0[1-9]|[12][0-9]|3[01])\.(0?0[1-9]|1[0-2])\.\d\d\d\d$/gm
            );
        }

        const inputValue = s.date.format(format);

        const startYear = 1901;
        const endYear = Number(moment().format("YYYY")) + 2; //Текущий год + 2 года вперед

        return (
            <div ref={this.refControls} className="calendar-wrapper">
                <div style={{ width: "100%", height: "100%" }}>
                    <Input
                        badge={CalendarIcon}
                        type={inputType}
                        disabled={false}
                        key={inputValue}
                        defaultValue={inputValue}
                        onBadgeClick={() => this.openCloseCalendarPanel()}
                        onChangeValue={(value) =>
                            this.selectDate(value, format)
                        }
                        pattern={pattern}
                    />
                </div>

                {s.panelOpened && (
                    <div className="calendar__calendar-panel">
                        <CalendarPanel
                            key={s.date.format()}
                            date={s.date}
                            onChange={(date) => this.selectDate(date.format())}
                            options={{
                                showTime: showTime,
                                startYear: startYear,
                                endYear: endYear,
                            }}
                        ></CalendarPanel>
                        <div className="calendar-panel-buttons">
                            <div style={{ width: "136px", height: "40px", margin: "4px" }}>
                                <Secondary2Button
                                    text="Закрыть"
                                    onClick={() => this.openCloseCalendarPanel(false)}
                                />
                            </div>
                            <div style={{ width: "136px", height: "40px", margin: "4px"  }}>
                                <PrimaryButton
                                    text="Сохранить"
                                    onClick={() => this.openCloseCalendarPanel(false)}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default Calendar;
