import moment from 'moment';
import React from 'react';

import "./style.scss"

import Chevron from "../../../../Media/Common/chevron-black.svg"

import { outsideClickHandler } from "../../../../Utils";

interface CalendarPanelOptions {
    showTime: boolean | true;
    startYear: number | 1901;
    endYear: number | 2024;
}

interface CalendarPanelProps {
    date?: moment.Moment;
    onChange?: (date: moment.Moment) => void;
    options?: CalendarPanelOptions;
}

interface CalendarPanelState {
    date: moment.Moment;
    day: number;
    month: number;
    year: number;
    hour: number;
    minute: number;

    monthDdOpened: boolean;
    yearDdOpened: boolean;
    timeSelectOpened: boolean;
}


class CalendarPanel extends React.Component<CalendarPanelProps, CalendarPanelState> {

    refControls: any = null;

    constructor(props: any) {
        super(props);
        moment.locale("ru"); //TODO: Убрать, когда появится локализация

        const initDate = this.props.date || moment();

        this.state = {
            date: initDate,
            day: Number(initDate.format("DD")),
            month: Number(initDate.format("MM")),
            year: Number(initDate.format("YYYY")),
            hour: initDate.hour(),
            minute: initDate.minute(),

            monthDdOpened: false,
            yearDdOpened: false,
            timeSelectOpened: false,
        };

        this.refControls = React.createRef();
    }

    defaultOptions: CalendarPanelOptions = {
        showTime: true,
        startYear: 1901,
        endYear: 2024
    }

    componentDidMount() {
        const s = this.state;

        outsideClickHandler({
            bindObj: this,
            body: document,
            ref: this.refControls,
            action: this.hideAllDds,
            type: "click",
            condition: !(s.monthDdOpened || s.yearDdOpened || s.timeSelectOpened)
        })
    }

    hideAllDds() {
        this.setState(
            {
                monthDdOpened: false,
                yearDdOpened: false,
                timeSelectOpened: false,
            }
        )
    }

    getWeekDays() { //TODO: Добавить локализацию
        const weekDaysRaw = moment.weekdaysShort().map(weekday => weekday.charAt(0).toUpperCase() + weekday.slice(1));
        const sunday = String(weekDaysRaw.shift());
        const weekDays = weekDaysRaw.concat([sunday]);
        return weekDays;
    }

    getMonthNamesMap() {
        const keys = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];

        const ruMonth: any = {
            'january': "Январь",
            'february': "Февраль",
            'march': "Март",
            'april': "Апрель",
            'may': "Май",
            'june': "Июнь",
            'july': "Июль",
            'august': "Август",
            'september': "Сентябрь",
            'october': "Октябрь",
            'november': "Ноябрь",
            'december': "Декабрь"
        }

        const namesMap: any = {};

        for (const key of keys) {
            namesMap[key] = {
                key: key,
                number: keys.indexOf(key) + 1,
                text: ruMonth[key] //TODO: Добавить локализацию по ключу
            }
        }

        return namesMap;
    }

    getMonth(monthNumber: number) {
        const namesMap = this.getMonthNamesMap();
        let monthName = "";
        for (const key in namesMap) {
            const month = namesMap[key];

            if (month.number === monthNumber) {
                monthName = month.text;
                break;
            }
        }
        return monthName;
    }

    getYearsList(from: number, to: number) {
        const range = to - from + 1;
        return Array.from({ length: range }, (x, i) => to - i);
    }

    firstDayOfMonth() {
        const s = this.state;
        const date = moment(`${s.year}-${s.month}-01`);
        const day = Number(date.day());
        return day === 0 ? 7 : day;
    }

    selectDate(element: any) {
        const s = this.state;
        const hourStr = s.hour < 10 ? `0${s.hour}` : String(s.hour);
        const minuteStr = s.minute < 10 ? `0${s.minute}` : String(s.minute);
        this.setState(
            {
                date: moment(`${element.date.format("YYYY-MM-DD")} ${hourStr}:${minuteStr}`),

                day: Number(element.date.format("DD")),
                month: Number(element.date.format("MM")),
                year: Number(element.date.format("YYYY")),

                monthDdOpened: false,
                yearDdOpened: false,
                timeSelectOpened: false,
            }
        );
    }

    componentDidUpdate(prevProps: CalendarPanelProps, prevState: CalendarPanelState) {
        if (prevState.date !== this.state.date) {
            if (this.props.onChange) {
                this.props.onChange(this.state.date);
            }
        }
    }

    getCalendarList() {
        const s = this.state;
        const activeDate = moment(`${s.year}-${s.month}-${s.day}`);
        const list = [];
        const firstDay = this.firstDayOfMonth();

        const totalDays = activeDate.daysInMonth();

        const previousMonth = moment(activeDate).subtract(1, "month");
        const nextMonth = moment(activeDate).add(1, "month");

        const previousMonthLastMonday = previousMonth.daysInMonth() - (firstDay - 2);

        for (let i = previousMonthLastMonday; i <= previousMonth.daysInMonth(); i++) {
            const day = i < 10 ? `0${i}` : i;
            list.push({
                day: i,
                date: moment(`${previousMonth.format("YYYY")}-${previousMonth.format("MM")}-${day}`),
                currentMonth: false,
            }); //Заполняем массив числами предыдущего месяца, начиная с понедельника
        }

        for (let i = 1; i <= totalDays; i++) {
            const day = i < 10 ? `0${i}` : String(i);
            const dt = moment(`${activeDate.format("YYYY")}-${activeDate.format("MM")}-${day}`);
            list.push({
                day: i,
                date: dt,
                chosen: dt.format("YYYY/MM/DD") === s.date.format("YYYY/MM/DD"),
                currentMonth: true,
            }); //Заполняем массив числами текущего месяца
        }

        const additionalDaysCount = 7 - list.length % 7;

        if (additionalDaysCount < 7) {
            for (let i = 1; i <= additionalDaysCount; i++) {
                const day = i < 10 ? `0${i}` : i;
                list.push({
                    day: i,
                    date: moment(`${nextMonth.format("YYYY")}-${nextMonth.format("MM")}-${day}`),
                    currentMonth: false,
                }); //Заполняем массив числами следующего месяца до конца недели
            }
        }

        const calendarList = [];

        while (list.length) {
            calendarList.push(list.splice(0, 7)); //Преобразуем массив в матрицу 7 дней на N недель
        };

        return calendarList;
    }

    openCloseMonthDd(openClose?: boolean) {
        const monthDdOpened = this.state.monthDdOpened;
        this.setState({
            monthDdOpened: openClose !== undefined ? openClose : !monthDdOpened,
            yearDdOpened: false,
            timeSelectOpened: false,
        })
    }

    openCloseYearDd(openClose?: boolean) {
        const yearDdOpened = this.state.yearDdOpened;
        this.setState({
            monthDdOpened: false,
            yearDdOpened: openClose !== undefined ? openClose : !yearDdOpened,
            timeSelectOpened: false,
        })
    }

    openCloseTimeSelect(openClose?: boolean) {
        const timeSelectOpened = this.state.timeSelectOpened;
        this.setState({
            monthDdOpened: false,
            yearDdOpened: false,
            timeSelectOpened: openClose !== undefined ? openClose : !timeSelectOpened,
        })
    }

    selectMonth(month: any) {
        this.setState(
            {
                month: month.number,
                monthDdOpened: false,
            }
        )
    }

    selectYear(year: number) {
        this.setState(
            {
                year: year,
                yearDdOpened: false,
            }
        )
    }

    selectNumber(e: any, from: number, to: number) {
        let inputValue = Number(e.target.value);

        if (inputValue < from) {
            inputValue = from;
        }

        if (inputValue > to) {
            inputValue = to;
        }

        let inputValueStr = String(inputValue);

        if (inputValue < 10) {
            inputValueStr = "0" + inputValueStr;
        }

        e.target.value = inputValueStr;
        return {
            inputValue,
            inputValueStr,
        };
    }

    selectHour(e: any) {
        const s = this.state;
        const {
            inputValue,
            inputValueStr
        } = this.selectNumber(e, 0, 23)

        this.setState({
            hour: inputValue,
            date: moment(`${s.date.format("YYYY-MM-DD")} ${inputValueStr}:${s.minute}`),
        })
    }

    selectMinute(e: any) {
        const s = this.state;
        const {
            inputValue,
            inputValueStr
        } = this.selectNumber(e, 0, 59)

        this.setState({
            minute: inputValue,
            date: moment(`${s.date.format("YYYY-MM-DD")} ${s.hour}:${inputValueStr}`),
        })
    }

    render() {
        const s = this.state;
        const weekdays = this.getWeekDays();
        const calendarList = this.getCalendarList();

        const monthName = this.getMonth(s.month);
        const monthsNamesMap = this.getMonthNamesMap();
        const monthList = Object.keys(monthsNamesMap).map(key => monthsNamesMap[key]);

        const year = s.year;

        const opts = this.props.options || this.defaultOptions;
        const yearsList = this.getYearsList(opts.startYear, opts.endYear);

        return (
            <div className='calendar-panel'>
                <div ref={this.refControls} className='calendar-high-controls'>
                    <div className={`calendar-high-controls-element month ${s.monthDdOpened ? "active" : ""}`}>
                        <div
                            className='calendar-high-controls-container'
                            onClick={() => this.openCloseMonthDd()}
                        >
                            <span className='calendar-high-controls-container'>
                                {monthName}
                            </span>
                            <div className={`calendar-high-controls-element-badge ${s.monthDdOpened ? "active" : ""}`}>
                                <img src={Chevron} alt="v"></img>
                            </div>
                        </div>
                        {
                            s.monthDdOpened &&
                            <div className='calendar-high-controls-dropdown'>
                                {
                                    monthList.map((month) =>
                                        <div
                                            className={`calendar-high-controls-dropdown-element ${month.number === s.month ? "active" : ""}`}
                                            onClick={() => this.selectMonth(month)}
                                        >
                                            <div className='calendar-high-controls-dropdown-element-text'>{month.text}</div>
                                        </div>)
                                }
                            </div>
                        }
                    </div>
                    <div className={`calendar-high-controls-element year ${s.yearDdOpened ? "active" : ""}`}>
                        <div
                            className='calendar-high-controls-container'
                            onClick={() => this.openCloseYearDd()}
                        >
                            <span className='calendar-high-controls-container'>
                                {year}
                            </span>
                            <div className={`calendar-high-controls-element-badge ${s.yearDdOpened ? "active" : ""}`}>
                                <img src={Chevron} alt="v"></img>
                            </div>
                        </div>
                        {
                            s.yearDdOpened &&
                            <div className='calendar-high-controls-dropdown'>
                                {
                                    yearsList.map((year) =>
                                        <div
                                            className={`calendar-high-controls-dropdown-element ${year === s.year ? "active" : ""}`}
                                            onClick={() => this.selectYear(year)}
                                        >
                                            <div className='calendar-high-controls-dropdown-element-text'>{year}</div>
                                        </div>)
                                }
                            </div>
                        }
                    </div>
                    {
                        opts.showTime &&
                        <div className={`calendar-high-controls-element time ${s.timeSelectOpened ? "active" : ""}`}>
                            <div
                                className='calendar-high-controls-container'
                                onClick={() => this.openCloseTimeSelect()}
                            >
                                <span className='calendar-high-controls-container'>
                                    {s.date.format("HH:mm")}
                                </span>
                                <div className={`calendar-high-controls-element-badge ${s.timeSelectOpened ? "active" : ""}`}>
                                    <img src={Chevron} alt="v"></img>
                                </div>
                            </div>
                            {
                                s.timeSelectOpened &&
                                <div className='calendar-high-controls-dropdown'>
                                    <div className='calendar-high-controls-time-selector'>
                                        <input
                                            className='calendar-high-controls-time-selector-input'
                                            type="number"
                                            onBlur={(e) => this.selectHour(e)}
                                            defaultValue={s.hour < 10 ? `0${s.hour}` : s.hour}
                                        >
                                        </input>
                                        <span>:</span>
                                        <input
                                            className='calendar-high-controls-time-selector-input'
                                            type="number"
                                            onBlur={(e) => this.selectMinute(e)}
                                            defaultValue={s.minute < 10 ? `0${s.minute}` : s.minute}
                                        >
                                        </input>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
                <table className='calendar-table'>
                    <thead>
                        <tr>
                            {
                                weekdays.map(
                                    (weekday, index) =>
                                        <th key={index} className='calendar-cell calendar-header'>
                                            {weekday}
                                        </th>
                                )
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            calendarList.map(
                                (row) =>
                                    <tr>
                                        {
                                            row.map(
                                                (element, index) =>
                                                    <td key={index}
                                                        className={`calendar-cell ${element.chosen ? "chosen" : ""} ${!element.currentMonth ? "inactive-day" : "day"}`}
                                                        onClick={() => element.currentMonth ? this.selectDate(element) : {}}
                                                    >
                                                        {element.day}
                                                    </td>
                                            )
                                        }
                                    </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

export default CalendarPanel;