export interface Notification {
    id: number;
    content: any;
    createdAt: Date;
    type: string
    autoDelete: boolean;
}

class NotificationsStore {

    notifications: Notification[] = [

    ]

    addListeners: ((notifications: Notification)=> void)[] = [

    ];

    removeListeners: ((id: number)=> void)[] = [

    ];

    addAddingListener(listener: (notification: Notification)=> void)
    {
        this.addListeners.push(listener);
    }

    addRemovingListener(listener: (id: number)=> void)
    {
        this.removeListeners.push(listener);
    }

    info(content: any, autoDelete: boolean = true)
    {
        this.add(content, autoDelete);
    }

    success(content: any, autoDelete: boolean = true)
    {
        this.add(content, autoDelete, "success");
    }

    error(content: any, autoDelete: boolean = true)
    {
        this.add(content, autoDelete, "error");
    }

    warn(content: any, autoDelete: boolean = true)
    {
        this.add(content, autoDelete, "warning");
    }

    add(content: any, autoDelete: boolean = true, type: string = "info-regular")
    {
        const newNotification: Notification = {
            id: this.notifications.length,
            content: content,
            createdAt: new Date(),
            type: type,
            autoDelete: autoDelete,
        }

        this.notifications.push(newNotification);

        for(const listener of this.addListeners) {
            listener(newNotification);
        }
    }

    remove(id: number)
    {
        for(const listener of this.removeListeners) {
            listener(id);
        }
    }

}

export default new NotificationsStore();