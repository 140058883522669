import React from 'react';
import './style.scss';
import Spinner from '../../Spinner';
import Play from '../../../../Media/Common/play.svg';
import TertiaryButton from '../../../Buttons/TertiaryButton';
import ReadonlyText from '../../../Inputs/ReadonlyText';
interface RegReqDetailsProps {
    onClose?: any;
}
interface RegReqDetailsState {
    opened: boolean;
    videoPreviewUrl: any,
    loading: boolean,
}
export default class RegReqDetails extends React.Component<RegReqDetailsProps, RegReqDetailsState> {

    refControls: any = null;

    constructor(props: RegReqDetailsProps) {
        super(props);
        this.state = {
            opened: false,
            videoPreviewUrl: '',
            loading: false,
        };
        this.refControls = React.createRef();
    }

    handleSubmit(e: any) {
        e.preventDefault();
        this.setState({
            loading: false
        });
    }

    render() {
        const { onClose: onOpenPopup } = this.props;

        const { loading } = this.state;
        let videoPreview: any = null;

        videoPreview = (
            <button
                className="register-popup__button-play"
                type="submit"
            >
                <img
                    className="register-popup__icon-play"
                    src={Play}
                    alt="icon-play"
                />
            </button>
        );

        const addPreview = () => {
            return (
                <div>
                    <div className="register-popup__video-preview">
                        {videoPreview}
                    </div>
                </div>
            );
        };

        const createSpinnerOrPreview = (loading: boolean) => {
            if (loading) {
                return <Spinner />
            } else {
                return (
                    <React.Fragment>
                        <form onSubmit={(e) => this.handleSubmit(e)}>
                            {addPreview()}
                        </form>
                    </React.Fragment>
                );
            }
        };

        return (
            <div className='register-popup__container'>
                <div className='register-popup__btn-close' onClick={onOpenPopup}></div>
                <div className='register-popup__wrapper'>
                    <div className='register-popup__columns'>
                        <div className="register-popup__item">
                            <div className="register-popup__label">
                                Имя{" "}
                            </div>
                            <div className="register-popup__item-container">
                                <ReadonlyText defaultValue={"Имя"} />
                            </div>
                        </div>

                        <div className="register-popup__item">
                            <div className="register-popup__label">
                                Фамилия{" "}
                            </div>
                            <div className="register-popup__item-container">
                                <ReadonlyText defaultValue={"Имя"} />
                            </div>
                        </div>
                    </div>


                    <div className="register-popup__item">
                        <div className="register-popup__label">
                            Имя пользователя{" "}
                        </div>

                        <div className="register-popup__item-container">
                            <ReadonlyText defaultValue={"Имя"} />
                        </div>
                    </div>
                    <div className="register-popup__item">
                        <div className="register-popup__label">
                            Мотивационное письмо{" "}
                        </div>
                        <div className="register-popup__item-container register-popup__textarea-letter">

                            <ReadonlyText

                                defaultValue="Инженер-программист по первому образованию, окончил курс «Визуальные коммуникации» в Британской высшей школе дизайна, курс «Новые художественные стратегии» в ICA.
                         Работал в «Афише», РБК и Apple. Cоздал вместе с партнёрами компанию аутсорс-разработки SmartHead (являюсь совладельцем и арт-директором), агентство RedKeds, школу ИКРА, портал Cossa. Сейчас руковожу digital-направлением в агентстве ONY. Преподаватель и дипломный руководитель в «Нетологии».
                          Жил и работал в США, делал мероприятия для банка и техно-вечеринки."
                            />
                        </div>

                    </div>

                    <div className='register-popup__columns'>
                        <div className="register-popup__item">
                            <div className="register-popup__label">
                                Видеопрезентация{" "}
                            </div>
                            <div>
                                {createSpinnerOrPreview(loading)}
                            </div>

                        </div>
                        <div className="register-popup__item">
                            <div className="register-popup__label">
                                Образование{" "}
                            </div>
                            <div className="register-popup__item-container register-popup__textarea-education">
                                <ReadonlyText
                                    defaultValue="Инженер-программист по первому образованию, окончил курс «Визуальные коммуникации» в Британской высшей школе дизайна, курс «Новые художественные стратегии» в ICA."
                                />
                            </div>
                        </div>

                    </div>

                    <div className="register-popup__item">
                        <div className="register-popup__label">
                            Сертификаты и дипломы{" "}
                        </div>
                        <div className="register-popup__item-container register-popup__input-diplomas">
                            <ReadonlyText
                                defaultValue=' 1. Сертификат Школы карьерного менеджмента  
                              1. Диплом о высшем образовании'
                            />
                        </div>
                    </div>


                    <div className="register-popup__item">
                        <div className="register-popup__label">
                            Профессиональные курсы, обучение, повышение квалификации{" "}
                        </div>
                        <div className="register-popup__item-container register-popup__textarea-courses">
                            <ReadonlyText
                                defaultValue="Инженер-программист по первому образованию, окончил курс «Визуальные коммуникации» в Британской высшей школе дизайна, курс «Новые художественные стратегии» в ICA. 
                                 Инженер-программист по первому образованию, окончил курс «Визуальные коммуникации» в Британской высшей школе дизайна, курс «Новые художественные стратегии» в ICA."
                            />
                        </div>
                    </div>

                    <div className="register-popup__item">
                        <div className="register-popup__label">
                            Количество лет / часов клиентской практики{" "}
                        </div>
                        <div className="register-popup__item-container register-popup__input-size">
                            <ReadonlyText
                                defaultValue='От 3 лет до 5 лет'
                            />
                        </div>
                    </div>


                    <div className="register-popup__item">
                        <div className="register-popup__label">
                            С какими странами вы работаете, помимо России?{" "}
                        </div>
                        <div className="register-popup__item-container register-popup__input-size">
                            <ReadonlyText
                                defaultValue='Турция, Украина, Западная Европа'
                            />
                        </div>
                    </div>

                    <div className="register-popup__item">
                        <div className="register-popup__label">
                            Выберите свой статус  {" "}
                            <span className="register-popup__star">
                                *
                            </span>
                        </div>
                        <div className="register-popup__item-container">
                            <span>Индивидуальный предприниматель</span>
                        </div>
                    </div>
                    <div className="register-popup__item">
                        <div className="register-popup__label">
                            Ссылка на соц.сеть / сайт {" "}
                        </div>

                        <div className="register-popup__item-container  register-popup__input-size">
                            <ReadonlyText
                                defaultValue='https://facebook.com'
                            />
                        </div>
                    </div>

                    <div className='register-popup__buttons'>
                        <div className='register-popup__button'><TertiaryButton text='Отклонить'></TertiaryButton></div>
                        <div className='register-popup__button'><TertiaryButton text='Подтвердить'></TertiaryButton></div>
                    </div>
                </div>
            </div>
        );
    };
}