import React, { Component } from 'react';
import './style.scss';

interface PanelProps {

}

export default class Panel extends Component<PanelProps, {}> {
  
    render() {
        return (
            <div className="common-panel">
                {this.props.children}
            </div>
        )
    }
  }