import React from "react";

import "./style.scss";
// import '../style.scss';

import MainView from "../Common/MainView";
import Breadcrumbs from "../../Components/Layout/Breadcrumbs";
import BreadcrumbsIndependentBlock from "../../Components/Layout/Breadcrumbs/IndependentBlock/BCIndependentBlock.tsx";

import TableWrapper from "../../Components/Layout/TableWrapper";
import SimpleLoader from "../../Components/Layout/Loaders/Resizeable";

import api from "../../Utils/personalAreaApi";

import PopupAddService from "../../Components/Layout/Popup/AddService";
import PopupWrapper from "../../Components/Layout/Popup/PopupWrapper";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import Notifier from "../../Components/Layout/Notifier/store";

import Toggle from "../../Components/Inputs/Toggle";

const servicesTableHeadData = { 
    id: "ID",
    service: "Наименование услуги",  
    description: "Описание",
    price: "Стоимость",
    discount: "Скидка",
    // addAction: "Опубликовать", 
};


interface ServicesSetupPageState {
    services: any[];
    loading: boolean;
    tags: any[];
    popupOpened: boolean;
}

export default class ServicesSetupPage extends React.Component<{}, ServicesSetupPageState> {
    
    constructor(props: any)
    {
        super(props);

        this.state = {
            services: [],
            loading: true,
            tags: [],
            popupOpened: false,
        }
    }

    async publishService(serviceId: number, statusId: number)
    {
        const updateResult = await api.editService(serviceId, {
            statusId: statusId,
        });

        const message = updateResult.message;
        if(updateResult.success)
        {
            Notifier.success("Услуга опубликована!");
        }
        else
        {
            Notifier.error(message);
        }
        await this.getServices();
    }

    formatTableData (data: any) {

        const newData = data.map((element:any) => {
    
            return {
                id: element.id,
                service: element.name,
                description: element.description,
                price: `${element.price} Coins`,
                discount: `${element.discountSize} ${element.discountUnitId === 1 ? "%" : "Coins"}`,
                // addAction: <div style={{
                //     display: "flex",
                //     justifyContent: "center",
                //     alignItems: "center"
                // }}>
                //     <Toggle onChange={(checked) => this.publishService(element.id, Number(checked) + 1)} checked={element.statusId === 2}/>
                // </div>,
            };
        });
    
        return newData;
    
    };

    async getServices()
    {
        const getServicesResult = await api.getServices();
        const extra = await api.getProfileExtra(["tags"]);

        let services: any[] = [];

        if(getServicesResult.success !== false)
        {
            services = getServicesResult.services;
        }
        
        const tags: any[] = extra.tags.map((t: any) => {
            const tag = t;
            tag.details = JSON.parse(t.details);
            return tag;
        });

        this.setState({
            loading: false,
            services: services,
            tags: tags,
        })
    }

    async componentDidMount() {
        await this.getServices();
    }

    async createService(service: any) {
        const createResult = await api.createService(service);

        const message = createResult.message;
        if(createResult.success)
        {
            Notifier.success(message);
        }
        else
        {
            Notifier.error(message);
        }
        await this.getServices();
    }

    openPopup(open: boolean = true)
    {
        this.setState(
            {
                popupOpened: open,
            }
        )
    }
    
    render() {
        return (
            <MainView>
                <Breadcrumbs breadcrumbsButtons={[]}>
                    <BreadcrumbsIndependentBlock>
                        <div>
                        Здесь вы можете создавать или редактировать услуги, предоставляемые вами или вашими модераторами. Модераторы смогут выбирать услуги из списка в своем личном кабинете.
                        </div>
                    </BreadcrumbsIndependentBlock>
                </Breadcrumbs>
                {
                    this.state.loading ?
                    <div style={{width: "100%", height: "500px"}}>
                        <SimpleLoader></SimpleLoader>
                    </div>
                    :
                    <>
                        {
                            <PopupWrapper active={this.state.popupOpened} onClose={() => this.openPopup(false)} >
                                <PopupAddService onSave={(service: any) => this.createService(service) } tags={this.state.tags}/>
                            </PopupWrapper>
                        }
                        <TableWrapper headerData={servicesTableHeadData} bodyData={this.formatTableData(this.state.services)}/>
                        <div style={{width: "200px", height: "50px", marginTop: "20px"}}>
                            <PrimaryButton onClick={() => this.openPopup(true)} text="Добавить услугу"/>
                        </div>
                    </>
                }
            </MainView>
        );
    }
}
