import React from "react";
import "./style.scss";

import MainView from "../Common/MainView";
import Breadcrumbs from "../../Components/Layout/Breadcrumbs";
import { BreadcrumbsItem } from "../../Components/Layout/Breadcrumbs/types";

import EmployeesSection from "./Sections/employees";
import RolesSection from "./Sections/roles";

const breadcrumbsButtons: BreadcrumbsItem[] = [
    {
        id: 1,
        name: "Сотрудники",
        path: "/employees/?section=users",
    },
    {
        id: 2,
        name: "Настройка ролей",
        path: "/employees/?section=roles",
    },
];

export default class EmployeesPage extends React.Component {
    render() {

        const queryParams = new URLSearchParams(window.location.search);
        let section = "users";

        const querySectionParam = queryParams.get("section");
        switch (querySectionParam) {
            case "users":
                section = "users";
                break;
            case "roles":
                section = "roles";
                break;
        }

        return (
            <MainView>
                <Breadcrumbs
                    breadcrumbsButtons={breadcrumbsButtons}
                ></Breadcrumbs>
                {(() => {
                    switch (section) {
                        case "roles":
                            return <RolesSection/>
                        default:
                            return <EmployeesSection/>;
                    }
                })()}
            </MainView>
        );
    }
}
