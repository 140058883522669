import React from 'react';
import './style.scss';
import "../common.scss";
import Badge from '../../../../Media/Common/triangle-green.svg';
import { outsideClickHandler } from "../../../../Utils";

import { DropdownItem } from "../types";

interface SingleSelectProps {
    chosenValue?: number; 
    disabled?: boolean
    values: DropdownItem[],
    placeholder?: string,
    onChange?: (chosenValue: number) => void;
}

interface SingleSelectState {
    chosenValue: number,
    dropdownOpened: boolean;
}

class SingleSelect extends React.Component<SingleSelectProps, SingleSelectState> {

    componentDidUpdate(prevProps: SingleSelectProps, prevState: SingleSelectState) {
        if(prevState.chosenValue !== this.state.chosenValue)
        {
            if (this.props.onChange) {
                this.props.onChange(this.state.chosenValue)
            }
        }
    }

    refControls: any = null;

    mockElement: DropdownItem = {
        id: 0,
        label: "Не выбрано",
        data: {}
    }
    node: any;

    constructor(props: any) {
        super(props);
        const chosenValue = this.props.values.find(e => e.id === this.props.chosenValue);
        this.state = {
            chosenValue: chosenValue?.id || 0,
            dropdownOpened: false,
        };
        this.refControls = React.createRef();
        if(this.props.placeholder)
            this.mockElement.label =  this.props.placeholder;
    }

    componentDidMount() {
        const s = this.state;

        outsideClickHandler({
            bindObj: this,
            body: document,
            ref: this.refControls,
            action: this.hideAllDds,
            type: "click",
            condition: !(s.dropdownOpened)
        })
    }

    hideAllDds() {
        this.setState(
            {
                dropdownOpened: false,
            }
        )
    }

    openCloseDropdown(openClose?: boolean) {
        const dropdownOpened = this.state.dropdownOpened;
        if (!this.props.disabled) {
            this.setState({
                dropdownOpened: openClose !== undefined ? openClose : !dropdownOpened,
            })
        }
    }

    elementChosen(element: DropdownItem) {
        return this.state.chosenValue === element.id;
    }


    chooseElement(element: DropdownItem) {
        if (element.id !== this.state.chosenValue) {
            this.setState({ 
                chosenValue: element.id,
                dropdownOpened: false, 
            });
        }
    }

    getElementName(element: DropdownItem) {
        const limit = 35;
        let name = element.label;

        if (name.length > limit) {
            name = name.slice(0, limit) + "...";
        }
        return name;
    }

    render() {
        const s = this.state;
        const values = this.props.values;
        const disabled = this.props.disabled || false;
        const dropdownOpened = this.state.dropdownOpened;
        return (
            <div ref={this.refControls} className="dropdown-single">
                <div className={`dropdown-header ${s.dropdownOpened ? "opened" : ""} ${this.props.disabled ? "disabled" : ""}`}>
                    <div className='dropdown-single-header-chosen-elements-area'>
                        {
                            this.state.chosenValue > 0 ?
                                <div className={`dropdown-single-header-element`}>
                                    <div className='dropdown-single-header-element-content'>
                                        <span>{this.getElementName(values.find(e => e.id === this.state.chosenValue) || this.mockElement)}</span>
                                    </div>
                                </div>
                                :
                                <div className={`dropdown-single-header-element placeholder`} key={0}>
                                    <div className='dropdown-single-header-element-content'>
                                        <span className='dropdown-single-default-option'>{this.mockElement.label}</span>
                                    </div>
                                </div>
                        }
                    </div>
                    <div className='dropdown-single-header-badge-area' onClick={() => this.openCloseDropdown()} >
                        <img alt="" className={`dropdown-single-badge ${dropdownOpened ? "opened" : ""}`} src={Badge}></img>
                    </div>
                </div>

                {
                    !disabled && dropdownOpened &&
                    <div className='dropdown-list-wrapper'>
                        <div className='dropdown-list'>
                            {values.map((element: any, i: number) =>
                                <div className={`dropdown-list-element ${this.elementChosen(element) ? "selected" : ""}`} key={i}>
                                    <div onClick={() => this.chooseElement(element)} key={i} >
                                        <span className='option-select'>{element.label}</span>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                }

            </div>
        )
    }
}

export default SingleSelect;
