import React from "react";
import "./style.scss";
import AddRemoveButton from "../../Buttons/AddRemoveButton";
import { outsideClickHandler } from "../../../Utils";

interface SubTagsProps {
    name?: string;
    values?: any[];
    chosenValues?: number[];
    color?: string;
    onChange?: (chosenValues: number[]) => void;
}

interface SubTagsState {
    opened: boolean;
    chosenValues: number[];
}

class SubTags extends React.Component<SubTagsProps, SubTagsState> {
    refControls: any = null;

    constructor(props: SubTagsProps) {
        super(props);
        this.state = {
            opened: false,
            chosenValues: props.chosenValues || [],
        };
        this.refControls = React.createRef();
    }

    componentDidUpdate(prevProps: SubTagsProps, prevState: SubTagsState) {
        if (
            JSON.stringify(prevState.chosenValues.sort()) !==
            JSON.stringify(this.state.chosenValues.sort())
        ) {
            if (this.props.onChange) {
                this.props.onChange(this.state.chosenValues);
            }
        }
    }

    componentDidMount() {
        const s = this.state;

        outsideClickHandler({
            bindObj: this,
            body: document,
            ref: this.refControls,
            action: (() => this.openCloseList(false)),
            type: "click",
            condition: !s.opened,
        });
    }

    openCloseList(forcedValue?: boolean) {
        const opened = this.state.opened;

        this.setState({
            opened: forcedValue === undefined ? !opened : forcedValue,
        })
    }

    valueSelected(id: number)
    {
        return this.state.chosenValues.includes(id)
    }

    selectValue(id: number)
    {
        const values = [...this.state.chosenValues];

        if(values.includes(id))
        {
            const index = values.indexOf(id);
            if (index !== -1) {
                values.splice(index, 1);
            }
        }
        else
        {
            values.push(id);
        }

        this.setState({
            chosenValues: values,
        })
    }

    render() {
        const values = this.props.values || [];
        return (
            <div ref={this.refControls} className="subtags-wrapper" style={{backgroundColor: this.props.color || "white"}}>
                <div className="subtags-header">
                    <div className="subtags-header-control">
                        <span className="subtags-header-text">
                            {this.props.name}
                        </span>
                        <AddRemoveButton 
                            add={!this.state.opened}
                            onClick={() => this.openCloseList()}
                        />
                    </div>
                </div>
                {
                    this.state.opened && 
                    <div className="subtags-list">
                        {
                            values.map((element: any) => (
                                <div 
                                    className={`subtags-list-item ${this.valueSelected(element.id) ? "selected" : ""}`}
                                    onClick={() => this.selectValue(element.id)}
                                >
                                    {element.title}
                                </div>
                            ))
                        }
                    </div>}
            </div>
        );
    }
}

interface TagsProps {
    disabled?: boolean;
    values?: any[];
    chosenValues?: number[];
    onChange?: (chosenValues: any[]) => void;
}

interface TagsState {
    chosenValues: number[];
    tags: any[];
    tagsChosenValues: any;
}

class Tags extends React.Component<TagsProps, TagsState> {
    refControls: any = null;

    constructor(props: TagsProps) {
        super(props);
        const chosenValues = props.chosenValues || [];

        this.state = {
            chosenValues: chosenValues,
            tags: this.props.values || [],
            tagsChosenValues: {},
        };

        this.refControls = React.createRef();
    }

    componentDidUpdate(prevProps: TagsProps, prevState: TagsState) {
        if (
            JSON.stringify(prevState.chosenValues.sort()) !==
            JSON.stringify(this.state.chosenValues.sort())
        ) {
            if (this.props.onChange) {
                this.props.onChange(this.state.chosenValues);
            }
        }
    }

    getSubtagValues(values: number[], tagId: number)
    {
        const tagsChosenValues = Object.assign({}, this.state.tagsChosenValues);

        tagsChosenValues[String(tagId)] = values;

        let chosenValues: number[] = [];

        for(const key in tagsChosenValues)
        {
            const array = tagsChosenValues[key];

            for(const val of array)
            {
                if(!chosenValues.includes(val))
                {
                    chosenValues.push(val);
                }
            }
        }

        this.setState({
            tagsChosenValues: tagsChosenValues,
            chosenValues: chosenValues,
        })
    }

    render() {
        const tags = this.state.tags;

        return (
            <div ref={this.refControls} className="tags-wrapper">
                {tags && 
                    tags.map((tag: any) => (
                        <SubTags
                            name={tag.title}
                            color={tag.color || tag.details.color}
                            values={tag.subTags}
                            chosenValues={this.state.chosenValues}
                            onChange={(values: number[]) => this.getSubtagValues(values, tag.id)}
                        />
                    ))
                }
            </div>
        );
    }
}

export default Tags;
