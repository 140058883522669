import React from 'react';
import './style.scss';
import Input from '../../Components/Inputs/Input';
import IcoSearch from '../../Media/Common/magnifier-green.svg';
import PrimaryButton from '../../Components/Buttons/PrimaryButton';
import TableWrapper from '../../Components/Layout/TableWrapper';
import MainView from '../Common/MainView';
import Breadcrumbs from '../../Components/Layout/Breadcrumbs';
import BreadcrumbsIndependentBlock from '../../Components/Layout/Breadcrumbs/IndependentBlock/BCIndependentBlock.tsx';
import RegReqDetails from '../../Components/Layout/Popup/RegReqDetails';
import PopupWrapper from "../../Components/Layout/Popup/PopupWrapper";
import { registerTableBodyData, registerTableHeadData } from '../../Data/register-table';
interface RegisterState {
    popupActive: boolean;
}
export default class Register extends React.Component<{}, RegisterState> {

    refControls: any = null;

    constructor(props: {}) {
        super(props);
        this.state = {
            popupActive: false,
        };
        this.refControls = React.createRef();
    }

    setStatusColor = (status: string) => {
        switch (status) {
            case "Новая":
                return "#33A5A1";
            case "Отменена":
                return "#BDBDBD";
            default:
                return "white"
        }
    };

    changePopupActive = () => {
        console.log("I am popup: " + this.state.popupActive)
        this.setState((state) => {
            return {
                popupActive: !state.popupActive
            }
        })
    };

    firstProcessData(data: any) {
        const newData = data.map((element: any) => {
            const color = this.setStatusColor(element.status);
            return {
                appNumber: <div style={{ textAlign: "start" }} key={element.id} className='register__table-name'>{String(element.appNumber)}</div>,
                name: <div style={{ textAlign: "center" }} className='register__table-name'>{String(element.name)}</div>,
                status: <div style={{ display: "flex", justifyContent: "center", backgroundColor: color }} className='register__table-status-btn' >{String(element.status)}</div>,
                data: <div style={{ textAlign: "center" }} className='register__table-data'>{String(element.data)}</div>,
                details:
                    <div style={{ display: "flex", justifyContent: "center" }} key={element.id}>
                        <div className="register__table-button " >
                            <PrimaryButton disabled={false} text={"Посмотреть"} onClick={this.changePopupActive} />
                        </div>
                    </div>
            };
        });
        return newData;
    };

    render() {
        return (
            <MainView>
                <Breadcrumbs >
                    <BreadcrumbsIndependentBlock>
                        Здесь отображаются новые заявки от пользователей.
                        <br /> Ты можешь одобрить, перенести или отклонить заявку.
                    </BreadcrumbsIndependentBlock>
                </Breadcrumbs>

                {
                    <PopupWrapper active={this.state.popupActive} >
                        <RegReqDetails onClose={() => this.changePopupActive()}/>
                    </PopupWrapper>
                }

                <div className='register__wrapper'>
                    <div className='register__search-wrapper'>
                        <form className='register__form-search'>
                            <div className='register__input-search' >
                                <Input badge={IcoSearch} type="text" placeholder='Ищите по Наименование#, name, вид партнёра' />     </div>
                            <button className='register__button-reset' type="reset" >Сбросить</button>
                        </form>
                    </div>

                    <TableWrapper headerData={registerTableHeadData} bodyData={this.firstProcessData(registerTableBodyData)} numberGreenColumn={[4]} />
                </div>
            </MainView>
        );
    };
}
