import React from 'react';
import './style.scss';

interface AvatarProps {
    size?: number;
    src?: string | null;
}

const blankAvatar = "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y&s=300";

export default class Avatar extends React.Component<AvatarProps, {}> {

    render() {
        const size = `${this.props.size ? this.props.size : 100}px`;
        const src = this.props.src || blankAvatar;
        return <img 
            style={
                {
                    width: size,
                    height: size,
                }
            }
            className='avatar' 
            src={src} 
            alt="avatar"
        />
    };
}