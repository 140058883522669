import React from "react";
import "./style.scss";
import "./partners.scss";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import MainView from "../Common/MainView";
import Breadcrumbs from "../../Components/Layout/Breadcrumbs";
import BreadcrumbsIndependentBlock from "../../Components/Layout/Breadcrumbs/IndependentBlock/BCIndependentBlock.tsx";

import TableWrapper from "../../Components/Layout/TableWrapper";
import SimpleLoader from "../../Components/Layout/Loaders/Resizeable";
import PartnersDetails from "../../Components/Layout/Popup/PartnersDetails";
import api from "../../Utils/personalAreaApi";
import TitleTextBlock from "../../Components/Layout/TextBlocks/TitleTextBlock";

interface PartnersViewProps {
    onChange?: (filter: boolean) => void;
    onOpenPopup: any;
}
interface PartnersViewState {
    popupActive: boolean;
    partners: any[];
    legalStatuses: any[];
    legalTypes: any[];
    loading: boolean;
    currentPartner: any;
    totalPartners: number;
}

const partnersTableHeadData = {
    name: "Наименование",
    email: "Email",
    type: "Вид партнёра",
    commission: "Комиссия",
    details: "Детали",
};
const firstTableBodyData = [
    {
        id: 0,
        name: "ООО «Яндекс»",
        email: "Ivanov@yandex.ru",
        type: "Юридическое лицо",
    },
    {
        id: 1,
        name: "ООО «Яндекс»",
        email: "Ivanov@yandex.ru",
        type: "Юридическое лицо",
    },
    {
        id: 2,
        name: "Иванов Иван Николаевич",
        email: "Ivanov@yandex.ru",
        type: "Физическое лицо",
    },
    {
        id: 3,
        name: "ООО «Яндекс»",
        email: "Ivanov@yandex.ru",
        type: "Юридическое лицо",
    },
];

export default class PartnersView extends React.Component<
    PartnersViewProps,
    PartnersViewState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            popupActive: false,
            partners: [],
            legalStatuses: [],
            legalTypes: [],
            loading: true,
            currentPartner: {},
            totalPartners: 0,
        };
        // this.refControls = React.createRef();
    }

    // componentDidUpdate() {
    //     if (this.props.onChange) {
    //         this.props.onChange(this.state.isFilterActive)
    //     }
    // }

    // refControls: any = null;

    async componentDidMount() {
        this.setState({ loading: true });

        await this.getProfileExtra();
        await this.getPartners();
        
        this.setState({
            loading: false,
        });
    }

    async getProfileExtra() {
        const result = await api.getProfileExtra(["legalStatuses", "legalTypes"]);

        if (result.success !== false) {
            this.setState({
                legalStatuses: result.legalStatuses,
                legalTypes: result.legalTypes,
            });
        }
    }

    async getPartners(offset: number = 0) {
        const getPartnerResult = await api.getPartners({}, 10, offset);

        if (getPartnerResult.success === false) {
        } else {
            if (getPartnerResult.total >= 0) {
                this.setState({ partners: getPartnerResult.partners, totalPartners: getPartnerResult.total});
            }
        }
    }

    async editPartner(body: any)
    {
        const currentPartner = this.state.currentPartner;

        if(Object.keys(currentPartner).length > 0)
        {
            const id = this.state.currentPartner.id;

        }

    }

    changePopupActive = () => {
        this.setState((state) => {
            return {
                popupActive: !state.popupActive,
            };
        });
    };

    processPartnersData(data: any) {
        const newData = data.map((element: any) => {
            return {
                name: (
                    <div
                        style={{ textAlign: "start" }}
                        key={element.id}
                        className="partners__table-name"
                    >
                        {String(element.name)}
                    </div>
                ),
                email: (
                    <div
                        style={{ textAlign: "start" }}
                        className="partners__table-email"
                    >
                        {String(element.partnerEmail)}
                    </div>
                ),
                type: (
                    <div
                        style={{ textAlign: "start" }}
                        className="partners__table-type"
                    >
                        {this.state.legalStatuses.find(e => e.id === element.legalStatusId).name}
                        <br/>
                        {`(${this.state.legalTypes.find(e => e.id === element.legalTypeId).name})`}
                    </div>
                ),
                commission: (
                    <div
                        style={{ textAlign: "start" }}
                        className="partners__table-type"
                    >
                        {`${String(element.commission)} %`}
                    </div>
                ),
                details: (
                    <div
                        style={{ textAlign: "center" }}
                        className="partners__table-button"
                    >
                        <PrimaryButton
                            disabled={false}
                            text={"Посмотреть"}
                            onClick={() => {
                                this.changePopupActive();
                                this.setState({
                                    currentPartner: element,
                                })
                            }}
                        />
                    </div>
                ),
            };
        });
        return newData;
    }

    render() {
        return (
            <>
                {this.state.loading ? (
                    <div style={{ width: "100%", height: "500px" }}>
                        <SimpleLoader></SimpleLoader>
                    </div>
                ) : (
                    <>
                        <TitleTextBlock>
                            Здесь находятся все партнёры платформы.
                            <br /> Вы можете найти нужного партнёра, просмотреть
                            детали и создать нового партнёра.
                        </TitleTextBlock>
                        {this.state.popupActive === true ? (
                            <div className="overlay"></div>
                        ) : (
                            ""
                        )}
                        {this.state.popupActive === true ? (
                            <PartnersDetails
                                active={this.state.popupActive}
                                onClose={this.changePopupActive}
                                partner={this.state.currentPartner}
                                onEdit={(data: any) => this.editPartner(data)}
                            />
                        ) : (
                            ""
                        )}
                        <TableWrapper
                            headerData={partnersTableHeadData}
                            bodyData={this.processPartnersData(
                                this.state.partners
                            )}
                            total={this.state.totalPartners}
                            rowsCount={10}
                            onChangePage={(offset: number) => this.getPartners(offset)}
                        />
                    </>
                )}
            </>
        );
    }
}
