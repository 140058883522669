import React, { Component } from "react";
import "./style.scss";

import PopupWrapper, { PopupWrapperProps } from "../PopupWrapper";

import CancelIcon from "../../../../Media/Common/cancel.svg";
import { getFullName, mapArrayToDropdownElements, standartTimeFormat } from "../../../../Utils";
import moment from "moment";
import PrimaryButton from "../../../Buttons/PrimaryButton";
import Textarea from "../../../Inputs/Textarea";
import Calendar from "../../../Inputs/Calendar";
import SingleSelect from "../../../Dropdowns/Select/Single";

interface FinishPopupProps extends PopupWrapperProps {
    serviceRequest: any;
    reasons: any[];
    onFinish: Function;
}

interface FinishPopupState {
    comment: string | null;
    result: string;
}

const results = [
    {
        id: 1,
        name: "Консультация завершена",
        key: "closeRegular",
    },
    {
        id: 2,
        name: "Консультация не состоялась (возврат денег)",
        key: "closeNonRegularRefund",
    },
    {
        id: 3,
        name: "Консультация не состоялась (без возврата)",
        key: "closeNonRegularNoRefund",
    },
]

export default class FinishRequestPopup extends Component<FinishPopupProps, FinishPopupState> {

    constructor(props: FinishPopupProps)
    {
        super(props);

        this.state = {
            comment: null,
            result: "closeRegular",
        }
    }

    setComment(value: string) {
        let comment: any = value;
        if(value.length === 0)
        {
            comment = null;
        }

        this.setState({
            comment: comment,
        })
    }

    setResult(value: number) {
        let result: string = results.find(r => r.id === value)?.key || "closeRegular";


        this.setState({
            result: result,
        })
    }



    render() {

        const request = this.props.serviceRequest;
        let serviceName = request.service.name;
        let username = getFullName(request.profile);

        return (
            <PopupWrapper
                active={this.props.active || false}
                onClose={() => this.props.onClose ? this.props.onClose() : () => {}}
            >
                <div className="popup-title-wrapper">
                    <div>Завершение заявки</div>
                    <img alt="+" src={CancelIcon} />
                </div>
                <div className="sr-popup-grid">
                    {/* Left */}
                    <div className="sr-popup-grid-column-left">
                        <div className="sr-popup-grid-item">Услуга:</div>
                        <div className="sr-popup-grid-item">Пользователь:</div>
                        <div className="sr-popup-grid-item">Результат:</div>
                    </div>
                    {/* Right */}
                    <div className="sr-popup-grid-column-right">
                        <div className="sr-popup-grid-item">{serviceName}</div>
                        <div className="sr-popup-grid-item">{username}</div>
                        <div className="sr-popup-calendar"><SingleSelect onChange={(value) => this.setResult(value)} values={mapArrayToDropdownElements(results)} placeholder={"Не выбрано"}/></div>
                    </div>
                </div>
                <div className="sr-popup-text-area">
                    <Textarea onChangeValue={(value) => this.setComment(value)} placeholder="Добавить сообщение" />
                </div>
                <div className="sr-popup-buttons">
                    <div className="sr-popup-button">
                        <PrimaryButton onClick={() => this.props.onFinish({
                            comment: this.state.comment,
                            status: this.state.result,
                        })} text="Завершить заявку"></PrimaryButton>
                    </div>
                </div>
            </PopupWrapper>
        );
    }
}
