import api from "./api";

async function apiRequest(
    type: string,
    method: string,
    body: any,
    headers: any,
    additionalOptions: any
) {
    const requestOptions = Object.assign({
        withCredentials: true,
    }, additionalOptions);

    const url = `${process.env.REACT_APP_PAAPI_HOST}/pa-api${method}`;
    const result = await api.request(type, url, body, headers, requestOptions);

    if (result._serviceInfo.status >= 400) {
        switch (result._serviceInfo.status) {
            case 403:
                // console.log("FORBIDDEN!"); //TODO: Что-то сделать нужно с этим
                break;
            case 401:
                handleUnauthenticated();
                break;
            default:
                // console.log(result);
                break;
        }
    }

    return result;
}

function handleUnauthenticated() {
    if (process.env.REACT_APP_NODE_ENV === "production") {
        window.open(
            process.env.REACT_APP_AUTH_PAGE +
                "/?redirect=" +
                process.env.REACT_APP_PA_HOST,
            "_self"
        ); //TODO: Заменить на текущий URL
    } else {
        window.open("/unauth", "_self");
    }
}

async function getSidebar() {
    return await apiRequest("get", "/structure/sidebar", {}, {}, {});
}

async function getProfile() {
    return await apiRequest("get", "/profile", {}, {}, {});
}

async function getProfileShort() {
    return await apiRequest("get", "/profile/short", {}, {}, {});
}

async function getProfileExtra(only?: string[]) {
    let qs = "";

    if (only) {
        const params = new URLSearchParams(only.map((item) => ["only", item]));

        qs = `?${params.toString()}`;
    }

    return await apiRequest("get", `/extra/profile/${qs}`, {}, {}, {});
}

async function getPageStructure(page_name: string) {
    return await apiRequest("get", `/structure/page/${page_name}`, {}, {}, {});
}

async function editProfile(newProfile: any) {
    return await apiRequest("put", `/profile`, newProfile, {}, {});
}

async function getEmployees(limit: number = 10, offset: number = 0) {
    return await apiRequest(
        "get",
        `/employees?limit=${limit}&offset=${offset}`,
        {},
        {},
        {}
    );
}

async function getUsers(limit: number = 10, offset: number = 0) {
    return await apiRequest(
        "get",
        `/users?limit=${limit}&offset=${offset}`,
        {},
        {},
        {}
    );
}

async function createEmployee(employee: any) {
    return await apiRequest("post", `/employees`, employee, {}, {});
}

async function editEmployeeStatus(id: number, statusBody: any) {
    return await apiRequest("put", `/employees/${id}`, statusBody, {}, {});
}

async function getPartnerRoles() {
    return await apiRequest("get", `/roles`, {}, {}, {});
}

async function getRolesTypes() {
    return await apiRequest("get", `/extra/roles-types`, {}, {}, {});
}

async function getRolesFunctions(typeId: number) {
    return await apiRequest(
        "get",
        `/extra/roles-functions/${typeId}`,
        {},
        {},
        {}
    );
}

async function getRole(id: number) {
    return await apiRequest("get", `/roles/${id}`, {}, {}, {});
}

async function createRole(roleBody: any) {
    return await apiRequest("post", `/roles`, roleBody, {}, {});
}

async function editRole(id: number, roleBody: any) {
    return await apiRequest("put", `/roles/${id}`, roleBody, {}, {});
}

async function grantRoles(userId: number, rolesIds: number[]) {
    const body = {
        userId: userId,
        rolesIds: rolesIds,
    };
    return await apiRequest("post", `/extra/grant-roles`, body, {}, {});
}

async function getServices() {
    return await apiRequest("get", `/services`, {}, {}, {});
}

async function createService(serviceBody: any) {
    return await apiRequest("post", `/services`, serviceBody, {}, {});
}

async function editService(serviceId: number, serviceBody: any) {
    return await apiRequest(
        "put",
        `/services/${serviceId}`,
        serviceBody,
        {},
        {}
    );
}

async function assignService(serviceId: number, assign: boolean) {
    return await apiRequest(
        "post",
        `/services/assign?assign=${String(assign)}`,
        { serviceId: serviceId },
        {},
        {}
    );
}

async function uploadAvatar(file: any) {
    const formData = new FormData();

    formData.append("avatar", file);

    return await apiRequest("put", `/profile/avatar`, formData, {}, {});
}

async function uploadVideo(file: any, onProgress: Function) {
    const formData = new FormData();

    formData.append("video", file);

    const options = {
        onUploadProgress: (progressEvent: any) => {
            if(onProgress)
            {
                let progress = Math.round((progressEvent.loaded / progressEvent.total) * 100 - Math.random() * 3);
                onProgress(progress);
            }
        },
    };

    return await apiRequest("put", `/profile/video`, formData, {}, options);
}

async function getVideo(videoId: any) {
    return await apiRequest("get", `/profile/video/${videoId}`, {}, {}, {});
}

async function deleteVideo() {
    return await apiRequest("delete", `/profile/video`, {}, {}, {});
}

async function getProfileServices() {
    return await apiRequest("get", `/services/profile`, {}, {}, {});
}

async function getSchedule(from: number, to: number) {
    return await apiRequest("get", `/schedules?from=${from}&to=${to}`, {}, {}, {});
}

async function editSchedule(body: any) {
    return await apiRequest("put", `/schedules`, body, {}, {});
}

async function getMyRequests(body: any, limit: number = 100, offset: number = 0, type: string = "new") {
    return await apiRequest("get", `/services-requests/my-requests/?limit=${limit}&offset=${offset}&type=${type}`, {}, {}, {});
}

async function getRequestedRequests(body: any, limit: number = 100, offset: number = 0, type: string = "new") {
    return await apiRequest("get", `/services-requests/requested/?limit=${limit}&offset=${offset}&type=${type}`, {}, {}, {});
}

async function editRequest(id: number, body: any,) {
    return await apiRequest("put", `/services-requests/${id}`, body, {}, {});
}

async function getServicesRequestsExtra() {
    return await apiRequest("get", `/extra/services-requests`, {}, {}, {});
}

async function getPartners(filter: any, limit: number = 10, offset: number = 0) {
    return await apiRequest("post", `/partners/filter?limit=${limit}&offset=${offset}`, filter, {}, {});
}

async function createPartner(data: any) {
    return await apiRequest("post", `/partners`, data, {}, {});
}

async function filterOperations(filter: any, limit: number = 10, offset: number = 0) {
    return await apiRequest("post", `/operations?limit=${limit}&offset=${offset}`, filter, {}, {});
}

async function getOperationsExtra() {
    return await apiRequest("get", `/operations`, {}, {}, {});
}

async function getNotificationsUnit() {
    return await apiRequest("get", `/notifications/unit`, {}, {}, {});
}

async function editNotificationsUnit(body: any) {
    return await apiRequest("put", `/notifications/unit`, body, {}, {});
}

async function getNotificationsExtra() {
    return await apiRequest("get", `/extra/notifications`, {}, {}, {});
}
// async function editPartner(id: number, body: any) {
//     return await apiRequest("put", `/partners/${id}`, body, {}, {});
// }

const paApiHelper = {
    getSidebar,
    getProfile,
    getProfileShort,
    getProfileExtra,
    getPageStructure,
    editProfile,
    getEmployees,
    createEmployee,
    editEmployeeStatus,
    getPartnerRoles,
    grantRoles,
    getRolesTypes,
    getRolesFunctions,
    getRole,
    createRole,
    editRole,
    getServices,
    getProfileServices,
    createService,
    editService,
    assignService,
    uploadAvatar,
    uploadVideo,
    getVideo,
    deleteVideo,
    getSchedule,
    editSchedule,
    getMyRequests,
    getRequestedRequests,
    getServicesRequestsExtra,
    editRequest,
    getPartners,
    createPartner,
    filterOperations,
    getOperationsExtra,
    getNotificationsUnit,
    getNotificationsExtra,
    editNotificationsUnit,
    getUsers,
};

export default paApiHelper;
