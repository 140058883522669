import React from "react";

import "./roles.scss";

import TableWrapper, {
    HeaderGreenProvider,
} from "../../../Components/Layout/TableWrapper";

import TitleTextBlock from "../../../Components/Layout/TextBlocks/TitleTextBlock";
import PrimaryButton from "../../../Components/Buttons/PrimaryButton";
import Secondary1Button from "../../../Components/Buttons/Secondary1Button";
import Checkbox from "../../../Components/Inputs/Checkbox";
import Input from "../../../Components/Inputs/Input";

import SingleSelect from "../../../Components/Dropdowns/Select/Single";
import paApiHelper from "../../../Utils/personalAreaApi";

import Notifier from "../../../Components/Layout/Notifier/store";

import SimpleLoader from "../../../Components/Layout/Loaders/Resizeable";

import { mapArrayToDropdownElements } from "../../../Utils";

const rolesHeader = {
    id: "ID",
    name: "Название роли",
    description: "Описание",
    type: "Тип",
    action: "Изменение"
};

const functionsHeader = {
    name: "Название функции",
    description: "Описание",
    action: "Доступ"
};


const processData = (data: any, roleTypes: any[], editHandler: (id: number) => void) => {
    const newData = data.map((element: any) => {
        return {
            id: element.id,
            name: element.name,
            description: element.description,
            type: roleTypes.find(t => t.id === element.typeId).name,
            action: <PrimaryButton onClick={() => editHandler(element.id)} text="Настроить"></PrimaryButton>
        };
    });

    return newData;
};

const processFunctionsData = (data: any, accessValues: any[], edit: (id: number, hasAccess: boolean) => void) => {

    data = data.filter((e: any) => e.typeId === undefined || e.typeId !== 4)

    const newData = data.map((element: any) => {

        let hasAccess = false;

        const func = accessValues.find(v => v.id === element.id);

        if(func)
        {
            hasAccess = func.hasAccess;
        }

        return {
            name: element.name,
            description: element.description,
            action: <Checkbox checked={hasAccess} onChange={(checked) => edit(element.id, checked)}></Checkbox>
        };
    });

    return newData;
};

interface RolesSectionState {
    addScreenOpened: boolean;
    roles: any[];
    rolesTypes: any[];
    functions: any[];
    rolesLoading: boolean;
    totalRoles: number;
    roleToEdit: any;
    roleToEditFunctions: any[];
}

const roleRequiredFields = ["name", "description", "typeId"];


export default class RolesSection extends React.Component<{}, RolesSectionState> {

    constructor(props: any)
    {
        super(props);

        this.state = {
            addScreenOpened: false,
            totalRoles: 0,
            rolesLoading: true,
            roles: [],
            rolesTypes: [],
            functions: [],
            roleToEdit: {
            },
            roleToEditFunctions: [],
        }
    }

    async getExtra() {
        const rolesResult = await paApiHelper.getPartnerRoles();
        const rolesTypesResult = await paApiHelper.getRolesTypes();

        const res = [
            rolesResult.success,
            rolesTypesResult.success,
        ]

        if (res.some(r => r === false)) {
            Notifier.error("Ошибка отображения страницы");
        }
        else
        {
            this.setState({
                roles: rolesResult.roles,
                rolesTypes: rolesTypesResult.rolesTypes,
            });
        }
    }

    async openCloseAddScreen(force?: boolean)
    {
        const opened = force === undefined ? !this.state.addScreenOpened : force;

        const newState: any = {
            addScreenOpened: opened
        }

        if(!opened)
        {
            newState.roleToEditFunctions = [];
            newState.roleToEdit = {};
            await this.getRoles()
        }

        this.setState(
            newState
        );
    }

    async getRoles()
    {
        const result = await paApiHelper.getPartnerRoles();
        if(result.success !== false)
        {
            this.setState({
                roles: result.roles,
                totalRoles: result.total,
            })
        }
    }

    async editRole(id: number)
    {
        const result = await paApiHelper.getRole(id);


        if(result.success !== false)
        {

            await this.setRoleType(result.typeId);
            const functionsResult = await paApiHelper.getRolesFunctions(result.typeId);

            if(functionsResult.success !== false)
            {
                this.setState({
                    addScreenOpened: true,
                    roleToEdit: {
                        id: result.id,
                        name: result.name,
                        description: result.description,
                        typeId: result.typeId,
                    },
                    functions: functionsResult.functions,
                    roleToEditFunctions: result.functions,
                })
            }
        }
    }

    editFunctionOfRole(id: number, hasAccess: boolean) {
        const funcs = [...this.state.roleToEditFunctions];

        const func = funcs.find(f => f.id === id);

        if(func)
        {
            func.hasAccess = hasAccess;
        }
        else
        {
            funcs.push({
                id: id,
                hasAccess: hasAccess,
            })
        }

        this.setState({
            roleToEditFunctions: funcs,
        })
    }

    async getFunctions(typeId: number)
    {
        const result = await paApiHelper.getRolesFunctions(typeId);
        if(result.success !== false)
        {
            this.setState({
                functions: result.functions,
                roleToEditFunctions: result.functions.map((f: any) => { return {id: f.id, hasAccess: false}})
            })
        }
    }

    haveRequiredFields(role: any)
    {
        let have = true;

        const fields = Object.keys(role);

        for(const field of roleRequiredFields)
        {
            if(!fields.includes(field))
            {
                have = false;
            }
        }

        return have;
    }

    async componentDidMount() {

        await this.getExtra()

        this.setState({
            rolesLoading: true,
        })

        await this.getRoles();

        this.setState({
            rolesLoading: false,
        })
    }

    async createOrEditRole() {
        if(this.state.roleToEdit)
        {
            const roleBody: any = {
                name: this.state.roleToEdit.name,
                description: this.state.roleToEdit.description,
                typeId: this.state.roleToEdit.typeId,
            };
            roleBody.functions = [...this.state.roleToEditFunctions].map((e: any) => {return {id: e.id, hasAccess: e.hasAccess}});


            let result = null;

            const roleId = this.state.roleToEdit.id;
            if(roleId === undefined)
            {
                
                result = await paApiHelper.createRole(roleBody);
            }
            else
            {
                result = await paApiHelper.editRole(roleId, roleBody);
            }


            if(result)
            {
                if(result.success)
                {
                    Notifier.success(result.message)
                }
                else
                {
                    Notifier.error(result.message)
                }
            }
            else
            {
                Notifier.error("Непредвиденная ошибка!")
            }

            // const result = await paApiHelper.createEmployee(this.state.roleToEdit);
            // if(result.success)
            // {
            //     Notifier.success(result.message)
            // }
            // else
            // {
            //     Notifier.error(result.message)
            // }
        }
        else
        {
            Notifier.warn("Необходимо заполнить обязательные поля")
        }
    }

    setField(name: string, value: any)
    {
        const employee = Object.assign({}, this.state.roleToEdit);

        employee[name] = value;

        this.setState({roleToEdit: employee});
    }

    async setRoleType(typeId: number)
    {
        this.setField("typeId", typeId);

        await this.getFunctions(typeId);
    }

    async updateRoles(userId: number, rolesIds: number[]) {

        if(rolesIds.length < 1)
        {
            Notifier.warn("Нельзя удалить последнюю роль пользователя!");
            return;
        }

        const result = await paApiHelper.grantRoles(userId, rolesIds);

        if(result.success)
        {
            Notifier.success(result.message)
        }
        else
        {
            Notifier.error(result.message)
        }
    }


    render() {
        return (
            <div>
                {!this.state.addScreenOpened && (
                    <>
                        <TitleTextBlock>
                            Здесь Вы можете создать и настроить роли для своих 
                            <br />
                            сотрудников. Роль представляет собой набор функционала 
                            <br />
                            из возможностей определённого типа.
                        </TitleTextBlock>
                        <div style={{ marginTop: "40px", width: "250px", height: "50px" }}>
                            <PrimaryButton text="Добавить новую роль"
                                onClick={() => this.openCloseAddScreen()}
                            />
                        </div>
                        {
                            this.state.rolesLoading ? 
                            <div style={{width: "100%", height: "500px"}}>
                                <SimpleLoader></SimpleLoader>
                            </div>
                            :
                            <HeaderGreenProvider value={true}>
                                <TableWrapper
                                    total={this.state.totalRoles}
                                    rowsCount={this.state.totalRoles}
                                    headerData={rolesHeader}
                                    bodyData={processData(this.state.roles, this.state.rolesTypes, (id) => this.editRole(id))}
                                    onChangePage={(offset: number) => this.getRoles()}
                                />
                            </HeaderGreenProvider>
                        }
                    </>
                )}
                {this.state.addScreenOpened && this.state.rolesLoading === false && (
                    <>
                        <div className="add-role_block">
                            <div style={{width: "450px", height: "50px"}}>
                                <Input placeholder="Введите название роли" defaultValue={this.state.roleToEdit.name || ""} onChangeValue={(value: string) => this.setField("name", value)}/>
                            </div>
                            <div style={{width: "450px", height: "50px"}}>
                                <Input placeholder="Введите описание роли" defaultValue={this.state.roleToEdit.description || ""} onChangeValue={(value: string) => this.setField("description", value)}/>
                            </div>
                            <div style={{width: "450px", height: "50px"}}>
                                <SingleSelect placeholder="Выберите тип роли" chosenValue={this.state.roleToEdit.typeId || null} onChange={(typeId) => this.setRoleType(typeId)} values={mapArrayToDropdownElements(this.state.rolesTypes)}/>
                            </div>
                        </div>
                        {
                            this.state.roleToEdit.typeId && 
                            <TableWrapper
                                total={this.state.functions.length}
                                rowsCount={this.state.functions.length}
                                headerData={functionsHeader}
                                bodyData={processFunctionsData(this.state.functions, this.state.roleToEditFunctions, (id, hasAccess) => this.editFunctionOfRole(id, hasAccess))}
                                // onChangePage={(offset: number) => this.getRoles(offset)}
                            />
                        }
                        <div className="add-employee_control">
                            <div style={{width: "250px", height: "50px" }}>
                                <Secondary1Button text="Отменить"
                                    onClick={() => this.openCloseAddScreen(false)}
                                />
                            </div>
                            <div style={{width: "250px", height: "50px" }}>
                                <PrimaryButton text="Сохранить"
                                    onClick={() => this.createOrEditRole()}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }
}
