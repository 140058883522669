import React from "react";
import "./style.scss";

const dropdownButtons = [
    {
        name: "Редактировать профиль",
        path: "/?section=profile",
        index: 0,
        id: "prof",
    },
    {
        name: "Уведомления",
        path: "/?section=notifications",
        index: 1,
        id: "not",
    },
    {
        name: "Безопасность",
        path: "/?section=security",
        index: 2,
        id: "sec",
    },
    {
        name: "Выход",
        path: `${process.env.REACT_APP_AUTH_PAGE + "/sign-out?redirect=" + process.env.REACT_APP_LANDING_HOST}`,
        index: 3,
        id: "exit",
    },
];

export default class HeaderDropdown extends React.Component<
    {},
    { blockActive: boolean }
> {
    constructor(props: any) {
        super(props);

        this.state = {
            blockActive: false,
        };
    }

    isCurrentPage(path: any) {
        const pathActivePage = `${
            window.location.pathname + window.location.search
        }`;
        let isActive = false;

        if (path === pathActivePage) {
            isActive = true;
        }
        return isActive;
    }

    isActiveFirstItem(id: string) {
        const pathNameActivePage = window.location.pathname;
        const queryStringOfPath = window.location.search;

        if (
            queryStringOfPath === "" &&
            id === "prof" &&
            pathNameActivePage === "/"
        ) {
            return true;
        }
    }

    render() {
        let classNameDropdown = "header__dropdown";
        const { blockActive } = this.state;

        if (blockActive) {
            classNameDropdown += " dropdown-active";
        }

        return (
            <div className={classNameDropdown}>
                <div className="header__dropdown-items">
                    {dropdownButtons.map((element) => (
                        <a
                            key={element.id}
                            className={`header__dropdown-item 
                                ${
                                    this.isCurrentPage(element.path) ||
                                    this.isActiveFirstItem(element.id)
                                        ? "item-active"
                                        : ""
                                }
                            `}
                            href={element.path}
                        >
                            {element.name}
                        </a>
                    ))}
                </div>
            </div>
        );
    }
}
