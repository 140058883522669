import React from 'react';
import './style.scss';



interface ReadonlyTextProps {
    defaultValue?: string;
    badge?: any;
    onBadgeClick?: Function;
}

interface InputState {
    value: string;
}

class ReadonlyText extends React.Component<ReadonlyTextProps, InputState> {

    refContols: any = null;
    constructor(props: ReadonlyTextProps) {
        super(props);
        this.state = {
            value: this.props.defaultValue || ""
        };
        this.refContols = React.createRef();
    }

    handleBadgeClick(event: any) {
        if (this.props.onBadgeClick) {
            this.props.onBadgeClick();
        }
    }

    render() {
        const badge = this.props.badge;
        const defaultValue = this.props.defaultValue || "";

        return (
            <div className='text-container'>
                <div
                    ref={this.refContols}
                    className="text"
                >
                    <span className='text-text'>{defaultValue}</span>  
                </div>
                {
                    badge &&
                    <img
                        className='text-badge'
                        src={badge}
                        alt="ci"
                        onClick={(event) => this.handleBadgeClick(event)}
                    />
                }
            </div>
        )
    }
}

export default ReadonlyText;