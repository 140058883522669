import React, { Component } from 'react';
import ImageUpload from '../ImageUpload';
import './style.scss';

interface PopupProps {
    onTogglePopup?: Function;
}

export default class Popup extends Component<PopupProps> {
    render() {

        const onTogglePopup = this.props.onTogglePopup || (() => {});

        return (
            <div className='popup-image-uploader'>
                <div className='popup__btn-close' onClick={() => onTogglePopup()}></div>
                <p className='popup__title'>Загрузка фотографии</p>
                <p className='popup__content'>Вы можете загрузить изображение<br/>
                    в формате <span className='text-highlight'>JPG</span> или <span className='text-highlight'>PNG</span>.
                </p>
                <ImageUpload onUploadEnd={() => onTogglePopup()}/>
                <p className='popup__content text-below'>Если у вас возникают проблемы с загрузкой, <br/> попробуйте выбрать фотографию меньшего размера.</p>
            </div>
        );
    };
}